import React from 'react'
import cx from 'classnames'
import { Link, LinkProps } from 'react-router-dom'
import style from './index.module.sass'

type Props = Omit<LinkProps, 'to'> & {to: null | LinkProps['to']}

export const OptionalLink = (props: Props) => {
	if (props.to !== null) {
		// return normal Link
		return <Link
			{...props} 
			to={props.to || ''} // hack I did while on airplane without internet...
		/>
	} else {
		// remove Link specific props 
		const {
			component,
			replace,
			to,
			innerRef,
			defaultValue,
			className,
			...rest
		} = props
		return (
			<a
				{...rest}
				className={cx(className, style.disabled)}
			>
				{props.children}
			</a>
		)

	}
	return null
}
