import React from 'react'
import { Link } from 'react-router-dom'
import c from 'classnames'

import style from './user-item.module.sass'
import { userManagementEditUrl } from 'utils/url'
import { useRouter } from 'components/context'
import { userList_user_List_objects as UserType } from './gql-types/userList'
import { Pill } from 'components/common/pill'


export default (props: {user: UserType}) => {
	const router = useRouter()
	const activeLink = userManagementEditUrl(props.user.id) === router.location.pathname
	return (
		<Link
			to={userManagementEditUrl(props.user.id)}
			className={style.user}
		>
			<div className={style.topRow}>
				<div className={c(style.name, {[style.userActive]: activeLink})}>
					{props.user.firstName} {props.user.lastName}
				</div>
				{props.user.topLevelManager &&
					<>&nbsp;&nbsp;<Pill color='blue'>ADMIN</Pill></>
				}
			</div>
			<div className={style.email}>
				{props.user.email}
			</div>
			
		</Link>
	)
}
