import React, { createRef } from 'react'
import c from 'classnames'
import { AnswerAnimationGroup } from './answer-animation-group/index.module'
import { RowAnswer } from './row-answer'
import style from './row-deletable.module.sass'
import { toggleAnswerRowDeactivated } from 'state/answer/thunks'
import { RootState } from 'state/redux-store'
import { useAppDispatch, useAppSelector } from 'utils/hooks'
import { selectAnswersForField } from 'state/answer/selectors'

type Props = {
	fieldId: string
	rank: number
	tableFieldShowColumns: number
	fieldIsDisabled: boolean
	showDeleteButton: boolean
}


export const RowDeletable = ({ fieldId, tableFieldShowColumns, fieldIsDisabled, showDeleteButton, rank }: Props) => {
	const dispatch = useAppDispatch()

	const [keyId, deactivated] = useAppSelector((state: RootState) => {
		const answers = selectAnswersForField(state, fieldId).filter(a => a.rank == rank)
		return [answers[0].id, answers.every(a => !!a.deactivated)]
	})

	const toggleDeactivate = () => {
		dispatch(toggleAnswerRowDeactivated({fieldId, rank, deactivated: deactivated ? null : new Date().toISOString()}))
	}

	const activeRef = createRef<HTMLDivElement>()
	const deactivatedRef = createRef<HTMLDivElement>()

	return (
		<AnswerAnimationGroup
			elements={[
				{
					id: `${keyId}-active`,
					show: !deactivated,
					ref: activeRef,
					node: (
						<div className={style.deletableRow} ref={activeRef}>
							<RowAnswer
								fieldId={fieldId}
								rank={rank}
								tableFieldShowColumns={tableFieldShowColumns}
								fieldIsDisabled={fieldIsDisabled}
							/>
							<div className={style.deleteAnswerButtonContainer}>
								{showDeleteButton &&
									<button
										className={c("icon-cross", style.deleteAnswerButton)}
										onClick={toggleDeactivate}
									/>
								}
							</div>
						</div>
					),
				},
				{
					id: `${keyId}-deleted`,
					show: !!deactivated,
					ref: deactivatedRef,
					node: (
						<div className={style.deletedAnswer} ref={deactivatedRef} onClick={toggleDeactivate}>
							Undo delete
						</div>
					),
				}
			]}
		/>
	)
}