import React from 'react'
import gql from "graphql-tag"
import { recordEssentials } from 'fragments'
import { useSelector } from 'react-redux'
import { useMutation, useQuery } from '@apollo/client'
import { useRouter, useGlobalContext } from 'components/context'

import { ReduxState } from 'types/redux'
import { Options, OptionsVariables } from './gql-types/Options'
import { MutateRecord, MutateRecordVariables } from './gql-types/MutateRecord'
import EditForm, { Values } from './form'
import { formUrl, recordDashboardUrl } from 'utils/url'
import { FormLoading } from '../common'
import { MOVE_DATE_OPTIONS } from './move-date-on-copy/inner'

const UPDATE_RECORD = gql`
	mutation MutateRecord(
		$id: ID,
		$position: String!,
		$template: ID!,
		$copyRecord: ID,
		$team: ID!,
		$name: String!,
		$image: Upload,
		$moveDatesTo: Int,
		$moveDatesType: MoveDatesType,
	) {
		record_Update(input: {
			id: $id
			team: $team
			position: $position
			name: $name
			image: $image
			template: $template
			copyRecord: $copyRecord
			moveDatesTo: $moveDatesTo
			moveDatesType: $moveDatesType
		}) {
			object {
				...RecordEssentials
				template {
					id
					formSet {
						id 
						# get first record form to navigate to
						forms(orderBy: [{field: rank}], limit: 1) {
							id
						}
					}
				}
			}
		}
	}
	${recordEssentials}
`

const GET_OPTIONS = gql`
	query Options($modules: ID!) {
		templateOptions: template_List(
			limit: 1000,
			offset: 0,
			module: $modules,
			orderBy: [
				{field: copyPositionText, direction: DESC},
				{field: position, modifiers: [CASE_INSENSITIVE]}
			],
		) {
			objects {
				id
				position
				namePlaceholder
				copyPositionText
			}
			pageInfo {
				hasNextPage
				total
			}
		}
		recordOptions: record_List(
			limit: 1000,
			offset: 0,
			orderBy: [{field: position}, {field: name}],
		) {
			objects {
				id
				name
				position
				template {
					id
					namePlaceholder
				}
			}
			pageInfo {
				hasNextPage
				total
			}
		}
		
	}
`

type Props = {
	initialValues: Values
	dedicatedCopyUrl?: boolean
}

const RecordEditForm: React.FC<Props> = (props) => {
	const router = useRouter()
	const globalContext = useGlobalContext()
	const { data, error, loading } = useQuery<Options, OptionsVariables>(
		GET_OPTIONS,
		{ variables: { modules: globalContext.currentModule.id } }
	)
	const [saveRecord, {}] = useMutation<MutateRecord, MutateRecordVariables>(UPDATE_RECORD)
	const refreshRecordList = useSelector((state: ReduxState) => state.record.refreshRecordList)

	if (loading) {
		return <FormLoading />
	}
	if(!data || !data.recordOptions || !data.templateOptions) {
		return <div>error</div>
	}

	const save = async (variables: MutateRecordVariables) => {
		const response = await saveRecord({variables})
		refreshRecordList()
		const r = response.data!.record_Update!.object!
		router.history.push(
			props.initialValues.id
			? recordDashboardUrl({recordId: r.id, ...globalContext})
			: formUrl({recordId: r.id, formId: r.template.formSet.forms[0].id, ...globalContext})
		)
	}
	const recordOptions = data.recordOptions.objects
	
	if (!props.initialValues.template && data.templateOptions.objects.length == 1) {
		props.initialValues.template = data.templateOptions.objects[0]
		props.initialValues.position = data.templateOptions.objects[0].position
	}

	props.initialValues.moveDatesType = MOVE_DATE_OPTIONS[0]
	
	return (
		<EditForm
			recordOptions={recordOptions}
			templateOptions={data.templateOptions.objects}
			onSubmit={save}
			{...props}
		/>
	)
}


export default RecordEditForm