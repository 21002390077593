import React from 'react'

import EditUserForm from './form'
import style from './index.module.sass'

export default () => {
	return (
		<div className={style.container}>
			<h3>Invite User</h3>
			<EditUserForm user={{
				firstName: '',
				lastName: '',
				email: '',
			}} />
		</div>
	)
}