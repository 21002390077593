import React from 'react'
import { Popover } from 'antd'
import { QueryFormActionMetrics_formAction_List_objects, QueryFormActionMetrics_formActionSchedule_List_objects, QueryFormActionMetrics_formActionEscalationEvent_List_objects } from '../gql-types/QueryFormActionMetrics'
import style from './segment.module.sass'
import { getUserDetails } from 'components/form/form-control-panel/form-actions/common/utils'
import { monthIdFromString } from '../common/const'


export type MonthData = {
	created: { [id: string]: QueryFormActionMetrics_formAction_List_objects }
	completedOn: { [id: string]: QueryFormActionMetrics_formAction_List_objects }
	dueInFuture: { [id: string]: QueryFormActionMetrics_formActionSchedule_List_objects }
	overdue: { [id: string]: QueryFormActionMetrics_formAction_List_objects }
	span: { [id: string]: QueryFormActionMetrics_formAction_List_objects }
	escalation: { [id: string]: QueryFormActionMetrics_formActionEscalationEvent_List_objects }
}

type Props = {
	year: number
	month: number
	todayKey: string
	monthData: MonthData
}
export const Segment = ({ monthData, month, year, todayKey }: Props) => {
	const hasEscalation = Object.keys(monthData.escalation).length > 0
	const overdue = Object.keys(monthData.overdue).length > 0
	const hasCreated = Object.keys(monthData.created).length > 0
	const hasCompleted = Object.keys(monthData.completedOn).length > 0
	const hasDueInFuture = Object.keys(monthData.dueInFuture).length > 0
	const hasSpan = Object.keys(monthData.span).length > 0


	return (
		<Popover
			title={""}
			content={() => {
				const types = [
					{title: 'Escalation', data: monthData.escalation},
					{title: 'Overdue', data: monthData.overdue},
					{title: 'Completed', data: monthData.completedOn},
					{title: 'Upcoming', data: monthData.dueInFuture},
					{title: 'Waiting for', data: monthData.span},
					{title: 'Created', data: monthData.created},
				]
				const toShow = types.filter(t => Object.keys(t.data).length > 0)
				if (!toShow.length) {
					return <div>No activity</div>
				}

				return toShow.map(t => (
					<div key={t.title}>
						<b>{t.title}</b>
						<ul style={{marginTop: 0}}>
							{Object.values(t.data).map(a => {
								const details = getUserDetails(a)
								return <li key={`${t.title}-${a.id}`}>{details.name}</li>
							})}
						</ul>
					</div>
				))
			}}
		>
			<div className={style.container}>
				{hasEscalation && <div className={style.escalation} />}
				{overdue && <div className={style.overdue} />}
				{hasDueInFuture && <div className={style.dueInFuture} />}
				{hasCompleted && <div className={style.completed} />}
				{hasCreated && <>
					<div className={style.created} />
					{Object.values(monthData.created).find(a => monthIdFromString(a.created) < (a.completedOn ? monthIdFromString(a.completedOn) : todayKey)) &&
						<div className={style.spanContainer}>
							<div className={style.spanEmpty} />
							<div className={style.span} />
						</div>
					}
				</>}
				{(Object.values(monthData.completedOn).find(a => monthIdFromString(a.created) < monthIdFromString(a.completedOn!)) ||
					Object.values(monthData.overdue).find(a => monthIdFromString(a.created) < todayKey)) &&
					<div className={style.spanContainer}>
						<div className={style.span} />
						<div className={style.spanEmpty} />
					</div>
				}
				{hasSpan && (
					<div className={style.spanContainer}>
						<div className={style.span} />
						<div className={style.span} />
					</div>
				)}
				<div className={style.background} />
			</div>
		</Popover>
	)
}