import React, { Component } from 'react'
import { RouteComponentProps } from 'react-router-dom'

import RecordEditForm from './edit-form'
import { useQuery } from '@apollo/client'
import { GetRecord, GetRecordVariables } from './gql-types/GetRecord'
import { GET_RECORD } from './queries'
import Container from './container'
import { FormLoading } from './common'
import { useTitle } from 'utils/hooks'
import { Title } from 'components/common/typeography'

type Props = RouteComponentProps<{recordId: string}> & {
	params: {recordId: string}
} 

const AddRecordContainer: React.FC<Props> = (props) => {
	const { data, error, loading } = useQuery<GetRecord, GetRecordVariables>(
		GET_RECORD,
		{variables: {id: props.match.params.recordId}}
	)
	useTitle(`Edit ${data?.record?.name || 'No Name'}`)

	if(loading) {
		return <FormLoading />
	}
	else if(!data?.record) {
		return <div>Error, please contact support.</div>
	}

	return (
		<>
			<Title type="page">{data.record.name}</Title>
			<RecordEditForm
				initialValues={{
					id: data.record.id,
					position: data.record.position,
					template: data.record.template,
					team: data.record.team,
					name: data.record.name,
					image: data.record.image,
				}}
			/>
		</>
	)
}
export default (props: Props) => (
	<Container>
		<AddRecordContainer {...props} />
	</Container>
)
