import {
	AuthAnswerText,
	PublicAnswerText,
	AuthAnswerBoolean,
	PublicAnswerBoolean,
	AuthAnswerDate,
	PublicAnswerDate,
	AuthAnswerFile,
	PublicAnswerFile,
	AuthAnswerDecimal,
	PublicAnswerDecimal,
} from "./get-form-answers"

export type AnswerText = AuthAnswerText | PublicAnswerText
export type AnswerBoolean = AuthAnswerBoolean | PublicAnswerBoolean
export type AnswerDate = AuthAnswerDate | PublicAnswerDate
export type AnswerFile = AuthAnswerFile | PublicAnswerFile
export type AnswerDecimal = AuthAnswerDecimal | PublicAnswerDecimal

export type GenericAnswer = 
	| AnswerText
	| AnswerBoolean
	| AnswerDate
	| AnswerFile
	| AnswerDecimal

export type ToSaveAnswer = {id: string, __typename: GenericAnswer["__typename"]} & Partial<
| (AnswerText & {lat: string, lng: string})
| AnswerBoolean
| AnswerDate
| AnswerFile
| AnswerDecimal
>

export const isAnswerText = (a: GenericAnswer): a is AnswerText => {
	return ['AnswerTextType', 'AnswerTextPubType'].includes(a.__typename)
}
export const isAnswerBoolean = (a: GenericAnswer): a is AnswerBoolean => {
	return ['AnswerBooleanType', 'AnswerBooleanPubType'].includes(a.__typename)
}
export const isAnswerDate = (a: GenericAnswer): a is AnswerDate => {
	return ['AnswerDateType', 'AnswerDatePubType'].includes(a.__typename)
}
export const isAnswerFile = (a: GenericAnswer): a is AnswerFile => {
	return ['AnswerFileType', 'AnswerFilePubType'].includes(a.__typename)
}
export const isAnswerDecimal = (a: GenericAnswer): a is AnswerDecimal => {
	return ['AnswerDecimalType', 'AnswerDecimalPubType'].includes(a.__typename)
}
