import React, { Component } from 'react'
import _groupBy from 'lodash/groupBy'
import { Category, CategoryType } from './category'
import { 
	record_record as RecordType,
} from '../gql-types/record'
import style from './index.module.sass'

type Props = {
	record: RecordType
}

const NO_CATEGORY_ID = '0'

export default (props: Props) => {
	const forms = props.record.template.formSet.forms
	const categoriesObject = _groupBy(forms, f => (f.category && f.category.id) || NO_CATEGORY_ID)
	let categories: CategoryType[] = []
	forms.forEach(f => {
		const c = f.category || {id: NO_CATEGORY_ID, rank: 0, title: null, collapsible: false}
		if(categoriesObject[c.id]) {
			categories.push({
				__typename: 'CategoryType',
				...c,
				forms: categoriesObject[c.id]
			})
			delete categoriesObject[c.id]
		} 
	})

	categories = categories.sort((a, b) => a.rank - b.rank)

	return (
		<div key={props.record.id} className={style.scrollContainer}>
			{categories.map(c => (
				<Category key={c.id} category={c} allForms={forms} record={props.record} />
			))}
		</div>
	)
}
