import React from 'react'
import gql from 'graphql-tag'
import { analyticsLink } from 'fragments'
import { useGlobalContext } from 'components/context'
import { useQuery } from '@apollo/client'
import ListMetric from '../../types/list'
import { NotificationsMetric, NotificationsMetricVariables } from './gql-types/NotificationsMetric'
import MetricPlaceholder from '../metric-placeholder'



const QUERY = gql`
	query NotificationsMetric($id: ID!, $teamId: ID, $isSuiteAdminDashboard: Boolean) {
		notificationsMetric(id: $id) {
			id
			name
			type
			values(teamId: $teamId, isSuiteAdminDashboard: $isSuiteAdminDashboard) {
				content
				contentObjectName
				formLink {
					...MetricFormLinkEssentials
				}
			}
		}
	}
	${analyticsLink}
`


type Props = {
	id: string
	isSuiteAdminDashboard?: boolean
}

const RED = '#e62b2b'

const NotificationsMetricWrapper = ({ id, isSuiteAdminDashboard }: Props) => {
	const globalContext = useGlobalContext()
	const { loading, data } = useQuery<NotificationsMetric, NotificationsMetricVariables>(
		QUERY,
		{
			variables: {
				id,
				teamId: globalContext.currentTeam?.id,
				isSuiteAdminDashboard
			},
		}
	)
	if (loading) {
		return <MetricPlaceholder />
	}
	if (!data || !data.notificationsMetric) {
		return (
			<div className="muted">
				We're having trouble fetching this metric.
				If this issue persists, please contact support
			</div>
		)
	}

	const extraProps = data.notificationsMetric.type == 'PAST'
		? {color: RED}
		: {}

	return (
		<ListMetric isSuiteAdminDashboard={isSuiteAdminDashboard} {...extraProps} {...data?.notificationsMetric} />
	)

}

export default NotificationsMetricWrapper