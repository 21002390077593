import React, { useRef, useEffect } from 'react'
import c from 'classnames'
import querystring from 'query-string'

import { Field } from './field'
import { useRouter } from 'components/context'
import { HelpInfoModal } from './field/help-info/modal'
import ShrinkButton from './shrink-button'
import Description from './description'
import { useFormContext } from 'state/main-form/reducer'
import style from './index.module.sass'
import { useAppSelector } from 'utils/hooks'
import { Title } from 'components/common/typeography'

type Props = {
	scrollContainerRef: React.RefObject<HTMLDivElement>
}

const FormInputPanelContainer = ({scrollContainerRef}: Props) => {
	const formContext = useFormContext()
	const router = useRouter()
	const fullScreenField = useAppSelector(state => state.userInterface.fullScreenField)

	const fields = Object.values(formContext.fields)
	fields.sort((a, b) => a.rank - b.rank)

	let fieldRefs: {[key: string]: React.RefObject<HTMLDivElement>} = {}
	fields.map(field => {
		fieldRefs[field.id] = useRef<HTMLDivElement>(null)
	})
	const scrollToField = querystring.parse(router.location.search).highlightFieldId || fullScreenField
	
	const scrollToFieldRef = fieldRefs[scrollToField]

	useEffect(() => {
		// Need to add a short delay for the container to fill before scrolling
		// (it works some of the time without so be careful)
		const scrollFunc = setTimeout(() => {
			if(scrollToField && scrollContainerRef?.current && scrollToFieldRef?.current) {
				scrollContainerRef.current.scrollTo(0, scrollToFieldRef.current.offsetTop - 100)
			}
		}, 500);
		return () => clearTimeout(scrollFunc);
	  }, []);

	return (
		<div className={c(style.container, {[style.fullScreenForm]: fullScreenField})}>
			{ formContext.record.archived || formContext.form.lockedFromFormAction &&
				<div className={style.archived}>
					<div className={`icon-pencil ${style.iconLock}`}/>
					{ formContext.record.archived ? 'Archived' : 'Signing in progress'}
				</div>
			}
			<div className={style.titleRow}>
				<ShrinkButton />
				<Title type="page">
					{formContext.form.title}
				</Title>
			</div>

			<Description
				description={formContext.form.description}
				recordId={formContext.record.id}
				formId={formContext.form.id}
			/>

			{ fields.map(field => {
				return <Field
					key={field.id}
					forwardRef={fieldRefs[field.id]}
					highlight={field.id == scrollToField}
					fieldId={field.id}
				/>
			})}
			<HelpInfoModal />
		</div>
	)
}

export default FormInputPanelContainer