import gql from 'graphql-tag'
export * from './gql-types/SaveAnswersAuth'

export const SAVE_ANSWERS_AUTH = gql`
	mutation SaveAnswersAuth(
		$formSave: form_saveInput!
		$answerText: [AnswerTextBulkObjectInput!]!
		$answerDecimal: [AnswerDecimalBulkObjectInput!]!
		$answerDate: [AnswerDateBulkObjectInput!]!
		$answerBoolean: [AnswerBooleanBulkObjectInput!]!
		$answerFile: [AnswerFileBulkObjectInput!]!
	) {
		formSave_Update(input: $formSave) {
			object {
				id
			}
		}
		answerText_BulkUpdate(input: { objects: $answerText }) {
			errors {
				field
				messages
			}
			objects {
				id
				content
				fieldId
				tableFieldId
				contentType
				objectId
				rank
				deactivated
			}
		}
		answerDate_BulkUpdate(input: { objects: $answerDate }) {
			errors {
				field
				messages
			}
			objects {
				id
				content
				fieldId
				tableFieldId
				contentType
				objectId
				rank
				deactivated
			}
		}
		answerDecimal_BulkUpdate(input: { objects: $answerDecimal }) {
			errors {
				field
				messages
			}
			objects {
				id
				content
				fieldId
				tableFieldId
				contentType
				objectId
				rank
				deactivated
			}
		}
		answerBoolean_BulkUpdate(input: { objects: $answerBoolean }) {
			errors {
				field
				messages
			}
			objects {
				id
				content
				fieldId
				tableFieldId
				contentType
				objectId
				rank
				deactivated
			}
		}
		answerFile_BulkUpdate(input: { objects: $answerFile }) {
			errors {
				field
				messages
			}
			objects {
				id
				content
				fieldId
				tableFieldId
				contentType
				objectId
				rank
				deactivated

				name
			}
		}
	}
`