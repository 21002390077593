import React from 'react'

import { SignatureType, isReusableSignature } from '../common'
import { formActionSignatureImageUrl } from 'utils/url'
import style from './signature-reuse.module.sass'
import { SignaturePreview } from './preview'



type Props = {
	signature: SignatureType | null
}

export const ReuseSignature = ({ signature }: Props) => {
	if (!signature || !isReusableSignature(signature)) {
		return <div>Error</div>
	}

	return (
		<div className={style.container}>
			Use your signature from your last action.<br/><br/>
			<SignaturePreview url={formActionSignatureImageUrl({formActionId: signature.reusePreviousFormAction.id})} />
		</div>
	)
}