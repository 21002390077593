import React, { useState } from 'react'

import { SinglePanel } from 'components/common/panel'
import { UpdateUser } from './update-user'
import { UpdatePassword } from './update-password'
import { useTitle } from 'utils/hooks'
import { Mfa } from './mfa'
import { settingsUserDetailsUrl, settingsUserMfaUrl, settingsUserPasswordUrl, settingsOrganisationUrl } from 'utils/url'
import { Route } from 'react-router-dom'
import { OrganisationSettings } from './organisation'
import { LeftMenu } from './left-menu'
import style from './index.module.sass'


export const Settings = () => {
	useTitle('Account Settings')
	return (
		<div className={style.container}>
			<LeftMenu />
			<SinglePanel type="thin">
				<div className={style.contentContainer}>
					<Route path={settingsUserDetailsUrl()} component={UpdateUser} />
					<Route path={settingsUserPasswordUrl()} component={UpdatePassword} />
					<Route path={settingsOrganisationUrl()} component={OrganisationSettings} />
					<Route path={settingsUserMfaUrl()} component={Mfa} />
				</div>
			</SinglePanel>
		</div>
	)
}
