import React from 'react'

import { Input } from 'components/common/inputs'
import { useAppDispatch } from 'utils/hooks'
import { updateAnswers } from 'state/answer/thunks'
import { AnswerTextBaseProps } from '../types'
import commonStyle from '../common.module.sass'


const AnswerTextShort = ({ answer, field, disabled}: AnswerTextBaseProps) => {
	const dispatch = useAppDispatch()
	return (
		disabled ? (
			<div className={commonStyle.answerTextDisabled}>
				{answer.content || <i className={commonStyle.noContent}>No content</i>}
			</div>
		)
		: (
			<Input
				className={commonStyle.formInput}
				onChange={(e) => dispatch(updateAnswers({ answers: [{ ...answer, content: e.target.value, }] }))}
				placeholder={field.placeholder || undefined}
				value={answer.content || ""}
			/>
		)
	)
}

export default AnswerTextShort

