import React, { useState } from 'react'
import gql from 'graphql-tag'
import { InfoCircleOutlined } from '@ant-design/icons'
import _defer from 'lodash/defer'
import { useQuery } from '@apollo/client'
import { Tabs, Divider, Tooltip } from 'antd'
import { useFormContext } from 'state/main-form/reducer'
import { Spinner } from 'components/common/loading'
import { SignatureType } from '../common'
import { TextSignature } from './signature-text'
import { UploadSignature } from './signature-upload'
import { ReuseSignature } from './signature-reuse'
import { ReuseSignatures } from './gql-types/ReuseSignatures'
import style from './index.module.sass'
import { previewPdfFileApiUrl, previewPdfFilePublicApiUrl } from 'utils/url'
import { Input } from 'components/common/inputs'

const LIST_SIGNATURES = gql`
	query ReuseSignatures {
		formAction_List(reusableSignatures: true) {
			objects {
				id
				signatureNote
			}
		}
	}
`

const TAB_REUSE = 'reuse'
const TAB_UPLOAD = 'upload'
const TAB_TEXT = 'text'

type Props = {
	setSignature: (s: SignatureType | null) => void
	signature: SignatureType | null
	pdfGenerators: {
		id: string
		name: string
	}[]
}

export const Signature = ({ setSignature, signature, pdfGenerators }: Props) => {
	const formContext = useFormContext()
	const [resetKey, setResetKey] = useState(0.0)
	const code = formContext.publicForm?.code
	const { data, loading } = useQuery<ReuseSignatures>(
		LIST_SIGNATURES,
		{
			onCompleted: (d) => {
				const fa = d.formAction_List?.objects[0]
				const canReuseSignature = !code && fa
				if (canReuseSignature) {
					setSignature({
						reusePreviousFormAction: fa,
						signatureNote: fa.signatureNote,
					})
				}
			},
		}
	)

	if (loading) {
		return <Spinner />
	}

	const lastSignature = data?.formAction_List?.objects[0]

	return (
		<>
			<p> Review the pdf before signing by clicking the button below.</p>
			{pdfGenerators &&
				<div>
					{pdfGenerators.map(pdfG => {
						const url = code
							? previewPdfFilePublicApiUrl({ code: code, pdfGeneratorId: pdfG.id })
							: previewPdfFileApiUrl({ formId: formContext.form.id, recordId: formContext.record.id, pdfGeneratorId: pdfG.id })
						return (
							<a key={url} href={url} target="_blank">
								<button key={pdfG.id} className="button-grey-solid icon-doc">{pdfG.name}</button>
							</a>
						)
					})}
				</div>
			}
			<Divider plain />
			<p>Select the signature to sign this form. Once signed, any changes to this document will be disabled.</p>
			<Tabs
				onChange={(e) => {
					// Set correct value when switching tabs
					setResetKey(Math.random())

					// Need to defer as hook in SignatureText overrides when switching tabs
					_defer(
						() => setSignature(
							e == TAB_REUSE && lastSignature
								? {
									reusePreviousFormAction: lastSignature,
									signatureNote: lastSignature.signatureNote
								}
								: null
						)
					)
				}}
				items={[
					...(!code && lastSignature ? [{
						label: 'History',
						key: TAB_REUSE,
						children: <ReuseSignature key={resetKey} signature={signature} />
					}] : []),
					{
						label: 'Text',
						key: TAB_TEXT,
						children: <TextSignature key={resetKey} setSignature={setSignature} signature={signature} />
					},
					{
						label: 'Upload',
						key: TAB_UPLOAD,
						children: <UploadSignature key={resetKey} setSignature={setSignature} signature={signature} />
					},
				]}
			/>
			<br/>
			<b>
				Role title &nbsp;
				<Tooltip title="This will display under your name on the pdf">
					<InfoCircleOutlined />
				</Tooltip>
			</b>
			
			
			<Input
				placeholder="Enter Title"
				onChange={(e) => { setSignature(signature ? {...signature, signatureNote: e.target.value} : null) }}
				value={signature?.signatureNote}
			/>
			<p className={style.legalText}>
				By selecting Adopt and Sign, I agree that the signature and initials will be the electronic representation of my signature for all purposes when I (or my agent) use them on documents, including legally binding contracts - just the same as a pen-and-paper signature or initial.
			</p>
		</>
	)
}