import React from 'react'

import { useAppDispatch } from 'utils/hooks'
import style from './field-row.module.sass'
import { useFormContext } from 'state/main-form/reducer'
import { setFieldToHidden } from 'state/main-form/thunks'


type Props = {
	fieldId: string
}

export const FieldRow = ({fieldId}: Props) => {
	const dispatch = useAppDispatch()
	const {fields, record} = useFormContext()
	const field = fields[fieldId]

	const onToggle = () => {
		dispatch(setFieldToHidden({
			fieldId,
			hiddenFieldId: field.hiddenFieldId || null,
			teamId: record.team.id,
			recordId: record.id
		}))
	}

	return (
		<div className={style.container}>
			<input
				type="checkbox"
				checked={!field.hiddenFieldId}
				onChange={onToggle}
				disabled={!field.canHide}
			/>
			<div className={style.title}>
				{field.title}
			</div>
		</div>
	)
}