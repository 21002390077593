import { useEffect } from 'react'

import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux'
import type { RootState, AppDispatch } from 'state/redux-store'

export { useDebounce } from './useDebounce'

// Use instead of `useDispatch` and `useSelector`
export const useAppDispatch = () => useDispatch<AppDispatch>()
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector

export const useTitle = (title: string) => {
	useEffect(() => {
		const initialTitle = document.title
		document.title = title
		return () => {
			document.title = initialTitle
		}
	}, [title]);
}
