import { configureStore } from '@reduxjs/toolkit'
import { createLogger } from 'redux-logger'

import analytics from './analytics/reducers'
import answers from './answer/reducer'
import file from './file/reducers'
import general from './general/reducers'
import mainForm from './main-form/reducer'
import record from './record/reducers'
import userInterface from './user-interface'
import modal from './modal/reducers'


const loggerMiddleware = createLogger({
	collapsed: true,
	duration: false,
	timestamp: false,
})

export const store = configureStore({
	middleware: (getDefaultMiddleware) => getDefaultMiddleware({
		serializableCheck: false
	}).concat(loggerMiddleware),
	reducer: {
		analytics,
		answers,
		file,
		general,
		mainForm,
		modal,
		record,
		userInterface,
	},
})

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch