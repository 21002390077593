import React from 'react'
import { format } from 'date-fns'
import gql from 'graphql-tag'
import { Dropdown } from 'antd'
import { MoreOutlined } from '@ant-design/icons'
import { Route, Link } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { useMutation } from '@apollo/client'

import EmailModal from 'components/email/modal'
import { emailModalUrl, emailModalSendUrl, editPdfNamelUrl, downloadPdfFileApiUrl, recordMergePdfsCreateUrl } from 'utils/url'
import { showModal } from 'state/modal/actions'
import { PDF_CONTENT_TYPE } from 'utils/constants'
import { useGlobalContext } from 'components/context'
import style from './pdf.module.sass'
import { Pdf as PdfType } from './gql-types/Pdf'
import { EditName } from './edit-name'
import { PdfPdfOriginChoices } from 'types/gql-types/global'
import { useFormContext } from 'state/main-form/reducer'

type Props = {
	pdf: PdfType
}

export const PDF_FRAGMENT = gql`
	fragment Pdf on PdfType {
		id
		name
		created
		origin
		isActive
	}
`

const DELETE_PDF = gql`
	mutation deletePdf(
		$id: ID!
	) {
		pdf_Update(
			input: {
				id: $id
				isActive: false
			}
		) {
			object {
				id
				isActive
			}
		}
	}
`

const Pdf = ({ pdf }: Props) => {
	const globalContext = useGlobalContext()
	const formContext = useFormContext()
	const recordId = formContext.record.id
	const formId = formContext.form.id
	const dispatch = useDispatch()
	const [deletePdfMutation, { loading }] = useMutation(DELETE_PDF)
	const [deleting, setDeleting] = React.useState(false)

	const showDeleteModal = () => {
		dispatch(showModal({
			title: 'Are you sure?',
			content:
				<p>
					You will no longer have access to this pdf
				</p>,
			confirmClass: 'button-red',
			confirmText: 'Delete pdf',
			confirmAction: async () => {
				setDeleting(true)
				await deletePdfMutation({ variables: { id: pdf.id }, refetchQueries: ['pdfGenerators', 'pdfGenerator_List'] })
				setDeleting(false)
			},
		}))
	}

	const emailProps = {
		recordId,
		formId,
		regardingObjectId: pdf.id,
		regardingObjectContentType: PDF_CONTENT_TYPE
	}

	const menuItems = [
		{ key: 'email', label: <Link to={emailModalSendUrl({ ...globalContext, ...emailProps })}>Email</Link> },
		{ key: 'rename', label: <Link to={editPdfNamelUrl({ ...globalContext, pdfId: pdf.id, recordId, formId })}>Rename</Link> },
		{ key: 'combine', label: <Link to={recordMergePdfsCreateUrl({ ...globalContext, recordId })}>Combine</Link> },
		{ key: 'delete', label: <div onClick={showDeleteModal}>Delete</div> },
	]

	return (
		<div className={style.pdfContainer} key={pdf.id}>
			<div className={style.pdf} style={deleting ? {opacity: 0.3} : {}}>
				<a target="_blank" className={style.link} href={downloadPdfFileApiUrl(pdf)}>
					<div className={style.pdfTextContainer}>
						<div><span className={`${style.icon} icon-doc`}></span><b>{pdf.name}</b></div>
						<div className="muted">{format(new Date(pdf.created), 'MMM do yyyy h:mma')}</div>
						{pdf.origin == PdfPdfOriginChoices.PUBLIC_FORM && <div className={style.badge}>Share Link</div>}
						{pdf.origin == PdfPdfOriginChoices.RESET && <div className={style.badge}>Reset Archive</div>}
						{pdf.origin == PdfPdfOriginChoices.FORM_ACTION && <div className={style.badge}>Actions Complete</div>}
					</div>
				</a>
				<Dropdown menu={{ items: menuItems }} trigger={['click']}>
					<MoreOutlined style={{ fontSize: '1.5rem' }} />
				</Dropdown>

			</div>
			<Route
				path={emailModalUrl({ ...globalContext, ...emailProps })}
				component={() => <EmailModal {...emailProps} />}
			/>
			<Route
				path={editPdfNamelUrl({ ...globalContext, pdfId: pdf.id, recordId, formId })}
				component={() => <EditName pdf={pdf} recordId={recordId} formId={formId} />}
			/>
		</div>
	)
}

export default Pdf