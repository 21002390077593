import React, { useState } from 'react'
import { useQuery } from '@apollo/client'
import { useGlobalContext } from 'components/context'
import { RouteComponentProps } from 'react-router-dom'
import gql from 'graphql-tag'
import { Switch } from 'antd'

import { FormActionMetricsUrlProps } from 'utils/url'
import { SinglePanel } from 'components/common/panel'
import { QueryActionRecordForms, QueryActionRecordFormsVariables } from './gql-types/QueryActionRecordForms'
import { Table } from './table'
import { PageTitle } from './page-title'
import Placeholder from 'components/common/placeholder'
import style from './index.module.sass'

const GET_ROWS = gql`
query QueryActionRecordForms(
	$moduleId: ID!,
	$teamId: ID,
) {
	record_List(
		module: $moduleId,
		team: $teamId,
		archived: false,
		orderBy: [
			{field: name, modifiers: [CASE_INSENSITIVE]}
		]
	) {
		objects {
			id
			name
			template {
				id
			}
		}
	}
	template_List(
		module: $moduleId
	) {
		objects {
				id
				formSet {
					id
					forms(allowActions: true, orderBy: [{field: title, modifiers: [CASE_INSENSITIVE]}]) {
						id
						title
					}
				}
			}
		}
	}
`

type Props = RouteComponentProps<FormActionMetricsUrlProps>
export const FormActionMetrics = ({match}: Props) => {
	const startYear = parseInt(match.params.startYear)
	const startMonth = parseInt(match.params.startMonth)
	const globalContext = useGlobalContext()

	const [hideEmpty, setHideEmpty] = useState(false)

	const { data, loading } = useQuery<QueryActionRecordForms, QueryActionRecordFormsVariables>(GET_ROWS, {
		variables: {
			moduleId: globalContext.currentModule.id,
			teamId: globalContext.currentTeam && globalContext.currentTeam.id,
		},
	})

	return (
		<SinglePanel noPadding type="full-width" noBoxShadow>
			<div className={style.container}>
				<PageTitle startYear={startYear} startMonth={startMonth} />

				<div className={style.emptyDataContainer}>
					<Switch checked={hideEmpty} onChange={() => setHideEmpty(!hideEmpty)} />
					<div className={style.emptyDataLabel}>Hide rows without data</div>
				</div>
				
				{loading && [...Array(5).keys()].map(i => (
					<div key={i}>
						<Placeholder style={{margin: '10px 0'}} />
					</div>
				))}
				{!loading &&
					<>
						{!data?.record_List || !data?.template_List
							? 'error loading data'
							: <Table
								hideEmpty={hideEmpty}
								startYear={startYear}
								startMonth={startMonth}
								records={data.record_List.objects}
								templates={Object.assign({}, ...data.template_List.objects.map((t) => ({[t.id]: t})))}
							/>}
					</>
				}
			</div>
		</SinglePanel>
	)
}