import React, { useState } from 'react'
import c from 'classnames'

import { useFormContext } from 'state/main-form/reducer'
import { useGlobalContext } from 'components/context'
import { SignatureType, isUploadSignature } from '../common'
import style from './signature-upload.module.sass'
import { checkImage } from 'utils/functions'
import { SignaturePreview } from './preview'

const FILE_INPUT_ID = 'signature'


type Props = {
	setSignature: (s: SignatureType | null) => void
	signature: SignatureType | null
}

export const UploadSignature = ({ setSignature, signature }: Props) => {
	const formContext = useFormContext()
	const [error, setError] = useState<string | null>(null)
	const globalContext = useGlobalContext()
	const code = formContext.publicForm?.code || null

	return (
		<>
			{signature && isUploadSignature(signature) ?
				<div className={style.imageContainer}>
					<button
						className={c(style.cancel, "icon-reset", "button-grey-solid")}
						onClick={() => setSignature(null)}
					/>
					<SignaturePreview url={URL.createObjectURL(signature.file)} />
				</div>
				:
				<>
					<button className={c("button-grey-solid", style.uploadButton)}>
						<label htmlFor={FILE_INPUT_ID}>
							Select Image
						</label>
						<input
							id={FILE_INPUT_ID}
							type="file"
							className={style.input}
							onChange={e => {
								const file = e.target.files && e.target.files.length > 0 && e.target.files[0]
								if (file) {
									const e = checkImage(file)
									if (e) {
										setError(e)
										return
									}
									setSignature({file, text: "", signatureNote: signature?.signatureNote || ""})
									setError(null)
								} else {
									setError("No file selected")
								}
							}}
						/>
					</button>
				</>
			}
			<div className="error">{error}</div>
		</>
	)
}