import React from 'react'
import { useSelector } from 'react-redux'

import gql from "graphql-tag"
import { SinglePanel } from 'components/common/panel'
import { RouteComponentProps } from 'react-router-dom'
import Files from 'components/common/file/files'
import { Events } from 'components/dashboard/pages/module/pages/analytics/dashboard/events'
import { EMPLOYEE_CONTENT_TYPE } from 'utils/constants'
import FormSetDashboard from './form-set-dashboard'
import { Record, RecordVariables } from './gql-types/Record'
import DetailsSection from './details-section'
import { ReduxState } from 'types/redux'
import Placeholder from 'components/common/placeholder'
import { useQuery } from '@apollo/client'
import { useGlobalContext } from 'components/context'
import { useTitle } from 'utils/hooks'


const GET_EMPLOYEE = gql`
	query Record($id: ID!) {
		record(
			id: $id
		) {
			id
			name
			position
			archived
			image
			team {
				id
				name
			}
			template {
				id
				position
				formSet {
					id
					dashboard {
						id
						dashboardFields {
							id
							field {
								id
								title
							}
							hideIfBlank
							rank
							answers(recordId: $id)
							form(recordId: $id)
						}
						sections {
							id
							name
							rank
						}
					}
				}
			}
		}
	}
`
type InnerProps = {
	record: NonNullable<Record["record"]>
}

const RecordDashboard: React.FC<InnerProps> = ({ record }) => {
	const refreshRecordList = useSelector((state: ReduxState) => state.record.refreshRecordList)
	const globalContext = useGlobalContext()
	useTitle((record.name || 'No Name') + ' - ' + record.team.name)
	return (
		<div>
			<DetailsSection record={record} refreshRecordList={refreshRecordList} />
			
			<div className="line-break"/>

			{ record.template!.formSet.dashboard &&
				<div>
					<FormSetDashboard dashboard={record.template!.formSet.dashboard} record={record} />
					<div className="line-break"/>
				</div>
			}

			<div className="analytics-metrics">
				<Events recordId={record.id} />
			</div>

			
			{ globalContext.currentModule.recordFilesEnabled &&
				<>
					<div className="line-break"/>
					<Files
						contentType={EMPLOYEE_CONTENT_TYPE}
						objectId={record.id}
					/>
				</>
			}
		</div>
	)
}


type Props = RouteComponentProps<{recordId: string}>


const RecordDashboardOuter: React.FC<Props> = (props) => {
	const {currentModule } = useGlobalContext()
	const { data, loading } = useQuery<Record, RecordVariables>(
		GET_EMPLOYEE,
		{ variables: { id: props.match.params.recordId } }
	)
	return (
		<SinglePanel type="wide">
			{ loading
				? <>
					<Placeholder />
					<Placeholder style={{height: 300}}/>
					<Placeholder style={{height: 300}}/>
				</>
			: (!data?.record)
				? (
					<div>
						<h1><span className="icon-warning" />Permission error</h1>
						<p>There was an issue fetching this {currentModule.recordWord}</p>
						<p>If you think this is a bug, please contact support.</p>
					</div>
				)
				: <RecordDashboard {...props} record={data.record} />
			}
		</SinglePanel>
	)
}

export default RecordDashboardOuter