import { Folder, File } from 'resources/resource'


export function updatedFile(file) {
	return {
		type: "UPDATED_FILE",
		file,
	}
}
export function updateFile(data) {
	return dispatch => {
		return File.saveFormData(data).then(response => {
			return response.json().then(f => {
				dispatch(updatedFile(f))
			})
		})
	}
}


export function createdFile(file) {
	return {
		type: "CREATED_FILE",
		file,
	}
}
export function createFile(data, file) {
	return dispatch => {
		return File.createFormData(data, file).then(response => {
			return response.json().then(f => {
				dispatch(createdFile(f))
			})
		})
	}
}


export function updatedFolder(folder) {
	return {
		type: "UPDATED_FOLDER",
		folder,
	}
}
export function updateFolder(data) {
	return dispatch => {
		return Folder.save(data).then(response => {
			return response.json().then(f => {
				dispatch(updatedFolder(f))
			})
		})
	}
}


export function createdFolder(folder) {
	return {
		type: "CREATED_FOLDER",
		folder,
	}
}
export function createFolder(data) {
	return dispatch => {
		return Folder.create(data).then(response => {
			return response.json().then(folder => {
				dispatch(createdFolder(folder))
			})
		})
	}
}


export function createEmptyFolder(params) {
	return dispatch => {
		dispatch(gotFiles([], params))
		dispatch(gotFolders([], params))
	}
}

export function gotFiles(files, {content_type, object_id}) {
	return {
		type: "GOT_FILES",
		files,
		content_type,
		object_id,
	}
}

export function getFiles(params) {
	return dispatch => {
		return File.query(params).then(response => {
			return response.json().then(fs => {
				dispatch(gotFiles(fs, params))
			})
		})
	}
}

export function gotFolders(folders, {content_type, object_id}) {
	return {
		type: "GOT_FOLDERS",
		folders,
		content_type,
		object_id,
	}
}

export function getFolders(params) {
	return dispatch => {
		return Folder.query(params).then(response => {
			return response.json().then(fs => {
				dispatch(gotFolders(fs, params))
			})
		})
	}
}