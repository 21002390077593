import React from 'react'
import c from 'classnames'

import { OptionalLink } from 'components/common/optional-link'
import { formUrl } from 'utils/url'
import { useGlobalContext } from 'components/context'
import { FormLink, NoData, ListItemTooltip } from '../common'
import style from './index.module.sass'


type Props = {
	isSuiteAdminDashboard?: boolean
	result: {
		value: string | null
		formLink: FormLink | null
	}
}

const FieldValueMetric = ({ result, isSuiteAdminDashboard }: Props) => {
	const globalContext = useGlobalContext()

	const toUrl = (result.formLink && !isSuiteAdminDashboard)
		? formUrl({recordId: result.formLink.recordId, formId: result.formLink.formId, highlightFieldId: result.formLink.fieldId, ...globalContext})
		: null

	if (result.value === null) {
		return <NoData />
	}
	
	return (
		<ListItemTooltip
			formLink={result.formLink}
			isSuiteAdminDashboard={isSuiteAdminDashboard}
		>
			<OptionalLink
				className={c(style.container, {'disabled-link': !result.formLink})}
				to={toUrl}
			>
				<div className={style.value}>
					{result.value}
				</div>
				<div className={style.subtitle}>
					{result.formLink?.recordName}
				</div>
			</OptionalLink>
		</ListItemTooltip>
	)
}

export default FieldValueMetric