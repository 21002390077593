import React from 'react'
import { useAppDispatch } from 'utils/hooks'
import { updateAnswers } from 'state/answer/thunks'
import { AnswerBooleanBaseProps } from '../types'
import style from './index.module.sass'

const AnswerBoolean = (props: AnswerBooleanBaseProps) => {
	const dispatch = useAppDispatch()
	return (
		<div className={style.answerBooleanContainer}>
			<input
				type="checkbox"
				checked={props.answer.content || false}
				onChange={(e) => {
					dispatch(updateAnswers({answers: [{ ...props.answer, content: e.target.checked }]}))
				}}
				disabled={props.disabled}
			/>
		</div>
	)
}

export default AnswerBoolean