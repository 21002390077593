import React, { useState } from 'react'
import gql from 'graphql-tag'
import { Divider } from 'antd'
import { useMutation } from '@apollo/client'
import { Checkbox } from 'antd'
import { Modal } from 'antd'
import { format } from 'date-fns'
import c from 'classnames'

import { Input } from 'components/common/inputs'
import { mergedPdfDownloadApiUrl } from 'utils/url'
import { pdfs_pdf_List_objects as PdfType } from './gql-types/pdfs'
import { create, createVariables } from './gql-types/create'
import style from './merge-pdf-form.module.sass'

type Props = {
	pdfs: PdfType[]
}

const MERGE_MUTATION = gql`
	mutation create(
		$name: String!,
		$pdfIds: [ID!]!,
	) {
		mergedPdf_Update(
			input: {
				name: $name
				pdfIds: $pdfIds,
			}
		) {
			object {
				id
				name
			}
			errors {
				field
				messages
			}
		}
	}
`

type ToggleObject  = {[k: string]: boolean}

const useObjectToggle: () => [ToggleObject, (ids: string[]) => void, () => void] = () => {
	const [selected, setSelected] = useState<ToggleObject>({})
	const togglePdfs = (ids: string[]) => {
		const newSelected = {...selected}
		ids.forEach(id => {
			if (newSelected[id]) {
				delete newSelected[id]
			} else {
				newSelected[id] = true
			}
		})
		setSelected(newSelected)
	}
	const removeAll = () => setSelected({})
	return [selected, togglePdfs, removeAll]
}

export const MergePdfForm = ({ pdfs }: Props) => {
	const [name, setName] = useState("")
	const [selectedPdfs, togglePdfs, removeAllPdfs] = useObjectToggle()
	const [mutate, { loading }] = useMutation<create, createVariables>(MERGE_MUTATION, {refetchQueries: ['mergePdf_List']})
	const pdfIds = Object.keys(selectedPdfs)
	const createMergedPdf = async () => {
		const result = await mutate({variables: { name, pdfIds }})
		const id = result.data?.mergedPdf_Update?.object?.id
		if (id) {
			Modal.info({
				title: "Pdf merged!",
				content: (
					<div>
						You can download your file here:<br/><br/>
						{ id &&
							<a target="_blank" href={mergedPdfDownloadApiUrl({id})}>
								<button className={c("icon-doc", "button-primary-solid")}>{name}.pdf</button>
							</a>
						}
					</div>
				),
				onOk() {
					removeAllPdfs()
					setName("")
				},
			});
		} else {
			alert("An error occured merging pdfs")
		}
	}
	return (
		<div>
			<div className={style.label}>Enter the name of the new pdf</div>
			<Input
				autoFocus
				value={name}
				onChange={e => setName(e.target.value)}
			/>
			<br/><br/>
			<div className={style.pdfList}>
				<div className={style.label}>Select your pdfs</div>
				<div className={style.pdf}>
					<Checkbox
						checked={Object.keys(selectedPdfs).length == pdfs.length}
						onChange={(e) => {
							if(e.target.checked) {
								const uncheckedPdfIds = pdfs.filter(pdf => !selectedPdfs[pdf.id]).map((pdf) => pdf.id)
								togglePdfs(uncheckedPdfIds)
							} else {
								removeAllPdfs()
							}
						}}
					/>
					<div className={style.textSection}>
						<span className={style.form}>Select all</span>
					</div>
				</div>
				<Divider />
				{pdfs.map(pdf => (
					<div key={pdf.id} className={style.pdf}>
						<Checkbox
							checked={!!selectedPdfs[pdf.id]}
							onChange={(e) => togglePdfs([pdf.id])}
						/>
						<div className={style.textSection}>
							<div>
								<span className={style.form}>{pdf.name}</span>
								{ pdf.form.category?.title && 
									<span> · {pdf.form.category?.title}</span>
								}
							</div>
							<div className={style.created}>{format(new Date(pdf.created), 'MMM do yyyy h:mma')}</div>
						</div>
					</div>
				))}
			</div>

			<button disabled={!name || !pdfIds.length || loading} className="button-primary-solid" onClick={createMergedPdf}>
				Merge {pdfIds.length} pdfs
			</button>
		</div>
	)
}
