import React from 'react'
import { Input } from 'components/common/inputs'
import { AnswerNumberBaseProps } from '../types'
import commonStyle from '../common.module.sass'
import { useAppDispatch } from 'utils/hooks'
import { updateAnswers } from 'state/answer/thunks'

const REGEX = /\d{0,12}/

const AnswerInteger = (props: AnswerNumberBaseProps) =>  {
	const dispatch = useAppDispatch()
	const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		let value = event.target.value

		const regexResult = REGEX.exec(value)

		if(regexResult) {
			const regexValue = regexResult[0]
			const validValue = regexValue === '' ? null : regexValue
			dispatch(updateAnswers({ answers: [{...props.answer, content: validValue,}] }))
		}
	}
	let value = props.answer.content || ""
	if (value.endsWith('.00')) {
		// don't show decimal places for integer field
		value = value.slice(0, value.length -3)
	}

	return props.disabled
		? <div>{value || <i className={commonStyle.noContent}>No content</i>}</div>
		:	(
			<Input
				className={commonStyle.formInput}
				placeholder={props.field.placeholder || undefined}
				onChange={onChange}
				value={value}
			/>
		)
}

export default AnswerInteger