import React from 'react'
import { Link } from 'react-router-dom'
import { ShareAltOutlined } from '@ant-design/icons'
import { formUrl, recordEditUrl } from 'utils/url'
import formatDistanceToNow from 'date-fns/formatDistanceToNow'
import { publicFormList_publicForm_List_objects as PublicFormType } from './gql-types/publicFormList'

import style from './public-form.module.sass'
import { useGlobalContext } from 'components/context'
import { Button } from 'antd';

type Props = { publicForm: PublicFormType }

export const PublicForm = ({ publicForm }: Props) => {
	let link = null
	const globalContext = useGlobalContext()
	if (publicForm.record && publicForm.form) {
		link = formUrl({ recordId: publicForm.record.id, formId: publicForm.form.id, ...globalContext })
	}
	else if (publicForm.record) {
		link = recordEditUrl({ recordId: publicForm.record.id, ...globalContext })
	} else {
		throw Error("Public form has no record")
	}

	const recordName = publicForm.record.name || 'No Name'
	const formTitle = publicForm.form?.title || 'Unknown Form'
	const userName = publicForm.createdBy?.firstName || "Anonymous User"
	return (
		<Link className={style.container} to={link}>
			<Button
					size="middle"
					type="primary"
					shape="circle"
					icon={<ShareAltOutlined />}
					style={{
						background: '#ffa200',
						borderColor: '#ffa200',
						marginRight: 10,
						opacity: 0.8,
					}}
			/>
			<div className={style.textContainer}>
				<div className={style.leftContent}>
					<b>{recordName}</b> · <b>{formTitle}</b> 
					{publicForm.createdBy &&
						<> shared by <b>{userName}</b> </>
					}
				</div>
				<div className={style.rightContent}>{formatDistanceToNow(new Date(publicForm.created))} ago</div>
			</div>
		</Link>
	)
}
