import React from 'react'
import { Menu, Dropdown, MenuProps } from 'antd'
import { MoreOutlined } from '@ant-design/icons'
import { Route } from 'react-router-dom'
import { modifyFormFieldsUrl } from 'utils/url'
import { Link } from 'react-router-dom'

import { useGlobalContext } from 'components/context'
import { useFormContext } from 'state/main-form/reducer'
import { useShowModifyForm } from './modify-form-button/use-show-modify-form'

import { useResetForm } from './use-reset-form'
import { ModifyFormModal } from './modify-form-button/modal'

import styles from './index.module.sass'

export const FormDropdown = () => {
	const globalContext = useGlobalContext()
	const formContext = useFormContext()

	const showModifyFormButton = useShowModifyForm()
	const showResetButton = formContext.form.allowReset

	const resetForm = useResetForm()

	if (!showModifyFormButton && !showResetButton) {
		return null
	}

	const url = modifyFormFieldsUrl({ ...globalContext, recordId: formContext.record.id, formId: formContext.form.id })

	const menu: MenuProps = {
		items: [
			...(showModifyFormButton
				? [{ key: 'item-1', label: <Link className="icon-pencil" to={url}>Modify form fields</Link>, }]
				: []
			),
			...(showResetButton
				? [{ key: 'item-2', label: <div className="icon-reset" onClick={resetForm}>Reset form</div>, }]
				: []
			),
		]
	}

	return (
		<>
			<Dropdown
				menu={menu}
				trigger={['click']}
			>
				<div className={styles.iconContainer}><MoreOutlined style={{ fontSize: '1.5rem' }} /></div>
			</Dropdown>

			<Route
				path={url}
				component={ModifyFormModal}
			/>
		</>
	)
}