import React from 'react'
import gql from 'graphql-tag'
import { useQuery } from '@apollo/client'
import { Select } from 'antd'

import Placeholder from 'components/common/placeholder'
import { FormActionContactQuery, FormActionContactQueryVariables, FormActionContactQuery_formActionContact_List } from './gql-types/FormActionContactQuery'
// import style from './index.module.sass'
import { useFormContext } from 'state/main-form/reducer'

const { Option } = Select

type Props = {
	onSelect: (c: FormActionContactQuery_formActionContact_List) => void,
	disabledAnswers: string[]
}

const GET_CONTACTS = gql`
	query FormActionContactQuery($recordId: ID!) {
		formActionContact_List(recordId: $recordId) {
				id
				email
				name
				fieldTitle
		}
	}
`


export const SelectContact = ({ onSelect, disabledAnswers }: Props) => {
	const { record } = useFormContext()

	const variables = { variables: { recordId: record.id } }
	const { data, loading } = useQuery<FormActionContactQuery, FormActionContactQueryVariables>(GET_CONTACTS, variables)

	const contacts = data?.formActionContact_List

	return (
		<div>
			{
				contacts ?
					<Select
						style={{width: 380}}
						placeholder="Select Email"
						onChange={(e) => onSelect(contacts.find(c => c.id === e)!)}
					>
						{contacts.map(c => (
							<Option
								key={c.id}
								disabled={!!disabledAnswers.find(id => id == c.id)}
								value={c.id}
							>
								{c.name} - {c.email}
							</Option>
						))}
					</Select>
				: loading ? [...Array(6)].map((_, i) => (
					<Placeholder key={i} style={{ width: 120, height: 28, margin: '3px 0px' }} />
				))
				: <div className="error">error</div>
			}
		</div>
	)
}
