import React from 'react'
import { useDispatch } from 'react-redux'

import SimpleIconButton from 'components/common/simple-icon-button'
import { showModal } from 'state/modal/actions'
import { Spinner } from 'components/common/loading'
import { useRemoveWebauthn } from './use-remove-mfa'


type Props = {
	credentialId: string
	onComplete: () => void
}


export const RemoveMfaButton = ({ credentialId, onComplete }: Props) => {
	const { removeMfa, loading } = useRemoveWebauthn({credentialId, onComplete})
	const dispatch = useDispatch()
	const confirmRemoveMfa = () => {
		dispatch(showModal({
			title: 'Are you sure?',
			content:
				<p>
					This will remove MFA across all devices associated with your account.
				</p>,
			confirmClass: 'button-red',
			confirmText: 'Remove MFA',
			confirmAction: removeMfa,
		}))
	}

	return (
		<>
			{loading
				? <Spinner />
				:
				<SimpleIconButton
					iconClass="icon-bin"
					small
					onClick={confirmRemoveMfa}
				/>
			}
		</>
	)
}