import React, { useState } from 'react'

import { IconTextInput } from 'components/common/inputs'
import { useAppDispatch } from 'utils/hooks'
import { updateAnswers } from 'state/answer/thunks'
import commonStyle from '../common.module.sass'
import { AnswerDatetimeBaseProps } from '../types'
import style from './index.module.sass'


const isValidTimeString = (timeString: string) => {
	timeString = timeString.replace(' ', '')
	var re = /^(0?[1-9]|1[0-2]):[0-5][0-9](a|p)m$/g;
	return timeString.match(re);
} 

const toTimeString = (content: string) => {
	const hour = parseInt(content.substring(11, 13))
	const hourString = hour > 12 ? hour - 12 : hour
	const minute = content.substring(14, 16)
	const amPm = (hour>=12) ? 'pm' : 'am'
	const a = `${hourString}:${minute}${amPm}`
	return a
}

const fromTimeString = (timeString: string) => {
	timeString = timeString.replace(' ', '')
	const isPm = timeString[timeString.length-2] === 'p'
	const hourNum = parseInt(timeString.split(':')[0])
	const hour = hourNum === 12
		? hourNum
		: hourNum + (isPm ? 12 : 0)
	const minute = timeString.split(':')[1].substr(0,2)
	const a = `2019-01-01T${hour.toString().padStart(2,'0')}:${minute}:00Z`
	return a
}

const AnswerTime = (props: AnswerDatetimeBaseProps) => {
	const content = props.answer.content
	const [timeString, setTimeString] = useState(content? toTimeString(content) : '')

	const dispatch = useAppDispatch()

	const onChange = ({ target: { value }}: React.ChangeEvent<HTMLInputElement>) => {
		value = value.replace('.', ':').replace(';', ':').replace(' ', '')
		setTimeString(value)
		if(isValidTimeString(value)) {
			dispatch(updateAnswers({ answers: [{ ...props.answer, content: fromTimeString(value), }] }))
		} else if (value.length === 0) {
			dispatch(updateAnswers({ answers: [{ ...props.answer, content: null }] }))
		}
	}

	const isValid = isValidTimeString(timeString) || timeString.length === 0
	return props.disabled
		? (
			<div>
				<span className={`${style.iconClock} icon-clock`} />
				{ props.answer.content 
					? toTimeString(props.answer.content)
					: <i className={commonStyle.noContent}>No content</i>
				}
			</div>
		) : (
			<div className={style.answerTime}>
				<IconTextInput
					iconClass="icon-clock"
					value={timeString}
					onChange={onChange}
					style={isValid ? {} : {borderColor: 'red'}}
				/>
				{!isValid && <div className={style.helpText}>e.g. 12:30pm</div>}
			</div>
		)
		
}

export default AnswerTime

