import React, { useState } from 'react'
import _pick from 'lodash/pick'
import { Typography } from 'antd'
import gql from 'graphql-tag'
import { useMutation } from '@apollo/client'
import { PlusOutlined } from '@ant-design/icons'

import { getFormAuth } from 'state/main-form/thunks'
import { TEMP_ID_PREFIX } from 'utils/constants'
import { FormActionFormActionScheduleUnitNameChoices } from 'types/gql-types/global'
import { useGlobalContext } from 'components/context'
import { useFormContext } from 'state/main-form/reducer'
import { useAppDispatch } from 'utils/hooks'
import { BottomRow } from '../common/botton-row'
import { GetFormActions_formActionSchedule_List_objects } from '../../gql-types/GetFormActions'
import { FORM_ACTION_SCHEDULE_FRAGMENT } from '../../common/utils'
import { GetEscalations_formActionEscalation_List_objects } from '../common/escalations/gql-types/GetEscalations'
import { Add, AddFormState } from './add'
import { SaveFormActionSchedule, SaveFormActionScheduleVariables } from './gql-types/SaveFormActionSchedule'
import { Details } from './details'
import { FORM_ACTION_ESCALATION_FRAGMENT, transformStateForSave, useEscalationState } from '../common/escalations/hooks'
import { EscalationList } from '../common/escalations/list'
import { AddEscalation } from '../common/escalations/add'

const { Title } = Typography

type Props = {
	formActionSchedule: GetFormActions_formActionSchedule_List_objects | null
	formAction: {id: string, reminderLast: Date | null, reminderNext: Date | null} | null
	escalations: GetEscalations_formActionEscalation_List_objects[],
	close: () => void
}
const SAVE_FORM_ACTION_SCHEDULE = gql`
	mutation SaveFormActionSchedule($formActionSchedule: form_action_scheduleInput!, $escalations: [form_action_escalation_bulkBulkInput!]!) {
		formActionSchedule_Update(input: $formActionSchedule) {
			object {
				...FormActionSchedule
			}
			errors {
				field
				messages
			}
		}
		formActionEscalation_BulkUpdate(input: {objects: $escalations}) {
			objects {
				...FormActionEscalation
			}
			errors {
				field
				messages
			}
		}
	}
	${FORM_ACTION_SCHEDULE_FRAGMENT}
	${FORM_ACTION_ESCALATION_FRAGMENT}
`


export const Recurring = ({formActionSchedule, formAction, escalations, close}: Props) => {
	const { user } = useGlobalContext()
	const [showAdd, setShowAdd] = useState<boolean>(false)

	const formContext = useFormContext()
	const [state, setState] = useState<AddFormState | null>(formActionSchedule ? {
		id: formActionSchedule.id,
		assigneeType: formActionSchedule.assignedUser ? 'user' : 'anon',
		deleted: formActionSchedule.deleted,
		dueAt: new Date(formActionSchedule.dueAt),
		unitAmount: formActionSchedule.unitAmount,
		unitName: formActionSchedule.unitName as FormActionFormActionScheduleUnitNameChoices.MONTHS,
		assignedAnonEmail: formActionSchedule.assignedAnonEmail,
		assignedAnonName: formActionSchedule.assignedAnonName,
		assignedAnonEmailAnswer: formActionSchedule.assignedAnonEmailAnswer,
		userSuppliedContext: formActionSchedule.userSuppliedContext,
		requestSignature: formActionSchedule.requestSignature,
		assignedUser: formActionSchedule.assignedUser,
	} : null)

	const {state: escalationState, dispatch: escalationDispatch} = useEscalationState({initialEscalations: escalations})

	const reduxDispatch = useAppDispatch()

	const [updateFormActionSchedule, { loading }] = useMutation<SaveFormActionSchedule, SaveFormActionScheduleVariables>(
		SAVE_FORM_ACTION_SCHEDULE,
		{
			onCompleted: () => {
				reduxDispatch(getFormAuth({ formId: formContext.form.id, recordId: formContext.record.id }))
			}
		}
	)

	const save = async () => {
		let variables: SaveFormActionScheduleVariables

		if (formActionSchedule && formActionSchedule.id !== state?.id) {
			// delete existing first
			await updateFormActionSchedule({variables: {
				formActionSchedule: {
					id: formActionSchedule?.id,
					deleted: true,
				},
				escalations: [],
			}})
		}
		
		if (state) {
			// create new (if necessary)
			variables = {
				formActionSchedule: {
					...(state.id.startsWith(TEMP_ID_PREFIX) ? {} : {id: state.id}),
					assignedAnonEmail: state.assignedAnonEmail,
					assignedAnonName: state.assignedAnonName,
					assignedAnonEmailAnswer: state.assignedAnonEmailAnswer?.id || null,
					userSuppliedContext: state.userSuppliedContext,
					requestSignature: state.requestSignature,
					assignedUser: state.assignedUser?.id || null,
					form: formContext.form.id,
					record: formContext.record.id,
					deleted: state.deleted,
					dueAt: state.dueAt,
					unitAmount: state.unitAmount,
					unitName: state.unitName,
				},
				escalations: transformStateForSave(formContext, escalationState)

			}
			await updateFormActionSchedule({variables})
		}
		
		close()
	}

	return (
		<>
			<Details state={state} formAction={formAction} />
			<EscalationList state={escalationState} dispatch={escalationDispatch} />
			

			<Add
				show={showAdd}
				initialState={state}
				stageChange={(s) => setState(s)} setShow={setShowAdd}
			/>
			
			<BottomRow
				loading={loading}
				save={save}
				close={close}
				left={
					<>
						{(!state || state.deleted)
							? <button className="button-primary-solid" onClick={() => setShowAdd(true)}><PlusOutlined /> Schedule</button>
							: <button className="button-grey-solid" onClick={() => setState(null)}>Remove Schedule</button>
						}
						<AddEscalation dispatch={escalationDispatch} state={escalationState} />
					</>
				}
			/>
		</>
	)
}
