import React, { useState } from 'react'
import gql from 'graphql-tag'
import { useQuery } from '@apollo/client'

import { useDebounce } from 'utils/hooks'
import Placeholder from 'components/common/placeholder'
import { User, UserType } from './user'
import { GetUsers, GetUsersVariables } from './gql-types/GetUsers'
import style from './index.module.sass'
import { Input } from 'components/common/inputs'


type Props = {
	onSelect: (u: UserType | null) => void
	teamId: string
	disabledUsers: string[]
}

const GET_USERS = gql`
	query GetUsers($teamId: ID!, $search: String) {
		user_List(
			hasAccessToTeam: $teamId,
			search: $search,
			orderBy: [
				{field: firstName modifiers: [CASE_INSENSITIVE]}
				{field: lastName modifiers: [CASE_INSENSITIVE]}
			]
		) {
			objects {
				id
				firstName
				lastName
				email
			}
		}
	}
`


export const SelectUser = ({ onSelect, teamId, disabledUsers }: Props) => {
	const [search, setSearch] = useState('')
	const [debouncedSearch, setDebouncedSearch] = useState(search)
	useDebounce(
		() => setDebouncedSearch(search),
		200,
		[search],
	)

	const variables = { variables: { teamId, search: debouncedSearch } }
	const { data, loading } = useQuery<GetUsers, GetUsersVariables>(GET_USERS, variables)

	const objects = data?.user_List?.objects

	const onInputChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
		setSearch(e.target.value)
	}

	return (
		<div className={style.container}>
			<Input
				className={style.search}
				type="text"
				value={search}
				onChange={onInputChanged}
				placeholder="Search Users..."
				autoFocus
			/>
			<div className={style.userList}>
				{(objects && objects.length > 0) ? objects.map(u => (
					<User
						key={u.id}
						user={u}
						onClick={() => onSelect(u)}
					/>
				))
					: loading ? [...Array(6)].map((_, i) => (
						<Placeholder key={i} style={{ width: 120, height: 28, margin: '3px 0px' }} />
					))
						: <div className="error">error</div>
				}
			</div>
		</div>
	)
}