import React from 'react'

import style from './container.module.sass'

type Props = {
	name: string
	children: React.ReactNode
}

const MetricContainer = ({name, children}: Props) => {
	return (
		<div className={style.container}>
			<div className={style.titleContainer}>
				<div className={style.title}>{name}</div>
			</div>
			<div className={style.contentContainer}>
				{children}
			</div>
		</div>
	)
	
}

export default MetricContainer