export type FieldContentType = 'field' | 'tablefield' // For notifications


export const PHAROS = 'PHAROS'
export const QUESTOS = 'QUESTOS'
export const BASTION = 'BASTION'
export const SCHOOL = 'SCHOOL'
export const JOURNAL = 'JOURNAL'
export const SITES = {
    PHAROS,
    QUESTOS,
    BASTION,
    SCHOOL,
    JOURNAL,
}
export type SiteNameType = typeof PHAROS | typeof QUESTOS | typeof BASTION | typeof SCHOOL | typeof JOURNAL