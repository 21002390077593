import React from 'react'

import DRAGABLE_SVG from './drag-texture.svg'
import style from './index.module.sass'
import { DraggableAttributes } from '@dnd-kit/core'
import { SyntheticListenerMap } from '@dnd-kit/core/dist/hooks/utilities'

type Props = {
	attributes: DraggableAttributes
	listeners: SyntheticListenerMap | undefined
}

const styles = {
	dragHandle: {
		backgroundImage: `url("${DRAGABLE_SVG}")`,
	}
}

export const DragHandle = ({attributes, listeners}: Props) => (
	<div
		{...attributes}
		{...listeners}
		className={style.sortHandle}
		style={styles.dragHandle}
	/>
)
