import React, {useState} from 'react'
import { useMutation } from '@apollo/client'

import { useGlobalContext } from 'components/context'
import { ModalProps } from '../../common'
import { Notifications_fieldNotification_List_objects as NotificationType } from '../../gql-types/Notifications'
import { notifiationsUserList_user_List as UserList } from './gql-types/notifiationsUserList'
import { createNotificationUser as CreateNotificationUser } from './gql-types/createNotificationUser'
import {
	SAVE_NOTIFICATION,
	DEACTIVATE_NOTIFICATION,
	DEACTIVATE_NOTIFICATION_USER,
	CREATE_NOTIFICATION_USER,
} from './queries'
import { UserSelect, EVERYONE } from './user-select'
import style from './item.module.sass'


const getText = (num: number) => {
	const dayWord = Math.abs(num) == 1 ? " day" : " days"
	if(num > 0) return num + dayWord + ' after'
	else if(num < 0) return -num + dayWord + ' before'
	else if(num == 0) return 'On the day'
}

type PillProps = {
	title: string
	pillClass: string
	onDelete?: () => void
}

const Pill = ({title, pillClass, onDelete}: PillProps) => (
	<div className={style.pillcontainer}>
		<div className={pillClass}>{title}</div>
		{onDelete &&
			<div className={style.pillDelete} onClick={onDelete}>x</div>
		}
	</div>
)

type ItemProps = ModalProps & {
	notification: NotificationType
	users: UserList['objects']
	refetch: () => Promise<{}>
}

export const NotificationItem = ({notification, users, refetch, ...modalProps}: ItemProps) => {
	const globalContext = useGlobalContext()
	const [deactivateNotificationMutation, {}] = useMutation(DEACTIVATE_NOTIFICATION)
	const [deactivateNotificationUserMutation, {}] = useMutation(DEACTIVATE_NOTIFICATION_USER)
	const [createNotificationUserMutation, {}] = useMutation<CreateNotificationUser>(CREATE_NOTIFICATION_USER)
	const [saveNotificationMutation, {}] = useMutation(SAVE_NOTIFICATION)

	const deactivateNotificationUser = (u: NotificationType['fieldNotificationUsers'][0]) => {
		deactivateNotificationUserMutation({variables: {id: u.id}})
	}
	
	const deactivateNotification = async (n: NotificationType) => {
		await deactivateNotificationMutation({variables: {id: n.id}})
	}

	const [showAdd, setShowAdd] = useState(false);

	const handleUserAdd = async (userId: string) => {
		setShowAdd(false)
		if (userId == EVERYONE) {
			await saveNotificationMutation({variables: {
				id: notification.id,
				delta: notification.delta,
				fieldId: modalProps.fieldId.toString(),
				fieldType: modalProps.fieldType,
				sendToOrganisation: !notification.sendToOrganisation,
			}})
		} else {
			await createNotificationUserMutation({variables: {userId, fieldNotificationId: notification.id}})
		}
		await refetch()
	}

	const notificationUsers = notification.fieldNotificationUsers.filter(u => u.isActive)

	return (
		<div className={style.row}>
			<div className={style.deltaText}>{getText(notification.delta)}</div>
			<div className={style.sendToContainer}>
				<div>
					{notificationUsers.length == 0 && !notification.sendToOrganisation &&
						<Pill title="Nobody" pillClass={style.nobody} />
					}
					{notification.sendToOrganisation &&
						<Pill title="Everyone" pillClass={style.everyone} onDelete={() => handleUserAdd(EVERYONE)} />
					}
					{notificationUsers.map(u => (
						<Pill
							key={u.id}
							title={`${u.user.firstName} ${u.user.lastName}`}
							pillClass={style.user}
							onDelete={() => deactivateNotificationUser(u)}
						/>
					))}
					{!notification.sendToOrganisation && !showAdd &&
						<div className={style.addPeople} onClick={() => setShowAdd(!showAdd)}>Add more people</div>
					}
					{showAdd &&
						<div className={style.userSelectContainer}>
							<UserSelect
								users={users.filter(u1 => !notificationUsers.find(u2 => u1.id == u2.user.id))}
								onSelect={handleUserAdd}
							/>
						</div>
					}
				</div>
			</div>
			<div style={{width: 30}}>
				{ (!notification.sendToOrganisation || globalContext.user.topLevelManager) &&
					<div
						className={`icon-bin ${style.deleteIcon}`}
						onClick={() => deactivateNotification(notification)}
					/>
				}
			</div>
		</div>
	)
}
