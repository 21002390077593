import { ApolloError, GraphQLErrors } from "@apollo/client/errors"
import _pickBy from 'lodash/pickBy'

export function getPersistentValue(name: string) {
	return localStorage.getItem(name)
}

export function setPersistentValue(name: string, value: string) {
	return localStorage.setItem(name, value)
}

export function getCookie(name: string) {
	const value = "; " + document.cookie
	const parts = value.split("; " + name + "=")
	if (parts.length == 2 && parts) {
		return parts.pop()?.split(";").shift()
	} else {
		return null
	}
}

export function hasExtension(fileName: string, exts: string[]) {
		fileName = fileName.toLowerCase()
		return (new RegExp('(' + exts.join('|').replace(/\./g, '\\.') + ')$')).test(fileName)
}

export function isValidUrl(string: string) {
	let url;

	try {
		url = new URL(string);
	} catch (_) {
		return false;
	}

	return url.protocol === "http:" || url.protocol === "https:";
}

export enum RequestFailures {
	PERMISSION_DENIED = "PERMISSION_DENIED",
	UNKNOWN_ERROR = "UNKNOWN_ERROR",
}

export const getErrorType = (error: ApolloError | undefined, errors: GraphQLErrors | undefined) => {
	if (errors) {
		for (const error of errors) {
			if (error.message.includes('Permission denied')) {
				return RequestFailures.PERMISSION_DENIED
			}
		}
		return RequestFailures.UNKNOWN_ERROR
	} else if (error) {
		return RequestFailures.UNKNOWN_ERROR
	}
		
	return null
}

export const checkImage = (f: File) => {
	const MAX_SIZE = 6 //Mb
	if(!hasExtension(f.name, ['png', 'jpg', 'jpeg'])){
		return "Please select a valid image file (png or jpg)"
	}
	else if(f.size/1024/1024 > MAX_SIZE) {
		return `Please an image under ${MAX_SIZE}Mb`
	}
	return null
}

export const validEmail = (email: string) => {
	return !!String(email)
		.toLowerCase()
		.match(
			/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
		)
}

export const getQueryParamString = (object: {[k: string]: any}) => {
	const filterdObject = _pickBy(object, (v, k) => ((v !== undefined) && (v!== null)))
	var str = filterdObject
		? Object.keys(filterdObject).map(k => `${k}=${filterdObject[k]}`).join("&") 
		: ""

	str = str.length > 0 
		? "?" + str 
		: ""
	return str
}
