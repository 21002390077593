import gql from 'graphql-tag'

export const SAVE_NOTIFICATION = gql`
	mutation saveFieldNotification($id: ID, $fieldId: String!, $fieldType: String!, $delta: Int!, $sendToOrganisation: Boolean!) {
		fieldNotification_Update(input: {
			id: $id,
			fieldId: $fieldId,
			fieldContentType: $fieldType,
			delta: $delta,
			sendToOrganisation: $sendToOrganisation,
		}) {
			object {
				id
				delta
				sendToOrganisation
				isActive
				fieldNotificationUsers {
					id
					isActive
					user {
						firstName
						lastName
					}
				}
			}
			errors {
				field
				messages
			}
		}
	}
`


export const DEACTIVATE_NOTIFICATION = gql`
	mutation deactivateNotification($id: ID!) {
		fieldNotification_Update(input: {id: $id, isActive: false}) {
			object {
				id
				isActive
			}
			errors {
				field
				messages
			}
		}
	}
`

export const DEACTIVATE_NOTIFICATION_USER = gql`
	mutation deactivateNotificationUser($id: ID!) {
		fieldNotificationUser_Update(input: {id: $id, isActive: false}) {
			object {
				id
				isActive
			}
			errors {
				field
				messages
			}
		}
	}
`

export const CREATE_NOTIFICATION_USER = gql`
	mutation createNotificationUser($userId: ID!, $fieldNotificationId: ID!) {
		fieldNotificationUser_Update(input: {user: $userId, fieldNotification: $fieldNotificationId}) {
			object {
				id
				isActive
				user {
					id
				}
			}
			errors {
				field
				messages
			}
		}
	}
`