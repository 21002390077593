import React from 'react'
import gql from 'graphql-tag'
import { useQuery } from '@apollo/client'
import { Spin } from 'antd'

import { Error } from 'components/common/error-boundary'
import { MergePdfForm }  from './merge-pdf-form'
import { pdfs as PdfQueryType } from './gql-types/pdfs'



const GET_PDFS = gql`
	query pdfs($recordId: ID!) {
		pdf_List(recordId: $recordId, orderBy: [{field: form_Category_Rank}, {field: form_Rank}]) {
			objects {
				id
				name
				created
				form {
					id
					category {
						id
						title
					}
				}
			}
		}
	}
`

type Props = {
	recordId: string
}

export const CreateMergedPdf = ({ recordId }: Props) => {
	const {data, loading, error } = useQuery<PdfQueryType>(GET_PDFS, {variables: { recordId }})
	return (
		<div>
			{ loading && <Spin size="large" /> }
			{ !loading && error && <Error /> }
			{ !loading && data?.pdf_List?.objects &&
				<MergePdfForm pdfs={data.pdf_List.objects} />
			}
		</div>
	)
}