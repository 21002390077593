import React from 'react'

import PdfSection from './pdf-section'
import QuickLinks from './quick-links'
import ExternalLink from './external-link'
import { useFormContext } from 'state/main-form/reducer'
import { FormDropdown } from './dropdown'
import { SubmitButtonDeprecated } from './submit-button-deprecated'
import { SavingIndicator } from './saving-indicator'
import style from './index.module.sass'
import { FormActions } from './form-actions'
import { CompleteFormActionButton } from './form-actions/complete-button'


export const FormControlPanel = () => {
	const formContext = useFormContext()

	if(formContext.publicForm?.code) {
		return (
			<div className={style.buttonRow}>
				{formContext.publicForm.formAction
					? <CompleteFormActionButton formAction={formContext.publicForm.formAction} />
					:<SubmitButtonDeprecated />
				}
				<SavingIndicator />
			</div>
		)
	}
	else {
		return (
			<>
				{!formContext.record.archived &&
					<div className={style.buttonRow}>
						<SavingIndicator />
						<FormDropdown />
					</div>
				}
				{ formContext.form.allowPublicLinks &&
					<div>
						<ExternalLink readOnly={formContext.record.archived} />
					</div>
				}

				<FormActions />
				
				<PdfSection
					recordId={formContext.record.id}
					formId={formContext.form.id}
					readOnly={formContext.record.archived}
				/>

				<QuickLinks formId={formContext.form.id}/>
			</>
		)
	}
}
