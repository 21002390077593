import React from 'react'
import { Link } from 'react-router-dom'
import cx from 'classnames'
import _sortBy from 'lodash/sortBy'
import _uniq from 'lodash/uniq'
import { useGlobalContext } from 'components/context'
import { formUrl } from 'utils/url'
import { Record, Record_record_template_formSet_dashboard as Dashboard } from './gql-types/Record'
import style from './form-set-dashboard.module.sass'


type Props = {
	// dont need this double NonNullable after big migration
    dashboard: Dashboard
    record: NonNullable<Record["record"]>
}

type Field = Props["dashboard"]["dashboardFields"][0]
type Section = Props["dashboard"]["sections"][0]
type FieldItem = {
    type: 'field'
} & Field
type SectionItem = {
    type: 'section'
} & Section

export default (props: Props) => { 
	let fields: {[k: string]: Field} = {}
	let sections: {[k: string]: Section} = {}
	props.dashboard.dashboardFields.forEach(f => fields[f.rank] = f) // this will clobber fields with same rank
	props.dashboard.sections.forEach(s => sections[s.rank] = s)
	const context = useGlobalContext()
	let ranks = [...Object.keys(fields), ...Object.keys(sections)].map(i => parseInt(i))
	ranks = _sortBy(_uniq(ranks))



	let allList: (FieldItem | SectionItem)[] = []
	ranks.map(r => {
		if(sections[r]) {
			allList.push({...sections[r], type: 'section'})
		}
		if(fields[r]) {
			allList.push({...fields[r], type: 'field'})
		}
	})

	let hideFieldsList: typeof allList = []

	allList.map((item, i) => {
		if(item.type == 'section') {
			hideFieldsList.push(item)
		}
		else {
			if(item.hideIfBlank && item.answers.length == 0) {
			}
			else {
				hideFieldsList.push(item)
			}
		}
	})

	//hide sections with no fields (due to team visiblility)
	let hideSectionsList: typeof allList = []
	hideFieldsList.map((item, i) => {
		if(item.type == 'section') {
			if(i == hideFieldsList.length - 1) {
			}
			else if(hideFieldsList[i+1].type == 'section') {
			}
			else {
				hideSectionsList.push(item)
			}
		}
		else {
			hideSectionsList.push(item)
		}
	})

	return (
		<div>
			{hideSectionsList.map((item, i) => 
				<div key={item.id + item.type}>
					{item.type == 'section' &&
						<h6 className={style.section}>{item.name}</h6>
					}
					{item.type == 'field' &&
						<Link
							key={item.field.id}
							to={formUrl({recordId: props.record.id, formId: item.form, ...context})}
							className={style.field}
						>
							<span className={style.title}>{item.field.title}:</span>

							{(item.answers.length == 0) ? 
								<span className={cx("muted", style.inlineContent)}> No content </span> 
							:
								item.answers.map((a, i) => 
									<span
										key={i}
										className={cx({
											[style.inlineContent]: item.answers.length == 1,
											[style.blockContent]: item.answers.length > 1
										})}
									>
										<span >
											{a}
										</span>
									</span>
								)
							}
						</Link>
					}
				</div>
			)}
		</div>
	)
}