import React, { useCallback, useRef, useState } from 'react'
import { Link } from 'react-router-dom'
import Map, { Layer, LayerProps, MapProps, MapRef, Popup, Source } from "react-map-gl"
import "mapbox-gl/dist/mapbox-gl.css"
import { GeoJSONFeature, MapEvent, MapMouseEvent, ProjectionSpecification } from 'mapbox-gl'

import { useGlobalContext } from 'components/context'
import { useAppSelector } from 'utils/hooks'
import { MAPBOX_PUBLIC_TOKEN } from 'utils/constants'
import { formUrl } from 'utils/url'
import { AnswerTexts_answerText_List } from './gql-types/AnswerTexts'
import style from './index.module.sass'

type Props = {
	answers: {[id: string]: AnswerTexts_answerText_List["objects"][0]}
}

type FeatureProperties = {properties: {id: string}}

type Geometry = {
	type: string,
	coordinates: [number, number],
}

const POPUP_OFFSET = [0,12]
const MAP_STYLE = { alignSelf: "stretch" }
const INTERACTIVE_LAYER_IDS = ['unclustered-point', 'clusters']

const LAYERS: LayerProps[] = [
	{
		id: 'clusters',
		type: 'circle',
		source: 'earthquakes',
		filter: ['has', 'point_count'],
		paint: {
		'circle-color': [
			'step',
			['get', 'point_count'],
			'#51bbd6', 100,
			'#f1f075', 750,
			'#f28cb1'
		],
		'circle-stroke-width': 1.5,
		'circle-stroke-color': '#fff',
		'circle-radius': [
			'step',
			['get', 'point_count'],
			20, 100,
			30, 750,
			40
		],
		},
	},
	{
		id: 'cluster-count',
		type: 'symbol',
		source: 'earthquakes',
		filter: ['has', 'point_count'],
		layout: {
			'text-field': ['get', 'point_count_abbreviated'],
			'text-font': ["Open Sans Bold","Arial Unicode MS Bold"],
			'text-size': 16,
		},
	},
	{
		id: 'unclustered-point',
		type: 'circle',
		source: 'earthquakes',
		filter: ['!', ['has', 'point_count']],
		paint: {
			'circle-color': '#11b4da',
			'circle-radius': 8,
			'circle-stroke-width': 1.5,
			'circle-stroke-color': '#fff'
		},
	},
]

type LngLat = {lng: number, lat: number}

const AUSTRALIA_VIEW_STATE: [LngLat, LngLat] = [{lng: 164, lat: -10}, {lng: 111, lat:-39}]

const getBounds = (answers: Props['answers']) => {
	const curr = [{lat: 90, lng: -180}, {lat:-90, lng:180}]

	let SWCorner = curr[0]
	let NECorner = curr[1]

	Object.values(answers).forEach(a => {
		if (a.addressGeolocation) {
			SWCorner.lng = Math.max(a.addressGeolocation.lng, SWCorner.lng)
			SWCorner.lat = Math.min(a.addressGeolocation.lat, SWCorner.lat)

			NECorner.lng = Math.min(a.addressGeolocation.lng, NECorner.lng)
			NECorner.lat = Math.max(a.addressGeolocation.lat, NECorner.lat)
		}
	})

	const latPadding = Math.max((NECorner.lat - SWCorner.lat) * 0.25, 0.1)
	const lngPadding = Math.max((SWCorner.lng - NECorner.lng) * 0.25, 0.1)

	SWCorner.lat = Math.max(-90, SWCorner.lat - latPadding)
	NECorner.lat = Math.min(90, NECorner.lat + latPadding)
	
	SWCorner.lng = Math.min(180, SWCorner.lng + lngPadding)
	NECorner.lng = Math.max(-180, NECorner.lng - lngPadding)

	return [curr[0], curr[1]] as [LngLat, LngLat]
}

const answersToFeatures = (answers: Props['answers']) => {
	return Object.values(answers).map(a => ({
		type: 'Feature',
		geometry: {
			type: 'Point',
			coordinates: [a.addressGeolocation?.lng, a.addressGeolocation?.lat],
		},
		properties: {id: a.id},
	}))
}



export const MapboxMap = ({ answers }: Props) => {
	const globalContext = useGlobalContext()
	const printableDashboard = useAppSelector(state => state.userInterface.printableDashboard)
	const isEmpty = Object.keys(answers).length === 0
	const initialViewStateProps = useRef<{initialViewState?: MapProps["initialViewState"]}>(
		{initialViewState: {
			bounds: isEmpty ? AUSTRALIA_VIEW_STATE : getBounds(answers)
		}}
	)
	
	const [popupPoints, setPopupPoints] = useState<{lng: number, lat: number, answerIds: string[]} | null>(null)
	const closePopupPoints = useCallback(() => {
		setPopupPoints(null)
	}, [setPopupPoints])
	
	const mapRef = useRef<MapRef>(null)

	const pointData = useRef({
		type: 'FeatureCollection',
		features: answersToFeatures(answers)
	})

	if (pointData.current.features.length != Object.keys(answers).length) {
		pointData.current.features = answersToFeatures(answers)
	}

	const onLoad = useCallback((e: MapEvent) => {
		const map = e.target
		for (const id of ['clusters', 'unclustered-point']) {
			map.on('mouseenter', id, () => {
				if (!map) return
				map.getCanvas().style.cursor = 'pointer'
			})
			map.on('mouseleave', id, () => {
				if (!map) return
				map.getCanvas().style.cursor = ''
			})
		}
	}, [])

	const pointClick = useCallback((e: MapMouseEvent) => {
		if (!e.features) {
			return 
		}
		
		const typedFeatures = e.features as unknown as FeatureProperties[]
		
		// For some reason features appear twice sometimes
		const s = new Set()
		const features = typedFeatures.filter((f) => {
			if (!f.properties) {
				console.error("Unable to map", e.features)
				return false
			}
			if (s.has(f.properties.id)) {
				return false
			}
			s.add(f.properties.id)
			return true
		})

		setPopupPoints({...e.lngLat, answerIds: features.map(f => f.properties!.id)})
	}, [setPopupPoints])

	const clusterClick = useCallback(async (e: MapMouseEvent) => {
		if (!mapRef.current) {
			return
		}
		const features = mapRef.current.queryRenderedFeatures(e.point, {
			layers: ['clusters']
		})

		const pointCount = features[0].properties?.point_count
		const clusterId = features[0].properties?.cluster_id

		const source = mapRef.current.getSource('earthquakes') as mapboxgl.GeoJSONSource

		const result = await new Promise<{allPointsEqual: boolean, features: GeoJSONFeature[]}>((resolve) => {
			source.getClusterLeaves(clusterId, pointCount, 0, (err, leaveFeatures) =>{
				if (!leaveFeatures) {
					return
				}
				
				const allPointsEqual = leaveFeatures.every(v => {
					const a = leaveFeatures[0].geometry as Geometry
					const b = v.geometry as Geometry
					return a.coordinates[0] === b.coordinates[0]
					&& a.coordinates[1] === b.coordinates[1]
				})
				
				resolve({allPointsEqual, features: leaveFeatures as unknown as GeoJSONFeature[]})
			})
		})

		if (result.allPointsEqual) {
			pointClick({...e, features: result.features, preventDefault: () => {}, defaultPrevented: false})
			return
		}

		source.getClusterExpansionZoom(clusterId, (err, zoom) => {
			if (err || !zoom) {
				console.error("Unable to zoom")
				return
			}
			const geometry = features[0].geometry as Geometry
			mapRef.current!.easeTo({
				center: geometry.coordinates,
				zoom: zoom
			})
		})
	}, [mapRef.current, pointClick])

	const onMapClick = useCallback((e: MapMouseEvent) => {
		if (!e.features || !e.features[0]?.layer) {
			// Click on the map, outside of a marker should hide popup
			setPopupPoints(null)
			return
		}
		const layerId = e.features![0].layer!.id
		if (layerId === 'clusters') {
			clusterClick(e)
		} else if (layerId == 'unclustered-point') {
			pointClick(e)
		}
	}, [clusterClick, pointClick])

	return (
		<Map
			key={`map${Object.keys(answers).length}-${printableDashboard}`}
			ref={mapRef}
			{...initialViewStateProps.current}
			style={MAP_STYLE}
			onClick={onMapClick}
			mapStyle="mapbox://styles/pharoshradmin/cm4eehqb7000e01sfcbe0fbqz"
			mapboxAccessToken={MAPBOX_PUBLIC_TOKEN}
			interactiveLayerIds={INTERACTIVE_LAYER_IDS}
			onLoad={onLoad}
			projection={{name: "mercator"}}
			
		>
			<Source
				id="earthquakes"
				type={'geojson'}
				data={pointData.current}

				cluster={true}
				clusterMaxZoom={15} // Always keep clusters
				clusterRadius={50}
			/>
			{LAYERS.map(l => <Layer key={l.id} {...l} />)}
			
			{popupPoints && 
				<Popup
					longitude={popupPoints.lng}
					latitude={popupPoints.lat}
					closeOnClick={false}
					anchor='top'
					offset={POPUP_OFFSET}
					closeButton={false}
					onClose={closePopupPoints}
					closeOnMove
				>
					{popupPoints.answerIds.map(id => answers[id]).map(a => 
						<div key={a.id} className={style.link}>
							{a.link ?
								<Link
									to={formUrl({
										...globalContext,
										recordId: a.link.recordId,
										formId: a.link.formId,
										highlightFieldId: a.field.id
									})}
								>
									<div className={style.recordName}>{a.link.recordName}</div>
									<div>{a.link.formTitle}</div>
								</Link>
								: "Invalid link"
							}
						</div>
					)}
				</Popup>
			}

			{ isEmpty &&
				<Popup
					longitude={(AUSTRALIA_VIEW_STATE[0].lng + AUSTRALIA_VIEW_STATE[1].lng) / 2}
					latitude={(AUSTRALIA_VIEW_STATE[0].lat + AUSTRALIA_VIEW_STATE[1].lat) / 2}
					closeOnClick={false}
					anchor='top'
					closeButton={false}
				>
						<div className={style.link}>
							You have no addresses
						</div>
				</Popup>
			}
		</Map>
	)
}