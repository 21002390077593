import React from 'react'
import { useDispatch } from "react-redux"
import { showModal } from 'state/modal/actions'
import { useFormContext } from "state/main-form/reducer"
import { useAppSelector } from 'utils/hooks'
import { resetAnswers } from 'state/answer/thunks'
import { selectFormIsDirtyFromUserInteraction } from 'state/answer/selectors'


export const useResetForm = () => {
	const showSaveWarning = useAppSelector(selectFormIsDirtyFromUserInteraction)
	const formContext = useFormContext()
	const dispatch = useDispatch()

	const showRestFormModal = () => {
		dispatch(showModal({
			title: 'Are you sure?',
			content:
				<p>
					Resetting the form will delete data from the form: <b>{formContext.record.name}</b>.
					This action will reset all of the specific data for <b>{formContext.record.name} - {formContext.record.position}</b>.
					All other data will be retained.
					A final pdf of the current data will be created before the Reset is completed.
					Click <b>Reset Form</b> to continue.
				</p>,
			confirmClass: 'button-red',
			confirmText: 'Reset Form',
			confirmAction: () => {
				dispatch(resetAnswers({
					formId: formContext.form.id,
					recordId: formContext.record.id,
				}))
			},
		}))
	}

	const showUnsavedChangesModal = () => {
		dispatch(showModal({
			title: 'Unable to reset form: Unsaved changes',
			content: `Please save the form or revert your changes by refreshing the page.`,
			cancelText: 'Cancel'
		}))
	}

	const showResetModal = () => {
		if (showSaveWarning) {
			showUnsavedChangesModal()
		}
		else {
			showRestFormModal()
		}
	}
	return showResetModal
}