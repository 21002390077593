import React from 'react'

import { loginUrl, getDjangoDomain} from 'utils/url'

const RedirectToLoginInner = () => {
	window.location.href = getDjangoDomain() + loginUrl();
	return null
}

export const RedirectToLogin = () => {
	return (
		<RedirectToLoginInner />
	)
}