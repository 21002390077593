import React, { useState } from 'react'
import c from 'classnames'
import gql from "graphql-tag"
import { useQuery } from '@apollo/client'
import Placeholder from 'components/common/placeholder'

import TeamItem from './team-item'
import style from './index.module.sass'
import { teams as QueryType, teamsVariables as QueryVariables } from './gql-types/teams'
import { getUser_user as UserType } from '../gql-types/getUser'


const GET_TEAMS = gql`
	query teams($userId: ID!) {
		team_List(
			limit: 200,
			offset: 0,
			orderBy: [{field: module_Name}, {field: name}]
		) {
			objects {
				id
				name
				module {
					id
					name
				}
				memberId(userId: $userId)
			}
		}
	}
`

type Props = {
	user: UserType
}

const UserTeamsInner = (props: Props) => {
	const { data, error, loading } = useQuery<QueryType, QueryVariables>(
		GET_TEAMS,
		{ variables: {userId: props.user.id} }
	)
	const [editable, updateEditable] = useState(false)
	return (
		<>
			{ loading ?
				<>
					<Placeholder style={{height: 30, width: 200}} />
					<Placeholder style={{height: 30, width: 200}} />
					<Placeholder style={{height: 30, width: 100}} />
				</>
			: (error || !data || !data.team_List)
				? <div>Error</div>
			: (
				<>
					{editable && <div className="muted">Tick the items you would like {props.user.firstName} to be able to access.</div>}
					{data.team_List.objects.map(t => (
						<TeamItem key={t.id} team={t} user={props.user} editable={editable} />
					))}
					{ data.team_List.objects.length === 0 
						? <div className={style.warningMessage}>There isn't anything to give access to.</div>
						: !editable && data.team_List.objects.filter(t => t.memberId).length === 0 && (
							<div className={style.warningMessage}>{props.user.firstName} doesn't have access to anything.</div>
						)
					}
					{ data.team_List.objects.length !== 0  &&
						<div>
							{!editable && 
								<button
									className={c("button-grey", "button-small", "icon-pencil", style.editButton)}
									onClick={() => updateEditable(!editable)}
								>
									{"Edit"}
								</button>
							}
						</div>
					}
				</>
			)}
		</>
	)
}

export default (props: Props) => {
	return (
		<div>
			<h4>Access</h4>
			{props.user.topLevelManager
				? <div>This user is an admin and has access to everything.</div>
				: <UserTeamsInner {...props} />
			}
		</div>
	)
}