import React, { useState } from 'react'
import { Modal, Radio, Switch, Typography } from 'antd'
import { useFormContext } from 'state/main-form/reducer'
import { UndoOutlined, PlusOutlined } from '@ant-design/icons'
import { validEmail } from 'utils/functions'
import { Input, TextArea } from 'components/common/inputs'
import { SelectUser } from './select-user'
import { User } from './select-user/user'
import style from './index.module.sass'
import { SelectContact } from './select-contact'
import { useGlobalContext } from 'components/context'

const { Title } = Typography;

export type AssigneeType = 'user' | 'anon' | 'contact'
export type Assignee = {
	assigneeType: AssigneeType
	assignedUser: { id: string, firstName: string, lastName: string, email: string } | null
	assignedAnonEmail: string
	assignedAnonName: string
	assignedAnonEmailAnswer: {id: string, content: string | null, contactName: string | null} | null
}
export type PartialAssignee = { [K in keyof Assignee]?: Assignee[K] }

type Props = {
	update: (toUpdate: PartialAssignee) => void
	assignee: Assignee
	disabledUsers: string[]
	disabledAnswers: string[]
}

export const isAssigneeValid = (assignee: Assignee) => {
	const invalidAuthUser = (assignee.assigneeType == 'user' && !assignee.assignedUser)
	const invalidAnonUser = (assignee.assigneeType == "anon" && (
		!assignee.assignedAnonName
		|| !assignee.assignedAnonEmail
		|| !validEmail(assignee.assignedAnonEmail)
	))
	const invalidContactUser = (assignee.assigneeType == "contact" && !assignee.assignedAnonEmailAnswer?.id)

	if (invalidAuthUser || invalidAnonUser || invalidContactUser) {
		return false
	}

	if (assignee.assigneeType == "anon" && !validEmail(assignee.assignedAnonEmail)) {
		return false
	}
	return true
}

export const emptyAssignee = () => {
	const a: Assignee = {
		assigneeType: 'user',
		assignedUser: null,
		assignedAnonEmail: '',
		assignedAnonName: '',
		assignedAnonEmailAnswer: null,
	}
	return a
}

export const SelectAssignee = ({ update, assignee, disabledUsers, disabledAnswers }: Props) => {
	const formContext = useFormContext()
	const { currentModule, user } = useGlobalContext()
	const {
		assigneeType,
		assignedUser,
		assignedAnonEmail,
		assignedAnonName,
	} = assignee;

	return (
		<>
			<Title level={5} style={{ marginTop: 0 }}>Type</Title>
			<Radio.Group value={assigneeType} onChange={(a) => update({ assigneeType: a.target.value || 'user' })}>
				<Radio.Button value="user">User</Radio.Button>
				<Radio.Button value="anon">Email</Radio.Button>
				<Radio.Button value="contact">From {currentModule.recordWord}</Radio.Button>
			</Radio.Group>
			{assigneeType === 'user' &&
				<>
					<br /><br />
					<Title level={5}>Select User</Title>
					{assignedUser &&
						<div className={style.selectedUserContainer}>
							<User user={assignedUser} />
							<UndoOutlined className={style.resetIcon} onClick={() => update({ assignedUser: null })} />
						</div>
					}
					{!assignedUser &&
						<SelectUser
							disabledUsers={disabledUsers}
							onSelect={(u) => {
								update({ assignedUser: u })
							}}
							teamId={formContext.record.team.id}
						/>
					}
				</>
			}
			{assigneeType === 'anon' &&
				<>
					<div>
						<Title level={5}>Name</Title>
						<Input
							value={assignedAnonName}
							onChange={(e) => update({ assignedAnonName: e.target.value })}
						/>
					</div>
					<div>
						<Title level={5}>Email</Title>
						<Input
							value={assignedAnonEmail}
							onChange={(e) => update({ assignedAnonEmail: e.target.value })}
						/>
					</div>
				</>
			}
			{assigneeType == 'contact' &&
				<div>
					<Title level={5}>Select email</Title>
					<SelectContact
						disabledAnswers={disabledAnswers}
						onSelect={(c) => update({
							assignedAnonEmailAnswer: {id: c.id, content: c.email, contactName: c.name},
						})}
					/>
				</div>
			}
		</>
	)
}
