import React, { useEffect, useRef } from 'react'
import c from 'classnames'

import style from './index.module.sass'


type TextProps = React.InputHTMLAttributes<HTMLInputElement>

export const Input = React.forwardRef(
	({className, type="text", ...inputProps}: TextProps, ref: React.Ref<HTMLInputElement>) => {
		return (
			<input
				type={type}
				ref={ref}
				className={c(style.input, className)}
				{...inputProps}
			/>
		)
	}
)

type TextAreaProps = React.TextareaHTMLAttributes<HTMLTextAreaElement> & {
	minHeight?: number
}

export const TextArea = React.forwardRef(({className, minHeight, ...inputProps}: TextAreaProps, ref: React.Ref<HTMLTextAreaElement>) => {
		const inputEl = useRef<HTMLTextAreaElement>(null)

		useEffect(() => {
			const height = inputEl.current?.scrollHeight
			if (height && inputEl.current) {
				inputEl.current.style.height = 'inherit'
				const computed = window.getComputedStyle(inputEl.current)
				let height = parseInt(computed.getPropertyValue('border-top-width'), 0)
							+ parseInt(computed.getPropertyValue('padding-top'), 0)
							+ inputEl.current.scrollHeight
							+ parseInt(computed.getPropertyValue('padding-bottom'), 0)
							+ parseInt(computed.getPropertyValue('border-bottom-width'), 0)
				if (minHeight) {
					height = Math.min(minHeight, height)
				}
				inputEl.current.style.height = `${height - 10}px`
			}
		}, [inputProps.value])

		return (
			<textarea
				ref={(el)=> {
					// @ts-ignore
					inputEl.current = el
					if(ref) {
						// @ts-ignore
						ref.current = el
					}
				}}
				className={c(style.input, className)}
				{...inputProps}
			/>
		)
	}
)

type IconTextProps = TextProps & {
	iconClass: string
} 

export const IconTextInput = React.forwardRef(({iconClass, className, ...inputProps}: IconTextProps, ref: React.Ref<HTMLInputElement>) => {
	return (
		<div className={style.container}>
			<div className={c(style.icon, iconClass)} />
			<Input
				ref={ref}
				className={c(style.iconInput, className)}
				{...inputProps}
			/>
		</div>
	)
})
