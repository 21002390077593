import React from 'react'
import { Link } from 'react-router-dom'
import c from 'classnames'

import { useGlobalContext, useRouter } from 'components/context'
import { settingsOrganisationUrl, settingsUserDetailsUrl, settingsUserMfaUrl, settingsUserPasswordUrl } from 'utils/url'
import style from './index.module.sass'

const USER_ROUTES = [
	{
		name: 'Details',
		url: settingsUserDetailsUrl(),
	},
	{
		name: 'Reset Password',
		url: settingsUserPasswordUrl(),
	},
	{
		name: 'MFA Keys',
		url: settingsUserMfaUrl(),
	},
]

const ORG_ROUTES = [
	{
		name: 'Details',
		url: settingsOrganisationUrl(),
	},
]

export const LeftMenu = () => {
	const router = useRouter()
	const { user } = useGlobalContext()
	return (
		<div className={style.containerOuter}>
			<div className={style.container}>
				<div className={style.title}>Settings</div>
				<div className={style.settingsGroup}>
					<div className={style.groupTitle}>Account</div>
					{USER_ROUTES.map(r => (
						<Link
							key={r.url}
							to={r.url}
							className={c(style.listItem, { [style.listItemActive]: r.url === router.location.pathname })}
						>
							{r.name}
						</Link>
					))}
				</div>
				{user.topLevelManager && 
					<div className={style.settingsGroup}>
						<div className={style.groupTitle}>Organisation</div>
						{ORG_ROUTES.map(r => (
							<Link
								key={r.url}
								to={r.url}
								className={c(style.listItem, { [style.listItemActive]: r.url === router.location.pathname })}
							>
								{r.name}
							</Link>
						))}
					</div>
				}
			</div>
		</div>
	)
}
