import React, { useState } from 'react'
import { Modal } from 'antd'

import { useDispatch } from 'react-redux'
import { SpinnerButton } from 'components/common/button'
import { Input } from 'components/common/inputs'
import { answersSubmit } from 'state/answer/thunks'
import { useAppSelector } from 'utils/hooks'
import { useFormContext } from 'state/main-form/reducer'


export const SubmitButtonDeprecated = () => {
	const dispatch = useDispatch()
	const formContext = useFormContext()
	const loading = useAppSelector(state => state.answers.submitting)
	const [showSubmit, setShowSubmit] = useState(false)
	const [email, setEmail] = useState("")
	const onClick = () => dispatch(answersSubmit({submitterEmail: email}))
	
	return (
		<div>
			<button
				className="button-primary-solid"
				onClick={() => setShowSubmit(true)}>
				{formContext.form.publicLinkSubmitButtonText || 'Submit'}
			</button>
			
			<Modal title="Submit Form" open={showSubmit} width={650} footer={null} onCancel={() => setShowSubmit(false)}>
				<p> After submitting, you won't be able to make any changes.</p>
				{formContext.publicForm?.requestEmailOnSubmit &&
					<>
						<p> If you would like to receive a copy via email, please enter your email below.</p>
						<Input
							onChange={(e) => setEmail(e.target.value)}
							value={email}
							type="email"
							placeholder="Email"
							autoFocus
						/>
					</>
				}
				<div style={{marginTop: 10, width: '100%', display: 'flex', justifyContent: 'flex-end'}}>
					<SpinnerButton loading={loading} onClick={onClick}>Submit</SpinnerButton>
				</div>
			</Modal>
		</div>
	)
}