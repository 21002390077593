import React, { useState, useEffect } from 'react'
import c from 'classnames'
import { useSelector } from 'react-redux'
import Team from './team'
import Record from './record'
import { ReduxState } from 'types/redux'
import style from './index.module.sass'
import { useGlobalContext } from 'components/context'
import { TEAM_PANEL, RECORD_PANEL, PanelTypes } from './const'
import { useAppSelector } from 'utils/hooks'

const PANEL_TO_STYLE = {
	[TEAM_PANEL]: style.viewTeam,
	[RECORD_PANEL]: style.viewRecord,
}

type Props = {
	recordId: string | null
}

const LeftSidePanel = (props: Props) => {
	const globalContext = useGlobalContext()
	const defaultPanel = props.recordId
		? RECORD_PANEL
		: TEAM_PANEL
	const [showPanel, setShowPanel] = useState<PanelTypes>(defaultPanel)
	const printableDashboard = useAppSelector(state => state.userInterface.printableDashboard)
	const fullScreenField = useAppSelector(state => state.userInterface.fullScreenField)

	useEffect(
		() => {
			setShowPanel(TEAM_PANEL)
		},
		[globalContext.currentTeam,],
	)
	useEffect(
		() => {
			if(props.recordId) {
				setShowPanel(RECORD_PANEL)
			} else {
				setShowPanel(TEAM_PANEL)
			}
		},
		[props.recordId,],
	)


	return (
		<div
			className={style.leftMenuContainer}
			style={(printableDashboard || fullScreenField) ? {width:0} : {}}
		>
			<div className={c(style.leftMenuDouble, PANEL_TO_STYLE[showPanel])}>
				<div className={style.leftMenuViewContainer}>
					<div className={style.leftMenuView}>
						<Team
							setShowPanel={setShowPanel}
							recordId={props.recordId}
						/>
					</div>
				</div>
				<div className={style.leftMenuViewContainer}>
					<div className={style.leftMenuView}>
						<Record
							setShowPanel={setShowPanel}
							recordId={props.recordId}
						/>
					</div>
				</div>
			</div>
		</div>
		
	)
}

export default LeftSidePanel