import gql from 'graphql-tag'
export * from './gql-types/SaveAnswersPublic'

export const SAVE_ANSWERS_PUBLIC = gql`
	mutation SaveAnswersPublic(
		$formSave: form_save_pubInput!
		$answerText: [AnswerTextPubBulkObjectInput!]!
		$answerDecimal: [AnswerDecimalPubBulkObjectInput!]!
		$answerDate: [AnswerDatePubBulkObjectInput!]!
		$answerBoolean: [AnswerBooleanPubBulkObjectInput!]!
		$answerFile: [AnswerFilePubBulkObjectInput!]!
	) {
		formSavePub_Update(input: $formSave) {
			object {
				id
			}
		}
		answerTextPub_BulkUpdate(input: { objects: $answerText }) {
			errors {
				field
				messages
			}
			objects {
				id
				content
				fieldId
				tableFieldId
				contentType
				objectId
				rank
				deactivated
			}
		}
		answerDatePub_BulkUpdate(input: { objects: $answerDate }) {
			errors {
				field
				messages
			}
			objects {
				id
				content
				fieldId
				tableFieldId
				contentType
				objectId
				rank
				deactivated
			}
		}
		answerDecimalPub_BulkUpdate(input: { objects: $answerDecimal }) {
			errors {
				field
				messages
			}
			objects {
				id
				content
				fieldId
				tableFieldId
				contentType
				objectId
				rank
				deactivated
			}
		}
		answerBooleanPub_BulkUpdate(input: { objects: $answerBoolean }) {
			errors {
				field
				messages
			}
			objects {
				id
				content
				fieldId
				tableFieldId
				contentType
				objectId
				rank
				deactivated
			}
		}
		answerFilePub_BulkUpdate(input: { objects: $answerFile }) {
			errors {
				field
				messages
			}
			objects {
				id
				content
				fieldId
				tableFieldId
				contentType
				objectId
				rank
				deactivated

				name
			}
		}
	}
`