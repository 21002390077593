import React from 'react'
import { useQuery } from "@apollo/client"
import gql from "graphql-tag";

import { Spinner } from 'components/common/loading'
import { adminTeams as AdminTeams, adminTeamsVariables as AdminTeamsVariables } from './gql-types/adminTeams'
import { Switch } from './switch'

import style from './teams.module.sass'

const GET_TEAMS = gql`
	query adminTeams($organisation: ID!) {
		teamAdmin_List(
			limit: 300,
			organisation: $organisation,
		) {
			objects {
				id
				name
				organisation{
					id
				}
			}
		}
	}
`

type Props = {
	orgId: string
}

export const Teams = ({orgId}: Props) => {
	const { data, loading, error } = useQuery<AdminTeams, AdminTeamsVariables>(GET_TEAMS, {
		variables: {organisation: orgId},
	})
	if (loading) {
		return <Spinner />
	}
	if (!data?.teamAdmin_List?.objects) {
		return <div>Error</div>
	}
	if (data?.teamAdmin_List?.objects.length == 0) {
		return <div>No teams</div>
	}

	return (
		<div className={style.teamsContainer}>
			{data.teamAdmin_List.objects.map(b => (
				<div key={b.id} className={style.team}>
					<div className={style.leftCol}>
						<div className={style.id}>{b.id}</div>
						<div className={style.teamName}>{b.name}</div>
					</div>
					<Switch orgId={b.organisation.id} teamId={b.id}/>
				</div>
			))}
		</div>
	)
}