import React, { useState } from 'react'
import { Modal, Typography } from 'antd'
import { PlusOutlined } from '@ant-design/icons'
import { SelectAssignee, isAssigneeValid } from '../../select-assignee'
import { Actions, CreateEscalation, Escalation, EscalationWithType, PartialEscalation, State, emptyEscalation } from '../hooks'
import { Input } from 'components/common/inputs'
import { es } from 'date-fns/locale'
// import style from './index.module.sass'

const { Title } = Typography;

type Props = {
	dispatch: React.Dispatch<Actions>
	state: State
}

export const AddEscalation = ({ dispatch, state }: Props) => {
	const [show, setShow] = useState(false)
	const [error, setError] = useState(false)
	const [escalation, setEscalation] = useState<EscalationWithType>(emptyEscalation())

	const create = () => {
		const valid = isAssigneeValid(escalation)
		setError(!valid)
		if (!valid) {
			return
		}
		dispatch({ type: 'CREATE', obj: escalation })
		setEscalation(emptyEscalation())
		setShow(false)
	}

	return (
		<>
			<Modal
				title="Add Escalation"
				open={show}
				maskClosable
				onCancel={() => setShow(false)}
				footer={null}
				width={800}
			>
				<p>Select a user that will be CCed to the email when overdue.</p>
				<SelectAssignee
					update={(a: PartialEscalation) => {setEscalation({...escalation, ...a})}}
					assignee={escalation}
					disabledUsers={Object.values(state).filter(a => !a.deleted && a.assignedUser?.id).map(a => a.assignedUser!.id)}
					disabledAnswers={Object.values(state).filter(a => !a.deleted && a.assignedAnonEmailAnswer?.id).map(a => a.assignedAnonEmailAnswer!.id)}
				/>
				<br /><br />
				<Title level={5}>Days overdue until escalation</Title>
				<Input
					onChange={(e) => setEscalation({...escalation, deltaDays: Math.min(parseInt(e.target.value), 365) || 0})}
					value={escalation.deltaDays.toString()}
					type="number"
					placeholder="Days"
				/>
				<br /><br />
				{error && <div className="error">Please ensure all fields are correct</div>}
				<div>
					<button className="button-primary-solid" onClick={create}>Create</button>
				</div>

			</Modal>
			<button className="button-grey-solid" onClick={() => setShow(true)}><PlusOutlined /> Escalation</button>
		</>
	)
}
