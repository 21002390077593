import { getCookie, getQueryParamString } from 'utils/functions'



export const ROOT_URL = '/api/v1/'


export function getCsrfToken() {
  return getCookie('csrftoken') || ''
}

class Resource {
	name: string

	constructor(name: string) {
		this.name = name
	}

	get(id: string | number, params: {[k: string]: any}) {
		const queryParams = getQueryParamString(params)
		return fetch(ROOT_URL + `${this.name}/${id}${queryParams}`, {
			method: 'GET',
			credentials: 'include',
			headers: new Headers({
				'Content-Type': 'application/json'
			})
		})
	}

	query(params: {[k: string]: any}) {
		return fetch(ROOT_URL + `${this.name}${getQueryParamString(params)}`, {
			method: 'GET',
			credentials: 'include',
			headers: new Headers({
				'Content-Type': 'application/json'
			})
		})
	}

	create(data: {[k: string]: any}, params: {[k: string]: any}) {
		return fetch(ROOT_URL + `${this.name}${getQueryParamString(params)}`, {
			method: 'POST',
			credentials: 'include',
			headers: new Headers({
				'Content-Type': 'application/json',
				'X-CSRFToken': getCsrfToken()
			}),
			body: JSON.stringify(data)
		})
	}

	save(obj: {[k: string]: any}, params: {[k: string]: any}) {
		return fetch(ROOT_URL + `${this.name}/${obj.id}${getQueryParamString(params)}`, {
			method: 'PATCH',
			credentials: 'include',
			headers: new Headers({
				'Content-Type': 'application/json',
				'X-CSRFToken': getCsrfToken()
			}),
			body: JSON.stringify(obj)
		})
	}

	saveFormData(obj: {[k: string]: any}, file: File) {
		var data = new FormData()

		Object.keys(obj).forEach(k => {
			if(obj[k] != undefined) {
				data.append(k, obj[k])
			}
		})
		// data.append("data", JSON.stringify(obj))
		
		if(file) {
			data.append("file", file)
		}

		return fetch(ROOT_URL + `${this.name}/${obj.id}`, {
			method: 'PUT',
			credentials: 'include',
			headers: new Headers({
				'X-CSRFToken': getCsrfToken()
			}),
			body: data
		})
	}

	createFormData(obj: {[k: string]: any}, file: File) {
		var data = new FormData()

		Object.keys(obj).forEach(k => {
			if(obj[k] != undefined) {
				data.append(k, obj[k])
			}
		})	
		
		if(file) {
			data.append("file", file)
		}

		return fetch(ROOT_URL + `${this.name}`, {
			method: 'POST',
			credentials: 'include',
			headers: new Headers({
				'X-CSRFToken': getCsrfToken()
			}),
			body: data
		})
	}

	delete(id: string) {
		return fetch(ROOT_URL + `${this.name}/${id}`, {
			method: 'DELETE',
			credentials: 'include',
			headers: new Headers({
				'Content-Type': 'application/json',
				'X-CSRFToken': getCsrfToken()
			})
		})
	}
}


export const Folder = new Resource('folders')
export const File = new Resource('files')
export const TabularRecordMetric  = new Resource('tabular_record_metric')

export const resetFormRequest = ({formId, recordId}: {formId: string, recordId: string}) => {
	return fetch(ROOT_URL + `forms/${formId}/reset?record_id=${recordId}`, {
		method: 'post',
		credentials: 'include',
		headers: new Headers({
			'Content-Type': 'application/json',
			'X-CSRFToken': getCsrfToken(),
		}),
	})
}

export const getAvailableDataRequest = (queryString: string) => {
	return fetch(ROOT_URL + 'csv_available_data' + queryString, {
		method: 'get',
		credentials: 'include',
		headers: new Headers({
			'Content-Type': 'application/json',
		})
	})
}
