import React from 'react'

import { SinglePanel } from 'components/common/panel'
import { Events } from './events'
import { ActiveExternalLinks } from './active-external-links'
import Metrics from './metrics'
import { useGlobalContext } from 'components/context'
import style from './module-dashboard.module.sass'


const ModuleDashboard = () => {
	const globalContext = useGlobalContext()
	return (
		<SinglePanel noPadding type="full-width" noBoxShadow>
			<div className={style.container}>
				<Metrics />
				<div className={style.eventLists}>
					{globalContext.currentModule.showActiveLinksOnDashboard &&
						<ActiveExternalLinks/>
					}
					<Events />
				</div>
			</div>
		</SinglePanel>

	)
}

export default ModuleDashboard