import React from 'react'
import { useQuery } from '@apollo/client'
import gql from 'graphql-tag'
import { RouteComponentProps } from 'react-router-dom'
import "mapbox-gl/dist/mapbox-gl.css"

import { analyticsLink } from 'fragments'
import { useGlobalContext } from 'components/context'
import { LoadingBar } from 'components/common/loading'
import { AnswerTexts, AnswerTexts_answerText_List_objects, AnswerTextsVariables } from './gql-types/AnswerTexts'
import style from './index.module.sass'
import { MapboxMap } from './map'


const GET_ADDRESSES_QUERY = gql`
	query AnswerTexts(
		$moduleId: ID!,
		$teamId: ID,
	) {
		answerText_List(
			module: $moduleId,
			team: $teamId,
			deactivated_Isnull: true,
			objectActive: true,
			isAddressWithContent: true,
			orderBy: [
				{field: content, modifiers: [CASE_INSENSITIVE]},
			]
		) {
			objects {
				id
				content
				contentObjectName
				field {
					id
					title
				}
				tableField {
					id
					title
				}
				addressGeolocation {
					id
					lng
					lat
				}
				link {
					...MetricFormLinkEssentials
				}
			}
		}
	}
	${analyticsLink}
`

type Props = RouteComponentProps

const AnswerMap = ({ }: Props) => {
	const globalContext = useGlobalContext()

	const { data, loading } = useQuery<AnswerTexts, AnswerTextsVariables>(GET_ADDRESSES_QUERY, {
		variables: {
			moduleId: globalContext.currentModule.id,
			teamId: globalContext.currentTeam && globalContext.currentTeam.id,
		},
	})

	const answerMap: {[id: string]: AnswerTexts_answerText_List_objects} = {}
	if (data?.answerText_List?.objects) {
		data?.answerText_List?.objects.forEach(a => {
			answerMap[a.id] = a
		})
	}

	return (
		<div className={style.container}>
			{loading && <LoadingBar small />}
			{data?.answerText_List?.objects &&
				<MapboxMap answers={answerMap} />
			}
		</div>
	)
}

export default AnswerMap