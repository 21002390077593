import React from 'react'
import c from 'classnames'
import style from './index.module.sass'
import { CloseOutlined } from '@ant-design/icons'

export type UserType = {
	id: string
	firstName: string
	lastName: string
}

type Props = {
	header?: boolean
	priority: React.ReactNode
	type: React.ReactNode
	name: React.ReactNode
	requestSignature: React.ReactNode
	reminder: React.ReactNode
	action?: React.ReactNode
}
export const Row = (props: Props) => {
	return (
		<div className={c(style.container, {[style.containerHeader]: props.header})}>
			<div className={style.cellPriority}>{props.priority}</div>
			<div className={style.cellName}>{props.name}</div>
			<div className={style.cellType}>{props.type}</div>
			<div className={style.cellSignature}>{props.requestSignature}</div>
			<div className={style.cellReminder}>{props.reminder}</div>
			<div className={style.cellAction}>{props.action}</div>
		</div>
	)
}