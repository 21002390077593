import React from 'react'
import styles from './index.module.sass'

const COLOR_MAP = {
	red: '#d64b4b',
	blue: '#427fd8',
}

type Props = {
	children: React.ReactNode
	color: keyof typeof COLOR_MAP
} 

export const Pill = ({children, color}: Props) => {
	return (
		<div
			className={styles.pill}
			style={{backgroundColor: COLOR_MAP[color]}}
		>
			{children}
		</div>
	)
}