import React, { Component } from 'react'
import gql from "graphql-tag";
import { useQuery } from '@apollo/client'
import { RouteComponentProps } from 'react-router-dom'

import { useGlobalContext } from 'components/context'
import RecordEditForm from './edit-form'
import { AddRecordInfo } from './gql-types/AddRecordInfo'
import { GetRecord, GetRecordVariables } from './gql-types/GetRecord'
import { GET_RECORD } from './queries'
import Container from './container'
import { FormLoading } from './common'
import { useTitle } from 'utils/hooks';
import { Title } from 'components/common/typeography';


const GET_ADD_RECORD_INFO = gql`
	query AddRecordInfo {
		hasRecord: record_List(offset:0, limit: 1) {
			objects {
				id
			}
		}
	}
`

type Props = {
	copyRecord: GetRecord["record"] | null
}

const AddRecordContainer: React.FC<Props> = (props) => {
	const { copyRecord } = props
	const { currentTeam, currentModule } = useGlobalContext()

	const { data, error, loading } = useQuery<AddRecordInfo>(
		GET_ADD_RECORD_INFO,
	)

	useTitle(`Add ${currentModule.recordWord}`)

	if(loading) {
		return <FormLoading />
	}
	else if (error || !data || !data.hasRecord) return <div>error</div>

	let title, subtitle
	if(data.hasRecord!.objects.length) { 
		
		title = copyRecord
			? `Copy ${currentModule.recordWord}`
			: `Add ${currentModule.recordWord}`

		subtitle = ''
	}
	else {
		title = `Welcome!`
		subtitle = `Its time to create your first ${currentModule.recordWord.toLowerCase()}`
	}
	return (
		<>
			<Title type="page">{title}</Title>
			{subtitle && <h5 className="common-form-subtitle">{subtitle}</h5>}
			
			<RecordEditForm
				initialValues={{
					id: null,
					name: '',
					position: copyRecord ? copyRecord.position : '',
					team: currentTeam,
					image: null,
					copyRecord,
					template: copyRecord ? copyRecord.template : null,
				}}
				dedicatedCopyUrl={!!copyRecord}
			/>
		</>
			
	)
}

type OuterProps = RouteComponentProps<{recordId: string}>

const GetCopyRecord: React.FC<OuterProps> = (props) => {
	const globalContext = useGlobalContext()
	const copyRecordId = props.match.params.recordId
	let newProps: Props = {
		copyRecord: null
	}
	if (copyRecordId) {
		const { data, error, loading } = useQuery<GetRecord, GetRecordVariables>(
			GET_RECORD,
			{variables: {id: copyRecordId}}
		)
		if(loading) return <FormLoading />
		if(!data || error) {
			return <div>Error fetching {globalContext.currentModule.recordWord} to copy</div>
		}
		newProps.copyRecord = data.record
	}
	
	return <AddRecordContainer {...props} {...newProps} />
}

export default (props: OuterProps) => (
	<Container>
		<GetCopyRecord {...props} />
	</Container>
)