import gql from 'graphql-tag'
import { useMutation } from '@apollo/client'
import { CompleteFormAction, CompleteFormActionVariables } from './gql-types/CompleteFormAction'
import { CompleteFormActionPub, CompleteFormActionPubVariables } from './gql-types/CompleteFormActionPub'
import { SignatureType } from './complete-modal'
import { useDispatch } from 'react-redux'
import { mainFormSlice } from 'state/main-form/reducer'
import { isReusableSignature, isTextSignature, isUploadSignature } from './complete-modal/common'

const COMPLETE_FORM_ACTION = gql`
	mutation CompleteFormAction(
			$id: ID!,
			$completedOn: DateTime,
			$signatureImage: Upload,
			$signatureText: String,
			$signatureNote: String,
			$reuseSignatureFormActionId: ID
		) {
		formAction_Update(input: {
			id: $id,
			completedOn: $completedOn,
			signatureImage: $signatureImage,
			signatureText: $signatureText,
			signatureNote: $signatureNote,
			reuseSignatureFormActionId: $reuseSignatureFormActionId
		}) {
			object {
				id
				completedOn
			}
		}
	}
`

const COMPLETE_FORM_ACTION_PUB = gql`
	mutation CompleteFormActionPub(
		$id: ID!,
		$completedOn: DateTime,
		$signatureImage: Upload,
		$signatureNote: String,
		$signatureText: String
	) {
		formActionPub_Update(input: {
			id: $id,
			completedOn: $completedOn,
			signatureImage: $signatureImage,
			signatureNote: $signatureNote,
			signatureText: $signatureText
		}) {
			object {
				id
				completedOn				
			}
		}
	}
`

type Props = {
	id: string
	code: string | null
	signature: SignatureType | null
}


export const useComplete = ({ id, code, signature }: Props) => {
	const args = {
		id,
		completedOn: new Date(),
		signatureNote: signature?.signatureNote,
		...(
			!signature ? {}
			: isReusableSignature(signature) ? { reuseSignatureFormActionId: signature.reusePreviousFormAction.id }
			: isTextSignature(signature) ? { signatureImage: signature?.file, signatureText: signature?.text, }
			: isUploadSignature(signature) ? { signatureImage: signature?.file }
			: {}
		),

	} satisfies CompleteFormActionVariables

	const dispatch = useDispatch()

	const [completeFormAction, { loading }] = useMutation<CompleteFormAction, CompleteFormActionVariables>(
		COMPLETE_FORM_ACTION,
		{
			variables: args,
			refetchQueries: ['pdfGenerators'],
			onCompleted: () => {
				if (signature) {
					dispatch(mainFormSlice.actions.setLockedFromFormAction(true))
				}
			},
		}
	)
	const [completeFormActionPub, { loading: loadingPub }] = useMutation<CompleteFormActionPub, CompleteFormActionPubVariables>(
		COMPLETE_FORM_ACTION_PUB,
		{
			variables: args,
			context: { headers: { "X-Public-Form-Code": code } },

		}
	)

	return {
		completeMutation: code ? completeFormActionPub : completeFormAction,
		loading: loading || loadingPub,
	}

}
