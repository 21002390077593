import React from 'react'
import { Record } from './gql-types/Record'
import { wrapWithContext, GlobalContextProps } from 'components/context'
import style from './details-section.module.sass'
import { Title } from 'components/common/typeography'


type Props = {
    record: NonNullable<Record["record"]>
    refreshRecordList: () => void
} & GlobalContextProps


export default wrapWithContext(({ record, refreshRecordList, currentModule }: Props) => (
    <div className={style.container}>
        { record.image && 
            <div
                className={style.picture}
                style={{ backgroundImage: `url(${record.image})` }}
            />
        }
        <div>
            <Title type="page">{record.name}</Title>
            <div className={style.position}>{record.position}</div>
        </div>
    </div>
))