import React from 'react'
import { Route } from 'react-router-dom'
import { useQuery } from '@apollo/client'

import { SinglePanel } from 'components/common/panel'
import AddUser from './user/add'
import EditUser from './user/edit'
import LeftMenu, { GET_USERS } from './left-menu'
import style from './index.module.sass'
import { userManagementAddUrl, userManagementEditUrl } from 'utils/url'
import { userList as QueryType } from './left-menu/gql-types/userList'
import { UserListContext } from './context'
import { useTitle } from 'utils/hooks'
import { useGlobalContext } from 'components/context'


export const UserManagement = (props: { children: React.ReactNode }) => {
	const { user } = useGlobalContext()
	const apolloProps = useQuery<QueryType>(
		GET_USERS, { skip: !user.topLevelManager }
	)
	useTitle(`User management`)

	return (
		<UserListContext.Provider value={apolloProps.refetch}>
			<div className={style.container}>
				<LeftMenu {...apolloProps} />
				<SinglePanel type="thin">
					{user.topLevelManager ?
						<>
							<Route path={userManagementAddUrl()} component={AddUser} />
							<Route path={userManagementEditUrl(':userId')} component={EditUser} />
						</>
						: <div>You do not have access to this page</div>
					}
				</SinglePanel>
			</div>
		</UserListContext.Provider>
	)
}