import React, { useState } from 'react'
import { useMutation } from '@apollo/client'
import * as Yup from 'yup'
import { Formik, Field } from 'formik'
import gql from "graphql-tag"

import { SpinnerButton } from 'components/common/button'
import { TextField } from 'components/common/formik'
import { useGlobalContext } from 'components/context'
import { UserSettingsUpdate, UserSettingsUpdateVariables } from './gql-types/UserSettingsUpdate'

const UPDATE_USER = gql`
	mutation UserSettingsUpdate(
		$id: ID!,
		$email: String!,
		$firstName: String!,
		$lastName: String!
	) {
		user_Update(input: {
			id: $id,
			email: $email,
			firstName: $firstName,
			lastName: $lastName
		}) {
			object {
				id
				email
				firstName
				lastName
			}
			errors {
				field
				messages
			}
		}
	}
`


export const UpdateUser = () => {
	const { user } = useGlobalContext()
	const [saved, setSaved] = useState(false)
	const [updateUser, {}] = useMutation<UserSettingsUpdate, UserSettingsUpdateVariables>(UPDATE_USER)
	return (	
		<Formik
			initialValues={{
				email: user.email,
				firstName: user.firstName,
				lastName: user.lastName,
			}}
			validationSchema={Yup.object().shape({
				email: Yup.string().email().required('Required'),
				firstName: Yup.string().min(1).required('Required'),
				lastName: Yup.string().min(1).required('Required'),
			})}
			onSubmit={(values, actions) => {
				updateUser({variables: {id: user.id, ...values}}).then(r => {
					setSaved(true)
					actions.resetForm()
				})
			}}
		>
			{props => {
				const { dirty, isSubmitting, handleSubmit } = props
				return (
					<>
						<h3 className="common-form-title">Account Details</h3>
						<form onSubmit={handleSubmit} className="common-form">
							{[
								['email', 'Email'],
								['firstName', 'First name'],
								['lastName', 'Last name'],
							].map(([name, placeholder]) => {
								return <Field
									key={name}
									name={name}
									placeholder={placeholder}
									component={TextField}
								/>
							})}
							
							<SpinnerButton loading={isSubmitting} type="submit">
								{(saved && !dirty) ? 'Saved!' : 'Save'}
							</SpinnerButton>
						</form>
					</>
				)
			}}
		</Formik>
	)
}