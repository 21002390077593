import React from 'react'
import gql from "graphql-tag"
import { Formik, Field } from 'formik'
import { TextField, ImageField } from 'components/common/formik'
import { SpinnerButton } from 'components/common/button'
import * as Yup from 'yup'
import { Tooltip, TooltipProps } from 'antd'
import { useMutation } from '@apollo/client'
import { useGlobalContext, GlobalContextProps, useRouter } from 'components/context'
import { settingsOrganisationUrl, teamMetricsDefaultUrl } from 'utils/url'
import { showNotification } from 'state/user-interface'
import { useDispatch } from 'react-redux'
import { apolloClient } from 'state/apollo-client'


const UPDATE_TEAM = gql`
	mutation team_Update($id: ID!, $name: String!, $reminderFrequencyDays: Int, $active: Boolean!) {
		team_Update(input: {id: $id, name: $name, reminderFrequencyDays: $reminderFrequencyDays, active: $active}) {
			object {
				id
				name
				reminderFrequencyDays
			}
		}
	}
`

type Props = {
	team: NonNullable<GlobalContextProps["currentTeam"]>
}

export default ({team}: Props) => {
	const [updateTeam, {}] = useMutation(UPDATE_TEAM)
	const globalContext = useGlobalContext()
	const router = useRouter()
	const dispatch = useDispatch()

	return (
		<>
			<Formik
				initialValues={{
					name: team.name,
					reminderFrequencyDays: team.reminderFrequencyDays,
				}}
				validationSchema={Yup.object().shape({
					name: Yup.string().required('Required'),
					reminderFrequencyDays: Yup.number().min(1, "Frequency cannot be less than a day").max(365, "Frequency cannot be more than 1 year").required('Required'),
				})}
				onSubmit={(values, actions) => {
					updateTeam({variables: {id: team.id, active: true, ...values}}).then(() => {
					}).catch((r) => {
						console.error(r)
						actions.setFieldError('all', 'There was a problem saving data')
					}).finally(() => {
						actions.setSubmitting(false)
					})
				}}
			>
				{({ errors, isSubmitting, handleSubmit }) => (
					<form onSubmit={handleSubmit} className="common-form">
						<h3 className="common-form-title">{globalContext.currentModule.teamWord} settings</h3>
						<Field
							name="name"
							title="Name"
							placeholder="Name"
							component={TextField}
						/>

						<Tooltip
							placement={"bottom"}
							title={globalContext.user.topLevelManager ? "" : "This field is only editable by admins"}
						>
							<Field
								name="reminderFrequencyDays"
								title="Reminde Frequency (days)"
								placeholder="Reminder Frequency"
								component={TextField}
								disabled={!globalContext.user.topLevelManager}
								type="number"
							/>
						</Tooltip>

						<div className="error">{
							//@ts-ignore
							errors.all}</div>

						<div style={{display: 'flex', justifyContent: 'space-between', width: '100%'}}>
							{globalContext.user.topLevelManager &&
								<button
									className="button-small button-red"
									onClick={async () => {
										if (window.confirm(`Are you sure you want to archive ${team.name}?`)) {
											await updateTeam({variables: {...team, active: false}})
											router.history.push(teamMetricsDefaultUrl(globalContext))
											apolloClient.resetStore()
											dispatch(showNotification({
												'content': `${team.name} archived`
											}))
										}
									}}
									type="button"
								>
									Archive
								</button>
							}

							<SpinnerButton loading={isSubmitting} type="submit">
								Save
							</SpinnerButton>
						</div>
					</form>
				)}
			</Formik>
		</>
	)
}