import React from 'react'
import c from 'classnames'
import style from './index.module.sass'

type Props = {
	type: 'page'
	children: React.ReactNode
}

export const Title = ({type, children}: Props) => {
	return (
		<div className={c({[style.pageTitle]: type === 'page'})}>
			{children}
		</div>
	)
}