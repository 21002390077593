import React, { useState, useRef, useEffect } from 'react'
import c from 'classnames'
import _debounce from 'lodash/debounce'

import { teamSettingsUrl, exportDataUrl, teamFilesUrl, teamMetricsUrl } from 'utils/url'
import { Input } from 'components/common/inputs'
import { useGlobalContext } from 'components/context'
import SimpleIconButton from 'components/common/simple-icon-button'
import style from './icon-row.module.sass'
import commonStyle from '../common.module.sass'
import QrCode from './qr-code'


type Props = {
	setSearch: (s: string) => void
	search: string
}


const SearchFilter = (props: Props) => {
	const globalContext = useGlobalContext()
	const inputRef = useRef<HTMLInputElement>(null)
	const [showSearch, setShowSearch] = useState(false)
	const [innerSearch, setInnerSearch] = useState("")
	const debouncedOuterSearch = useRef(_debounce(props.setSearch, 230))
	const setSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
		setInnerSearch(e.target.value)
		debouncedOuterSearch.current(e.target.value)
	}

	useEffect(() => {
		if(showSearch) {
			inputRef.current && inputRef.current.focus()
		} else if(!showSearch && props.search) {
			props.setSearch("")
		}
	}, [showSearch])

	useEffect(() => {
		props.setSearch("")
	}, [globalContext.currentModule, globalContext.currentTeam])

	return (
		<div>
			<div className={style.iconRow}>
				<div className={c(style.searchIconMover, {[style.searchIconMoverActive]: showSearch})}>
					<SimpleIconButton
						onClick={() => setShowSearch(!showSearch)}
						iconClass="icon-search"
						title="Search"
					/>
				</div>
				<SimpleIconButton
					to={teamMetricsUrl(globalContext)}
					iconClass={'icon-gauge'}
					title="Dashboard"
				/>
				{ globalContext.organisation.csvDownloadEnabled &&
					<SimpleIconButton
						to={exportDataUrl(globalContext)}
						iconClass="icon-download"
						title="Download csv"
					/>
				}
				{ globalContext.currentTeam && globalContext.organisation.showTeamFiles && 
					<SimpleIconButton
						to={teamFilesUrl(globalContext)}
						iconClass="icon-folder"
						title="Files"
					/>
				}
				{ globalContext.currentTeam && globalContext.user.topLevelManager &&
					<SimpleIconButton
						to={teamSettingsUrl(globalContext)}
						iconClass={'icon-cog'}
						title="Settings"
					/>
				}
				{ globalContext.currentModule.publicRecordCreateLinks.length > 0 &&
					<QrCode />
				}
			</div>
			<div className={c(style.searchInputContainer, {[style.showSearchInputContainer]: showSearch})}>
				<Input
					className={style.searchInput}
					placeholder="Search..."
					ref={inputRef}
					onChange={setSearch}
					value={innerSearch}
				/>
			</div>
				
			<div className={commonStyle.lineSpacer} />
		</div>
	)
}

export default SearchFilter