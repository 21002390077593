import React, { useState } from 'react'
import gql from "graphql-tag";
import { useQuery } from '@apollo/client'

import { Spinner } from 'components/common/loading'
import { adminOrganisations as AdminOrganisations, adminOrganisationsVariables as AdminOrganisationsVariables } from './gql-types/adminOrganisations'
import { Org } from './org'

const GET_ORGANISATIONS = gql`
	query adminOrganisations($search: String!) {
		organisationAdmin_List(
			search: $search,
			limit: 300,
			annotateCounts: true,
		) {
			objects {
				id
				name
				userCount
				recordCount
			}
		}
	}
`

type Props = {
	search: string
}

export const Orgs = ({search}: Props) => {
	const { data, loading, error } = useQuery<AdminOrganisations, AdminOrganisationsVariables>(GET_ORGANISATIONS, {
		variables: {search},
	})

	if (loading) {
		return <Spinner />
	}

	if (!data?.organisationAdmin_List?.objects) {
		return <div>Error</div>
	}
	if (data?.organisationAdmin_List?.objects.length == 0) {
		return <div>No results</div>
	}

	return (
		<div>
			{data.organisationAdmin_List.objects.map(o => (
				<Org org={o} key={o.id}/>
			))}
		</div>

	)
}