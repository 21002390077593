import React, { useState, RefObject } from 'react'
import gql from 'graphql-tag'
import { RouteComponentProps } from 'react-router-dom'
import { useQuery } from '@apollo/client'
import { ArrowDownOutlined, ArrowUpOutlined, SearchOutlined } from '@ant-design/icons'

import { useGlobalContext } from 'components/context'
import { Input } from 'components/common/inputs'
import { SinglePanel } from 'components/common/panel'
import { useDebounce } from 'utils/hooks'
import { getLoadingCards } from './common'
import Rows, { ASC, DESC, ColumnOrderItem } from './rows'
import { ConcatenatedTable as ConcatenatedTableType } from './gql-types/ConcatenatedTable'
import style from './index.module.sass'
import { downloadConcatenatedTableCsvUrl } from 'utils/url'

const COLUMN_QUERY = gql`
	query ConcatenatedTable($id: ID!) {
		concatenatedTable(id: $id) {
			id
			name
			includeFields {
				id
					field {
					id
					title
				}
			}
			includeTableFields {
				id
				tableField {
					id
					title
				}
			}
		}
	}
`

const updateOrderColumns = (index: number, currentOrderColumns: ColumnOrderItem[]) => {
	let next: ColumnOrderItem[]
	if (currentOrderColumns[0]?.columnIndex === index) {
		// is primary order : switch direction or reset
		const currDirection = currentOrderColumns[0]?.direction
		if (currDirection == ASC) {
			next = [
				{
					columnIndex: index,
					direction: DESC
				},
				...currentOrderColumns.filter(o => o.columnIndex !== index)
			]
		}
		else {
			next = []
		}
	}
	else {
		// make primary and remove if in order
		next = [
			{
				columnIndex: index,
				direction: ASC,
			},
			...currentOrderColumns.filter(o => o.columnIndex !== index)
		]
	}
	return next
}

const OrderIcon = ({ index, orderColumns }: {index: number, orderColumns: ColumnOrderItem[]}) => {
	const primary = orderColumns[0]?.columnIndex === index
	const direction = orderColumns.filter(o => o.columnIndex == index)[0]?.direction
	const Icon = direction == ASC ? ArrowDownOutlined : ArrowUpOutlined
	return (
		<div className={style.orderIcon}>
			{direction && <Icon
				style={primary ? {color: '#00b7ff'} : {}}
			/>}
		</div>
	)
}

type Props = {scrollContainerRef: RefObject<HTMLDivElement>} & RouteComponentProps<{id: string}>

const ConcatenatedTable = ({ match, scrollContainerRef}: Props) => {
	const concatenatedTableId = match.params.id
	const globalContext = useGlobalContext()
	const [orderColumns, setOrderColumns] = useState<ColumnOrderItem[]>([])
	const [search, setSearch] = useState("")
	const [debouncedSearch, setDebouncedSearch] = useState(search)
	useDebounce(
		() => setDebouncedSearch(search),
		200,
		[search],
	)
	const {data: columnData, loading: mainLoading } = useQuery<ConcatenatedTableType>(COLUMN_QUERY, {variables: {id: concatenatedTableId}})

	if (mainLoading) {
		return (
			<SinglePanel noPadding type="full-width" noBoxShadow>
				<div className={style.container}>
					<br/>
					{getLoadingCards(15)}
				</div>
			</SinglePanel>
		)
	} else if (!columnData?.concatenatedTable) {
		return <div className={style.container}>error</div>
	}

	const columns = [
		{
			name: globalContext.currentModule.recordWord,
			key: 'record_name',
		},
		...columnData.concatenatedTable.includeFields.map((f, i) => ({
			name: f.field.title,
			key: f.field.title,
		})),
		...columnData.concatenatedTable.includeTableFields.map((f, i) => ({
			name: f.tableField.title,
			key: f.tableField.title,
		})),
	]

	const columnStyle = columns.map(c => ({flexBasis: 100, flexGrow: 1}))

	return (
		<SinglePanel noPadding type="full-width" noBoxShadow>
			<div className={style.container}>
				<div className={style.actionContainer}>
					<div className={style.searchContainer}>
						<SearchOutlined />
						<Input
							className={style.searchInput}
							placeholder="Search..."
							value={search}
							onChange={v => setSearch(v.target.value)}
						/>
					</div>
					<a href={downloadConcatenatedTableCsvUrl({tableId: concatenatedTableId, teamId: globalContext.currentTeam?.id || null})}>
						<button className="button-small button-outline button-secondary">Download</button>
					</a>
				</div>
				<div className={style.headerRow}>
					{columns.map((c, i) => (
						<div
							key={c.key}
							className={style.cell}
							style={columnStyle[i]}
							onClick={() => {setOrderColumns(updateOrderColumns(i, orderColumns))}}
						>
							{c.name}
							<OrderIcon index={i} orderColumns={orderColumns} />
						</div>
					))}
				</div>
				<Rows
					concatenatedTableId={match.params.id}
					columnStyle={columnStyle}
					search={debouncedSearch}
					orderColumns={orderColumns}
				/>
			</div>
		</SinglePanel>
	)
}

export default ConcatenatedTable