import gql from 'graphql-tag'

export * from './gql-types/MainFormAuth'

// Note: MainFormAuth is used by refetch queries!
export const QUERY_MAIN_FORM_AUTH = gql`
	query MainFormAuth($formId: ID!, $recordId: ID!) {
		form(id: $formId) {
			id
			title
			description
			publicLinkIsReadOnly
			publicLinkSubmitButtonText
			allowActions
			allowPublicLinks
			allowReset
			lockedFromFormAction(recordId: $recordId)
			hideControlPanel
			readOnly
			fields(orderBy: [{field: rank}]) {
				id
				rank
				title
				type
				subtitle
				contentType
				placeholder
				allowMultipleAnswers
				disabledAlways
				disableWhenPublic
				disableWhenSigning
				disableEditingRowsWhenPublic
				disabledForFormActions(recordId: $recordId) {
					id
					record {
						id
						name
					}
					form {
						id
						title
					}
				}
				mandatory
				subsectionHeading
				hiddenFieldId(recordId: $recordId)
				placeholder
				canHide
				tableFields(orderBy: [{field: rank}]) {
					id
					title
					rank
					type
					disabledAlways
					disableWhenPublic
					disableWhenSigning
					placeholder
					helpInfo {
						id
						htmlContent
					}
					selectOptions {
						id
						label
						color
						order
					}
				}
				helpInfo {
					id
					htmlContent
				}
				selectOptions {
					id
					label
					color
					order
				}
			}
		}
		record(id: $recordId) {
			id
			name
			archived
			position
			team {
				id
				organisation {
					id
				}
			}
		}
		initialAnswer_List(record: $recordId, form: $formId) {
			objects {
				id
				fieldId
				tableFieldId
				rank
				contentText
				contentDate
				contentBoolean
				contentDecimal
				contentFile
				contentFileName
			}
		}
	}
`
