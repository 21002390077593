import React from 'react'
import { Tabs, Modal } from 'antd'

import history from 'utils/history'
import { RouteComponentProps, Route, Link } from 'react-router-dom'
import { PUBLIC_FORM_CONTENT_TYPE, PDF_CONTENT_TYPE } from 'utils/constants'
import { emailModalSendUrl, emailModalHistoryUrl, formUrl } from 'utils/url'
import { useGlobalContext } from 'components/context'
import EmailHistory from './history'
import EmailSend from './send'


type Props = {
	recordId: string
	formId: string
	regardingObjectId: string
	regardingObjectContentType: string
}

const TABS = {
	SEND: 'send',
	HISTORY: 'history',
}

const EmailModal = (props: Props) => {
	const globalContext = useGlobalContext()
	const historyRoute = emailModalHistoryUrl({
		...globalContext,
		...props,
	})
	const sendRoute = emailModalSendUrl({
		...globalContext,
		...props,
	})
	
	return (
		<Modal
			title={props.regardingObjectContentType == PDF_CONTENT_TYPE
				? 'Pdf' : 'External link'
			}
			open
			maskClosable
			onCancel={() => history.push(formUrl({recordId: props.recordId, formId: props.formId, ...globalContext}))}
			footer={null}
		>
			<Tabs
				defaultActiveKey={window.location.pathname.includes('send') ? TABS.SEND : TABS.HISTORY}
				onChange={(key) => history.push(key === TABS.SEND ? sendRoute : historyRoute) }
			>
				<Tabs.TabPane tab="Send email" key={TABS.SEND}>
					<Route
						path={sendRoute}
						component={() => <EmailSend
							{...props}
						/>}
					/>
				</Tabs.TabPane>
				<Tabs.TabPane tab="Email history" key={TABS.HISTORY}>
					<Route
						path={historyRoute}
						component={() => <EmailHistory
							{...props}
						/>}
					/>
				</Tabs.TabPane>
			</Tabs>
		</Modal>
	)
}

export default EmailModal