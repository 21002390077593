import React, { createRef, useState } from 'react'
import { CSSTransition, TransitionGroup } from 'react-transition-group'
import style from './index.module.sass'

type Props = {
	elements: {
		id: string
		show: boolean
		node: React.ReactNode
		ref: React.RefObject<HTMLDivElement>
	}[]
}

export const AnswerAnimationGroup = ({ elements }: Props) => {
	return (
		<TransitionGroup appear enter exit className={style.container}>
			{elements.map(el => {
				if (!el.show) { return null }
				return <CSSTransition
					timeout={400}
					nodeRef={el.ref}
					classNames={"answer-animation"}
					in
					key={el.id}
					appear
					mountOnEnter
				>
					{el.node}
				</CSSTransition>
			})}
		</TransitionGroup>
	)
}
