import React, { Component } from 'react'
import cx from 'classnames'
import { LoadingBar } from './loading'

type Props = React.ButtonHTMLAttributes<HTMLButtonElement> & {
	loading: boolean,
	children: React.ReactNode,
	small?: boolean,
	className?: string,
	style?: React.CSSProperties
} 

export const SpinnerButton = ({loading, children, small, className, style, ...buttonProps}: Props) => {
	if(className || style) {
		console.warn("You shouldn't hand className or style into SpinnerButton")
	}
	return (
		<button
			className={cx("button-primary-solid", "button-loading", {"button-loading-small": small})}
			style={{pointerEvents: loading ? 'none' : 'inherit'}}
			{...buttonProps}
		>
			<span className="content" style={{opacity: loading ? 0.4 : 1}}>{children}</span>
			<div style={{opacity: loading ? 1 : 0, transition: 'all 0.5s linear', padding: '0px 8px'}}>
				<LoadingBar small />
			</div>
		</button>
	)
}