import React from 'react'
import StaticModal from 'components/general/modal/static'
import { useAppDispatch, useAppSelector } from 'utils/hooks'
import { toggleHelpInfoField } from 'state/user-interface'


export const HelpInfoModal = () => {
	const dispatch = useAppDispatch()
	const helpInfoField = useAppSelector(state => state.userInterface.helpInfoField)
	if (!helpInfoField) {
		return null
	}

	return (
		<StaticModal
			onClose={() => dispatch(toggleHelpInfoField(null))}
			title={helpInfoField.title}
		>
				<div dangerouslySetInnerHTML={{__html: helpInfoField.htmlContent}} />
		</StaticModal>
	)
}