import React, { useReducer } from 'react'
import { Modal } from 'antd'

import { useFormContext } from 'state/main-form/reducer'
import { FORM_ACTION_FRAGMENT } from '../common/utils'
import { ResetFormActions, ResetFormActionsVariables } from './gql-types/ResetFormActions'
import { useMutation } from '@apollo/client'
import gql from 'graphql-tag'
import { useAppDispatch } from 'utils/hooks'
import style from './index.module.sass'
import { getFormAuth } from 'state/main-form/thunks'
import { SpinnerButton } from 'components/common/button'
import { useGlobalContext, useRouter } from 'components/context'
import { formUrl } from 'utils/url'

type Props = {
	formActions: { id: string }[]
}

const RESET_FORM_ACTIONS = gql`
	mutation ResetFormActions($objects: [form_action_bulkBulkInput!]!) {
		formAction_BulkUpdate(input: {objects: $objects}) {
			objects {
				...FormAction
			}
			errors {
				field
				messages
			}
		}
	}
	${FORM_ACTION_FRAGMENT}
`

export const ResetFormActionModal = ({ formActions }: Props) => {
	const reduxDispatch = useAppDispatch()
	const globalContext = useGlobalContext()
	const formContext = useFormContext()
	const router = useRouter()

	const close = () => {
		router.history.push(formUrl({ recordId: formContext.record.id, formId: formContext.form.id, ...globalContext }))
	}


	const [resetFormAction, { loading }] = useMutation<ResetFormActions, ResetFormActionsVariables>(
		RESET_FORM_ACTIONS,
		{
			variables: {
				objects: formActions.map(({ id }) => ({ id, deleted: true }))
			},
			onCompleted: () => {
				reduxDispatch(getFormAuth({ formId: formContext.form.id, recordId: formContext.record.id }))
				close()
			}
		}
	)



	return (
		<Modal
			title="Reset Form Actions"
			open
			maskClosable
			onCancel={close}
			footer={null}
			width={800}
		>
			<p>
				Are you sure? This will remove existing form actions.
			</p>
			
			{formContext.form.lockedFromFormAction && <p>You will be able to edit this form after resetting.</p>}
			<div className={style.buttonGroup}>
				<button className="button-grey-solid" onClick={close}>Cancel</button>
				<SpinnerButton
					loading={loading}
					className="button-primary-solid"
					onClick={() => resetFormAction()}
				>
					Reset
				</SpinnerButton>
			</div>
		</Modal>
	)
}
