import React, { useState, useEffect } from 'react'
import { Route, Switch } from 'react-router-dom'

import { loginUrl, noTeamUrl, downloadAnswerFileFromPdfUrl, settingsUserPasswordUrl } from 'utils/url'
import { useGlobalContext, useRouter } from 'components/context'
import { NavBar } from './navbar'
import styles from './index.module.sass'
import { NoTeamPage } from 'components/dashboard/pages/NoTeamWarning'
import { Advice } from 'components/dashboard/pages/advice'
import { AdminPanel } from 'components/dashboard/pages/admin'
import { Settings } from 'components/dashboard/pages/settings'
import { AddModule } from 'components/dashboard/pages/add-module'
import { UserManagement } from 'components/dashboard/pages/user-management'
import { Module } from 'components/dashboard/pages/module'
import { SuiteAdminDashboard } from 'components/dashboard/pages/module/pages/analytics/dashboard/suite-admin-dashboard'
import { WrappedRouteNotFound } from 'components/common/route-not-found'
import history from 'utils/history'
import { ErrorBoundaryHoc } from 'components/common/error-boundary'
import { DownloadAnswerFile } from 'components/dashboard/pages/download-answer-file'
import {
	pharosAdminUrl,
	addModuleUrl,
	recordAddUrl,
	settingsUrl,
	userManagementUrl,
	suiteAdminDashboardUrl,
	adviceUrl,
	teamNoListUrl,
	GLOBAL_CONTEXT_PARAMS
} from 'utils/url'

const ROUTES = [
	{path: teamNoListUrl(GLOBAL_CONTEXT_PARAMS), component: ErrorBoundaryHoc(Module)},
	{path: noTeamUrl(), component: ErrorBoundaryHoc(NoTeamPage)},
	{path: adviceUrl(), component: ErrorBoundaryHoc(Advice)},
	{path: pharosAdminUrl(), component: ErrorBoundaryHoc(AdminPanel)},
	{path: settingsUrl(), component: ErrorBoundaryHoc(Settings)},
	
	{path: addModuleUrl(), component: ErrorBoundaryHoc(AddModule)},
	{path: downloadAnswerFileFromPdfUrl(':fileId'), component: ErrorBoundaryHoc(DownloadAnswerFile)},
	{path: userManagementUrl(), component: ErrorBoundaryHoc(UserManagement)},
	{path: suiteAdminDashboardUrl(), component: ErrorBoundaryHoc(SuiteAdminDashboard)},
	{path: "*", component: ErrorBoundaryHoc(WrappedRouteNotFound)},
]

export const Dashboard = () => {
	const globalContext = useGlobalContext()
	const [redirected, setRedirected] = useState(false)
	const router = useRouter()
	
	if(!globalContext.user) {
		router.history.push(loginUrl({next: router.location.pathname}))
		return null
	}

	useEffect(() => {
		const user = globalContext.user
		if(!redirected) {
			const noTeam = globalContext.organisation.suite.moduleSetItems.filter(
				m => m.module.teams.length > 0
			).length == 0
			if(user.usingTemporaryPassword) {
				history.push(settingsUserPasswordUrl())
			} else if(noTeam) {
				if(user.topLevelManager) {
					history.push(addModuleUrl())
				} else {
					history.push(noTeamUrl())
				}
			} else if(!user.recordCount) {
				const currentModule = globalContext.organisation.suite.moduleSetItems.filter(
					m => m.module.teams.length > 0
				)[0].module
				const currentTeam = currentModule.teams[0]
				history.push(recordAddUrl({currentModule, currentTeam}))
			} else {
				return
			}
			setRedirected(true)
		}
	}, [globalContext.user])
	return (
		<div className={styles.dashboard}>
			<NavBar />
			<div className={styles.dashboardPage}>
				<Switch>
					{ROUTES.map((props: {path: string, component: React.ComponentType<any>}) => (
						<Route key={props.path} path={props.path} component={props.component} />
					))}
				</Switch>
			</div>
		</div>
	)
}