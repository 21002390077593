import { CalendarUrlProps } from "utils/url"

type GetPropsFromDateArgs = (d: Date) => CalendarUrlProps
export const getPropsFromDate: GetPropsFromDateArgs = (date) => ({
	month: (date.getMonth() + 1).toString().padStart(2, "0"),
	year: date.getFullYear().toString(),
})

type getDateFromPropsArgs = (a: CalendarUrlProps) => Date
export const getDateFromProps: getDateFromPropsArgs = ({month, year}) => (
	new Date(parseInt(year), parseInt(month) - 1, 15)
)