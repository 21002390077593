import React, { useState } from 'react'
import { Modal, Typography } from 'antd'
import { PlusOutlined } from '@ant-design/icons'
import { Actions, State } from '../state'
import style from './index.module.sass'
import { Assignee, SelectAssignee, PartialAssignee, emptyAssignee, isAssigneeValid } from '../../common/select-assignee'
import { SetMiscDetails, MiscDetails, emptyDetails } from '../../common/misc-details'

const { Title } = Typography;

type Props = {
	dispatch: React.Dispatch<Actions>
	state: State
}

export const Add = ({ dispatch, state }: Props) => {
	const [show, setShow] = useState(false)
	const [assignee, setAssignee] = useState<Assignee & MiscDetails>({...emptyAssignee(), ...emptyDetails()})
	const [error, setError] = useState(false)

	const create = () => {
		const valid = isAssigneeValid(assignee)
		setError(!valid)
		if (!valid) {
			return
		}
		dispatch({ type: 'CREATE', obj: assignee })
		setAssignee({...emptyAssignee(), ...emptyDetails()})
		setShow(false)
	}

	return (
		<>
			<Modal
				title="Add Action"
				open={show}
				maskClosable
				onCancel={() => setShow(false)}
				footer={null}
				width={800}
			>
				<SelectAssignee
					update={(a: PartialAssignee) => {setAssignee({...assignee, ...a})}}
					assignee={assignee}
					disabledUsers={state.filter(a => !a.deleted && a.assignedUser?.id).map(a => a.assignedUser!.id)}
					disabledAnswers={state.filter(a => !a.deleted && a.assignedAnonEmailAnswer?.id).map(a => a.assignedAnonEmailAnswer!.id)}
				/>
				<SetMiscDetails
					update={(a) => {setAssignee({...assignee, ...a})}}
					details={assignee}
				/>
				{error && <div className="error">Please ensure all fields are correct</div>}
				<div className={style.buttonContainer}>
					<button className="button-primary-solid" onClick={create}>Create</button>
				</div>

			</Modal>
			<button className="button-primary-solid" onClick={() => setShow(true)}><PlusOutlined /> Action</button>
		</>
	)
}
