import { combineReducers } from 'redux'

type StateType = {
	[id: string]: any
}

function aggregatedTable(state: StateType = {}, action: any) {
	switch (action.type) {
		case "GOT_TABULAR_EMPLOYEE_METRIC":
			return {
				...state,
				[action.id]: {
					...state[action.id],
					...action.table
				}
			}
		case "GOT_TABULAR_EMPLOYEE_METRICS":
			const newState: StateType = {}
			action.tables.forEach((t: any) => {
				newState[t.id] = t
			})
			return newState
	}
	return state
}


export default combineReducers({
	aggregatedTable,
})