import React from 'react'
import { Empty, Popover } from 'antd'

import style from './common.module.sass'

export type FormLink = {
	fieldId: string | null
	fieldTitle: string | null
	formId:  string
	formTitle: string
	recordId: string
	recordName: string
	organisationName: string
	teamName: string
}

export const NoData = () => {
	return (
		<div className={style.container}>
			<Empty />
		</div>
	)
}

type Props = {
	isSuiteAdminDashboard?: boolean
	children: React.ReactNode
	formLink: FormLink | null
}

export const ListItemTooltip = ({isSuiteAdminDashboard, children, formLink}: Props) => {
	if (!formLink) {
		return <>{children}</>
	}
	const textPriority = [
		...(isSuiteAdminDashboard ? [formLink.organisationName || 'Unknown organisation'] : []),
		formLink.teamName || 'Unknown team',
		formLink.formTitle || 'Unknown form',
	]
	const title = textPriority[0]
	const items = textPriority.slice(1).map((item, i) => <div key={i}>{item}</div>)
	return (
		<Popover placement="right" title={title} content={items}>
			<div>{children}</div>
		</Popover>
	)
}