import React from 'react'
import c from 'classnames'
import { Link } from 'react-router-dom'

import { useGlobalContext } from 'components/context'
import style from './record-list-item.module.sass'
import { recordDashboardUrl } from 'utils/url'
import { RECORD_PANEL, PanelTypes } from '../const'
import { GetRecordList_record_List_objects as Record } from './gql-types/GetRecordList'

type Props = {
	record: Record
	setShowPanel: (panel: PanelTypes) => void
	recordParamId: string | null
}

export default (props: Props) => {
	const globalContext = useGlobalContext()
	const record = props.record
	const recordClass = c(
		style.record,
		{
			[style.recordSelected]: record.id === props.recordParamId,
		}
	)

	return (
		<Link
			className={recordClass}
			to={recordDashboardUrl({recordId: record.id, ...globalContext})}
			onClick={() => props.setShowPanel(RECORD_PANEL)}
		>
			<div className={style.name}>
				{record.name
					? record.name
					: <div className={style.noName}>No name</div>}
			</div>
			<div className={style.position}>
				{ record.position || 
					<span className={style.noName}>
						No {globalContext.currentModule.positionWord}
					</span>
				}
				{globalContext.user.topLevelManager && 
					<>&nbsp;· {record.team.name}</>
				}
			</div>
		</Link>
	)
}