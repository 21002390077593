import React, { useMemo, useEffect } from 'react'
import _pick from 'lodash/pick'
import { Switch, Route, Redirect, RouteComponentProps } from 'react-router-dom'
import gql from "graphql-tag"
import { useQuery } from '@apollo/client'

import Notifications from './general/notifications'
import { userEssentials } from 'fragments'
import MainModal from './general/modal/main'
import { GlobalContext, RouterContext, GLOBAL_CONTEXT_EMPTY } from './context'
import { WelcomeModal } from 'components/general/welcome-modal'
import { PublicRecordCreate } from 'components/public-record-create'
import { PublicFormSuccess } from 'components/general/full-page-messages/PublicFormSuccess'
import { RedirectToLogin } from 'components/common/redirect-to-login'
import { Dashboard } from 'components/dashboard'
import { DashboardLoading } from 'components/dashboard/loading'
import { Auth } from 'components/auth'
import Form from 'components/form'
import { WrappedRouteNotFound } from 'components/common/route-not-found'
import { 
	dashboardUrl,
	oldPublicLandingPageUrl,
	accountUrl,
	getTeamParams,
	publicFormUrl,
	publicCreateRecordUrl,
	publicFormSuccessUrl,
	loginUrl,
} from 'utils/url'
import { currentUser } from './gql-types/currentUser'

declare const FS: any

const GET_CURRENT_USER = gql`
	query currentUser {
		user_List(isSelf: true) {
			objects {
				...UserEssentials
				
			}
		}
	}
	${userEssentials}
`

type Props = RouteComponentProps

const AppContainer = (props: Props) => {
	const { data, loading } = useQuery<currentUser>(GET_CURRENT_USER)
	const params = getTeamParams(props.location.pathname)

	// useMemo so that the globalContext only changes if it needs to
	// thus avoiding unecessary rerenders
	const globalContext = useMemo(() => {
		const user_List = data && data.user_List
		const user = user_List && user_List.objects  && user_List.objects.length > 0 && user_List.objects[0]
		const teamId =  params.teamId == 'all' ? null : params.teamId
		if(user) {
			const organisation = user.organisation
			const currentModuleSetItem = organisation.suite.moduleSetItems.find(
				(m) => m.module.id == params.moduleId
			)
			const currentModule = currentModuleSetItem?.module!
			const currentTeam = currentModule && currentModule.teams.find(
				(t) => t.id == teamId
			) || null
			return {
				organisation,
				user,
				currentModule,
				currentTeam,
			}
		}
		else {
			return GLOBAL_CONTEXT_EMPTY
		}
	}, [params.moduleId, params.teamId, data?.user_List?.objects[0]]);

	useEffect(() => {
		if (globalContext.user && globalContext.user.id) {
			try {
				FS.identify(globalContext.user.id, {
					email: globalContext.user.email,
					organisationId: globalContext.organisation!.id,
					organisationName: globalContext.organisation!.name,
				});
			}
			catch(e) {

			}
		}
	}, [globalContext.user && globalContext.user.id])

	return (
		<RouterContext.Provider value={_pick(props, ['history', 'location', 'match'])}>
			<GlobalContext.Provider value={globalContext}>
				{ loading
					? <DashboardLoading />
					: (
						<>
							<Switch>
								<Route path='/' exact component={RedirectToLogin} />
								<Route path={oldPublicLandingPageUrl()} component={RedirectToLogin} />
								<Route path={accountUrl()} component={Auth} />
								{/* Redirc old login route, as some people have bookmarked, (changed on the 20th July 2019) */}
								<Redirect from="/login" to={{ pathname: loginUrl() }} /> 
								<Route path={dashboardUrl()} component={Dashboard} />
								<Route path={publicFormUrl(':publicFormCode')} component={Form} />
								<Route path={publicFormSuccessUrl()} component={PublicFormSuccess} />
								<Route path={publicCreateRecordUrl({teamCode: ':teamCode', linkCode: ':linkCode'})} component={PublicRecordCreate} />
								{ !(props.location.pathname === '/' || props.location.pathname.startsWith(oldPublicLandingPageUrl())) &&
									<Route path="*" component={WrappedRouteNotFound} />
								}
							</Switch>
							<MainModal />
							<Notifications />
							<WelcomeModal />
						</>
					)
				}
			</GlobalContext.Provider>
		</RouterContext.Provider>
	)
}

export default AppContainer