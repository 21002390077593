import React, { Component } from 'react'
import { SinglePanel } from 'components/common/panel'
import { useGlobalContext } from 'components/context'
import ModuleCard from './card'
import styles from './index.module.sass'

export const AddModule = () => {
	const { organisation } = useGlobalContext()
	return (
		<div className={styles.containerOuter}>
			<div className={styles.container}>
				<h4>Activate {organisation.suite.moduleWord}</h4>
				<div className={styles.cardContainer}>
					{organisation.suite.moduleSetItems.filter(msi => (
						!msi.hiddenWhenEmpty
						|| msi.module.teams.length > 0
					)).map(msi => (
						<ModuleCard key={msi.module.id} module={msi.module} />
					))}
				</div>
			</div>
		</div>
	)
}
