import React, { useState } from 'react'
import c from 'classnames'
import { FieldProps } from 'formik'
import gql from 'graphql-tag'
import { useMutation } from '@apollo/client'
import { useDispatch } from 'react-redux'

import { ANSWER_TEXT_CONTENT_TYPE, USER_CONTENT_TYPE, EMAIL_CONTENT_TYPE} from 'utils/constants'
import TickButton from 'components/common/tick-button'
import { removeEmailContact, removeEmailContactVariables } from './gql-types/removeEmailContact'
import styles from './email-field.module.sass'
import { email_Options_emailOption_List as EmailOption } from './gql-types/email_Options'
import { showNotification } from 'state/user-interface'
import { FormValues } from './types'


const REMOVE_EMAIL_CONTACT = gql`
	mutation removeEmailContact($id: ID!) {
		email_Update (
			input: {
				id: $id,
				excludeFromAddressBook: true
			}
		) {
			object {
				id
				excludeFromAddressBook
			}
			errors {
				field
				messages
			}
		}
	}
`

type EmailToggleFieldProps = FieldProps<FormValues["emailOptions"][0]>

const EmailToggleField: React.FC<EmailToggleFieldProps> = ({ field: { value, name, ...fieldProps }, form }) => {
	const [removed, setRemoved] = useState(false)
	const [removeContact, { loading: mutating }] = useMutation<removeEmailContact, removeEmailContactVariables>(
		REMOVE_EMAIL_CONTACT, {refetchQueries: []}
	)
	const dispatch = useDispatch()
	return (
		<div className={c(styles.containerOuter, {[styles.containerOuterRemoved]: removed})}>
			<div
				className={styles.container}
				onClick={() => form.setFieldValue(name, {...value, selected: !value.selected})}
			>
				<TickButton selected={value.selected}/>
				<div className={styles.details}>
					<div>
						<div className={styles.emailText}>{removed ? 'Removed' : value.email}</div>
						<div>
							{value.name}
							{value.name && value.detail && <span>&nbsp;&nbsp;·&nbsp;&nbsp;</span>}
							<span className={styles.aboutText2}>{value.detail}</span>
						</div>
					</div>
					<div className={styles.rightContainer}>
						{
							value.sourceType === EMAIL_CONTENT_TYPE ? <div className={styles.typeCustom}>Email history</div> :
							value.sourceType === USER_CONTENT_TYPE ? <div className={styles.typeUser}>User</div> :
							value.sourceType === ANSWER_TEXT_CONTENT_TYPE ? <div className={styles.typeRecord}>{value.recordWord}</div> :
							"Unknown source"
						}
					</div>
				</div>
			</div>
			<div
				className={c("icon-cross", styles.cross, {[styles.hideCross]: value.sourceType !== EMAIL_CONTENT_TYPE || removed})}
				// @ts-ignore: need to upgrade formik...
				onClick={() => removeContact({ variables: {id: value.objId}}).then(() => {
					setRemoved(true)
					dispatch(showNotification({content: `Contact removed`}))
				})}
			/>
		</div>
	)
}

export default EmailToggleField