import React from 'react'
import { Link } from 'react-router-dom'

import { formUrl, addModuleUrl, recordAddUrl } from 'utils/url'
import commonStyle from '../../common.module.sass'
import { quickLinks_quickLink_List_objects } from '../gql-types/quickLinks'
import styles from './index.module.sass'
import { useGlobalContext } from 'components/context'

const QuickLink = ({ title, records, linkToForm, moduleNotActivated, module }: quickLinks_quickLink_List_objects) => {
	const { user, organisation } = useGlobalContext()
	if (!module) {
		return null
	}
	return (
		<div>
			<div className={commonStyle.sectionTitle}>{title}</div>
			{records.map(r => (
				<Link
					key={r.id}
					to={formUrl({
						currentModule: {id: r.team.module.id},
						currentTeam: {id: r.team.id},
						recordId: r.id,
						formId: linkToForm.id,
					})}
					target="_blank"
					className={styles.link}
				>
					{r.team.name}: {r.name}
				</Link>
			))}
			{records.length === 0 &&
				<div className="muted">
					There's nothing here.<br/>
					{moduleNotActivated
						? user.topLevelManager && (
							<Link to={addModuleUrl()}>
								Enable the {module.name.toLowerCase()} {organisation.suite.moduleWord.toLowerCase()}
							</Link>
						)
						: user.topLevelManager && (
							<Link to={recordAddUrl({currentModule: module, currentTeam: null})}>
								Create your first {module.recordWord.toLowerCase()}
							</Link>
						)
					}
				</div>
			}
		</div>
	)
}

export default QuickLink

