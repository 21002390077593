import React, { useState, useRef } from 'react'
import Select, { ValueType } from 'react-select'
import _defer from 'lodash/defer'

import { Input } from 'components/common/inputs'
import SimpleIconButton from 'components/common/simple-icon-button'
import { AnswerTextBaseProps } from '../types'
import commonStyle from '../common.module.sass'
import style from './index.module.sass'
import { Option, SingleValue, OTHER_OPTION, IOption, OptionInput } from './option'
import { FieldTypeEnum } from 'state/main-form/types'
import { useAppDispatch } from 'utils/hooks'
import { updateAnswers } from 'state/answer/thunks'


const AnswerDropDown = (props: AnswerTextBaseProps) => {
	const textInput = useRef<HTMLInputElement>(null);
	const innerSelect = useRef<HTMLSelectElement>(null);
	const dispatch = useAppDispatch()
	let [options, setOptions] = useState(() => {
		const options: IOption[] = props.field.selectOptions.map(o => ({ ...o, value: o.label, labelDisplay: o.label }))
		const noAnswerMatches = props.answer.content && !props.field.selectOptions.find(o => o.label == props.answer.content)

		if (props.field.type == FieldTypeEnum.DROP_DOWN_OTHER) {
			options.push(OTHER_OPTION)
		} else if (noAnswerMatches) {
			console.error(`Answer "${props.answer.content}" doesn't match an option for field ${props.field.title}`)
			options.push(OTHER_OPTION)
		}
		return options
	})
	let selected = options.find((o: IOption) => o.label == props.answer.content) || null

	let [isOther, setIsOther] = useState(props.answer.content && !selected)

	const reset = () => {
		dispatch(updateAnswers({ answers: [{ ...props.answer, content: '' }] }))
		setIsOther(false)
		_defer(() => innerSelect.current?.focus())
	}

	const onDropdownChanged = (selectedAny: ValueType<IOption>) => {
		const selected = selectedAny as IOption

		if (Array.isArray(selected)) {
			throw ('No array allowed mate!')
		}
		if (selected === OTHER_OPTION) {
			dispatch(updateAnswers({ answers: [{ ...props.answer, content: '' }] }))
			setIsOther(true)
			_defer(() => textInput.current?.focus())
		}
		else if (selected) {
			dispatch(updateAnswers({ answers: [{ ...props.answer, content: selected?.label || "" }] }))
			setIsOther(false)
		}
	}

	return (
		props.disabled ?
			<div className={commonStyle.answerTextDisabled}>
				{props.answer.content || <i className={commonStyle.noContent}>No content</i>}
			</div>
			: (isOther) ?
				<div className={style.answerDropdownOtherContainer}>
					<Input
						className={commonStyle.formInput}
						ref={textInput}
						onChange={(e) => dispatch(updateAnswers({ answers: [{ ...props.answer, content: e.target.value }] }))}
						placeholder={props.field.placeholder || undefined}
						value={props.answer.content || ""}
					/>
					<SimpleIconButton
						small
						iconClass="icon-list"
						onClick={reset}
						title="See selectable options"
					/>
				</div>
				:
				<div className={style.answerDropdown}>
					<Select
						//@ts-ignore
						ref={innerSelect}
						options={options}
						className={style.input}
						value={selected}
						onChange={onDropdownChanged}
						placeholder={props.field.placeholder || 'Select...'}
						components={{
							SingleValue: SingleValue,
							Option: Option,
							Input: OptionInput,
							IndicatorSeparator: () => <div style={{ marginLeft: -10 }} />,
						}}
						clearable={false}
						openMenuOnFocus
					/>
				</div>
	)
}

export default AnswerDropDown