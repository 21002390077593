import React from 'react'
import c from 'classnames'
import getDate from 'date-fns/getDate'

import { AnswerDates_answerDate_List_objects as AnswerDate } from '../gql-types/AnswerDates'
import Item from './answer'
import style from './index.module.sass'

type Props = {
	date: Date,
	isToday: boolean,
	dim: boolean,
	answers: AnswerDate[]
}

const Day = ({date, isToday, dim, answers}: Props ) => {
	return (
		<div className={style.container}>
			<div className={c(style.dayOfMonth, {[style.isToday]: isToday, [style.dim]: dim})}>
				{getDate(date)}
			</div>
			<div className={style.answers}>
				{answers.map(a => (
					<Item key={a.id} answer={a} />
				))}
			</div>
		</div>
	)
}

export default Day