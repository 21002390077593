import React, { useState } from 'react'
import { FieldType } from 'state/main-form/types'
import { AnswerNumberBaseProps } from '../types'
import style from './index.module.sass'
import { updateAnswers } from 'state/answer/thunks'
import { useAppDispatch } from 'utils/hooks'


const DEFAULT_COLOR = {
	start: [110, 170, 220],
	end: [40, 100, 200],
}

const getDefaultColorForOption = (selectOptions: FieldType['selectOptions'], index: number) => {
	const percent = index / (selectOptions.length - 1)
	const arrayColor = DEFAULT_COLOR.start.map((startRgb, i) => {
		const endRgb = DEFAULT_COLOR.end[i]
		return startRgb + (endRgb - startRgb) * percent
	});
	return `rgb(${arrayColor[0]},${arrayColor[1]},${arrayColor[2]})`
}

const findSelectOptionForOrder = (order: string, selectOptions: {order: number, label: string, color: string | null}[]) => {
	const selectOption = selectOptions.find(a => a.order === parseInt(order))
	if (selectOption) {
		return selectOption
	}
	return null
}

const AnswerRating = ({answer, disabled, field}: AnswerNumberBaseProps) => {
	const dispatch = useAppDispatch()
	const [hoverOption, setHoverOption] = useState<null | string>(null)
	const activeOption = hoverOption || answer.content
	const activeSelectOption = activeOption && (findSelectOptionForOrder(activeOption, field.selectOptions)) || null
	const activeColor = activeOption && (
		activeSelectOption?.color
		|| getDefaultColorForOption(
			field.selectOptions,
			field.selectOptions.findIndex((a) => a.order == parseInt(activeOption))
		)
	) || '#aaa'
	return (
		<div className={style.container}>
			<div className={style.selector}>
				{field.selectOptions.map((selectOption, i) => (
					<div
						key={selectOption.order}
						className={style.option}
						style={{
							backgroundColor: activeOption && selectOption.order <= parseInt(activeOption)
								? selectOption.color || getDefaultColorForOption(field.selectOptions, i)
								: '#eee',
							pointerEvents: disabled ? 'none' : 'all',
						}}
						onMouseEnter={() => setHoverOption(selectOption.order.toString())}
						onMouseLeave={() => setHoverOption(null)}
						onClick={() => {
							dispatch(updateAnswers({ answers: [{ ...answer, content: selectOption.order.toString() }] }))
						}}
					/>
				))}
			</div>
			<div className={style.label} style={{color: activeColor }}>
				{activeSelectOption ? (activeSelectOption.label || activeSelectOption.order)
					: activeOption ? `No select option for value: "${activeOption}"`
					: disabled ? `Disabled`
					: field.placeholder || `No selection`
				}
			</div>
		</div>
	)
}


export default AnswerRating