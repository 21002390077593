import { ApolloClient, InMemoryCache } from "@apollo/client"
import { setContext } from "@apollo/client/link/context"
import { onError } from "@apollo/client/link/error";
import { getCookie } from 'utils/functions'
import { createUploadLink } from 'apollo-upload-client'
import { store as reduxStore } from './redux-store'
import { showNotification } from './user-interface'

const httpLink = createUploadLink({
	uri: '/graphql',
	credentials: 'same-origin'
})

const authLink = setContext((_, { headers }) => {
	return {
		headers: {
			...headers,
			'X-CSRFToken': getCookie('csrftoken')
		}
	}
})

const errorLink = onError(({ networkError }) => {
	reduxStore.dispatch(showNotification({
		'type': 'error',
		'content': `Oops, it appears something has gone wrong`
	}))
 })

// @ts-ignore I think different grqphql versions is causing this...
const newAuthLink = authLink.concat(httpLink)

export const apolloClient = new ApolloClient({
	cache: new InMemoryCache(),
	link: errorLink.concat(newAuthLink),
	defaultOptions: {
		watchQuery: {
			fetchPolicy: 'cache-and-network',
			errorPolicy: 'all',
		},
		query: {
			fetchPolicy: 'cache-first',
			errorPolicy: 'all',
			notifyOnNetworkStatusChange: true,
		},
		mutate: {
			errorPolicy: 'all',
		},
	},
})

