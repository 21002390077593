import React from 'react'
import { useAppSelector } from "utils/hooks"
import { SmallSpinner } from 'components/common/loading'
import style from './index.module.sass'
import { selectFormIsDirtyFromUserInteraction } from 'state/answer/selectors'

export const SavingIndicator = () => {
	const saving = useAppSelector(state => state.answers.saving)
	const dirty = useAppSelector(selectFormIsDirtyFromUserInteraction)
	const touched = useAppSelector(state => state.answers.touched)
	return (
		<div className={style.container}>
			{
				saving ? <><SmallSpinner/>&nbsp;&nbsp;Saving</> :
				dirty ? <>Modifed...</> :
				touched ? <><span className={style.iconTick}/>Saved</> :
				<>No changes</>
			}
		</div>
	)
	
}