import React, { useState } from 'react'
import { useQuery } from '@apollo/client'
import gql from 'graphql-tag'
import c from 'classnames'
import { RouteComponentProps, Link } from 'react-router-dom'
import addDays from 'date-fns/addDays'
import addMonths from 'date-fns/addMonths'
import differenceInWeeks from 'date-fns/differenceInWeeks'
import endOfWeek from 'date-fns/endOfWeek'
import endOfMonth from 'date-fns/endOfMonth'
import format from 'date-fns/format'
import isEqual from 'date-fns/isEqual'
import isSameMonth from 'date-fns/isSameMonth'
import startOfDay from 'date-fns/startOfDay'
import startOfMonth from 'date-fns/startOfMonth'
import startOfWeek from 'date-fns/startOfWeek'

import { analyticsLink } from 'fragments'
import { calendarUrl, CalendarUrlProps } from 'utils/url'
import { useGlobalContext } from 'components/context'
import { LoadingBar } from 'components/common/loading'
import { AnswerDates, AnswerDatesVariables } from './gql-types/AnswerDates'
import { AnswerDates_answerDate_List_objects as AnswerDate } from './gql-types/AnswerDates'
import style from './index.module.sass'
import Day from './day'
import Filter from './filter'
import { getDateFromProps, getPropsFromDate } from './utils'
import { SinglePanel } from 'components/common/panel'


const GET_DATES_QUERY = gql`
	query AnswerDates(
		$moduleId: ID!,
		$teamId: ID,
		$filterFields: [ID],
		$contentAfter: PharosDatetime,
		$contentBefore: PharosDatetime
	) {
		answerDate_List(
			module: $moduleId,
			team: $teamId,
			deactivated_Isnull: true,
			objectActive: true,
			filterFields: $filterFields,
			content_Gte: $contentAfter,
			content_Lte: $contentBefore,
			orderBy: [
				{field: content},
				{field: field_Title, modifiers: [CASE_INSENSITIVE]}
			]
		) {
			objects {
				id
				content
				contentObjectName
				field {
					id
					title
				}
				tableField {
					id
					title
				}
				link {
					...MetricFormLinkEssentials
				}
			}
		}
	}
	${analyticsLink}
`


type Props = RouteComponentProps<CalendarUrlProps>
const Calendar = ({ match }: Props) => {
	const globalContext = useGlobalContext()
	const selectedMonth = getDateFromProps(match.params)
	const [fields, setFields] = useState<{[k: string]: string | null}>({})

	const monthStart = startOfMonth(selectedMonth)
	const monthEnd = endOfMonth(selectedMonth)
	const firstWeekStart = startOfWeek(monthStart, {weekStartsOn: 1})
	const lastWeekStart = startOfWeek(monthEnd, {weekStartsOn: 1})

	const numWeeks = differenceInWeeks(lastWeekStart, firstWeekStart) + 1

	const { data, loading } = useQuery<AnswerDates, AnswerDatesVariables>(GET_DATES_QUERY, {
		variables: {
			moduleId: globalContext.currentModule.id,
			teamId: globalContext.currentTeam && globalContext.currentTeam.id,
			contentAfter: firstWeekStart.toISOString(),
			contentBefore: endOfWeek(monthEnd, {weekStartsOn: 1}).toISOString(),
			filterFields: Object.keys(fields).filter(id => fields[id])

		},
	})

	const dict: {[k: string]: AnswerDate[]} = {}
	if (data) {
		data.answerDate_List?.objects.forEach(a => {
			if(!a.content) {return}

			const date = a.content.slice(0,10)
			if (dict[date]) {
				dict[date] = [...dict[date], a]
			}
			else {
				dict[date] = [a]
			}
		})
	}

	return (
		<SinglePanel noPadding type="full-width" noBoxShadow>
			<div className={style.container}>
				<div className={style.titleContainer}>
					<Link
						to={calendarUrl({...globalContext, ...getPropsFromDate(addMonths(selectedMonth, -1))})}
						className={c(style.arrow, 'icon-arrow-left')}
					/>
					<div className={style.titleText}>
						{format(selectedMonth, "MMMM")} &nbsp;
						{selectedMonth.getFullYear()}
					</div>
					<Link
						to={calendarUrl({...globalContext, ...getPropsFromDate(addMonths(selectedMonth, +1))})}
						className={c(style.arrow, 'icon-arrow-right')}
					/>
				</div>

				<Filter filterFields={fields} setFields={setFields} date={selectedMonth}/>

				<div className={style.days}>
					{Array.from(Array(7).keys()).map(weekDay => (
						<div key={weekDay} className={style.day}>
							{format(addDays(firstWeekStart, weekDay), 'EEE')}
						</div>
					))}
				</div>
				<div className={style.loadingBarContainer}>
					{loading && <LoadingBar small />}
				</div>
				<div className={style.month}>
					{Array.from(Array(numWeeks * 7).keys()).map(dayNumber => {
						const date = addDays(firstWeekStart, dayNumber)
						const answers = dict[format(date, "yyyy-MM-dd")] || []
						return <Day
							key={dayNumber}
							dim={!isSameMonth(date, selectedMonth)}
							isToday={isEqual(startOfDay(new Date()), date)}
							date={date}
							answers={answers}
						/>
					})}
				</div>
			</div>
		</SinglePanel>
	)
}

export default Calendar