import React from 'react'
import gql from 'graphql-tag'
import { analyticsLink } from 'fragments'
import { useGlobalContext } from 'components/context'
import { useQuery } from '@apollo/client'
import PieMetric from '../../types/pie'
import { FieldGroupMetric, FieldGroupMetricVariables } from './gql-types/FieldGroupMetric'
import MetricPlaceholder from '../metric-placeholder'


const QUERY = gql`
	query FieldGroupMetric($id: ID!, $teamId: ID, $isSuiteAdminDashboard: Boolean) {
		fieldGroupMetric(id: $id) {
			id
			name
			values(teamId: $teamId, isSuiteAdminDashboard: $isSuiteAdminDashboard) {
				name
				color
				formLinks {
					...MetricFormLinkEssentials
				}
			}
		}
	}
	${analyticsLink}
`


type Props = {
	id: string
	isSuiteAdminDashboard?: boolean
}

const FieldGroupMetricWrapper = ({ id, isSuiteAdminDashboard }: Props) => {
	const globalContext = useGlobalContext()
	const { loading, data } = useQuery<FieldGroupMetric, FieldGroupMetricVariables>(
		QUERY,
		{
			variables: {
				id,
				teamId: globalContext.currentTeam?.id,
				isSuiteAdminDashboard,
			}
		}
	)
	if (loading) {
		return <MetricPlaceholder />
	}
	if (!data || !data.fieldGroupMetric) {
		return <div>error</div>
	}
	return (
		<PieMetric isSuiteAdminDashboard={isSuiteAdminDashboard} {...data?.fieldGroupMetric} />
	)

}

export default FieldGroupMetricWrapper