
import React from 'react'
import gql from 'graphql-tag'
import { useQuery } from '@apollo/client'

import { quickLinks, quickLinksVariables } from './gql-types/quickLinks'
import Placeholder from 'components/common/placeholder'
import QuickLink from './quick-link'


type Props = {
	formId: string
}

const GET_QUICK_LINKS = gql`
	query quickLinks($formId: ID!) {
		quickLink_List(
			form: $formId
			limit: 20
			offset: 0
		) {
			objects {
				id
				title
				moduleNotActivated
				module { 
					id
					name
					recordWord
				}
				linkToForm {
					id
					title
				}
				records {
					id
					name
					team {
						id
						name
						module {
							id
						}
					}
				}
			}
			pageInfo {
				hasNextPage
				total
			}
		}
	}
`

const QuickLinks = (props: Props) => {
	const { data, loading } = useQuery<quickLinks, quickLinksVariables>(GET_QUICK_LINKS, {
		variables: { formId: props.formId, }
	})

	const quickLinks = data?.quickLink_List

	return (
		<>
			{quickLinks &&
				<>
					{quickLinks?.objects.map(q => <QuickLink key={q.id} {...q} />)}
				</>
			}
			{loading && !quickLinks &&
				<Placeholder style={{height: 100}}/>
			}
			{!quickLinks && !loading &&
				<div className="error"> Quick link error</div>
			}
		</>
	)
}

export default QuickLinks