import { FieldTypeEnum, TableFieldEnumType, TableFieldType } from 'state/main-form/types'
import { GenericAnswer } from 'state/answer/types'
import { RootState } from 'state/redux-store'
import { AnswerContentTypeEnum } from 'types/gql-types/global'
import { isAnswerValid } from './utils'


export const selectObjectIdMap = ((state: RootState) => {
	const data = state.mainForm.data
	if (data === null) {
		alert('Error getting current objects')
		throw Error('Error getting current objects')
	}
	const obj: { [K in AnswerContentTypeEnum]: string } = {
		[AnswerContentTypeEnum.organisation]: data.organisation.id,
		[AnswerContentTypeEnum.team]: data.record.team.id,
		[AnswerContentTypeEnum.record]: data.record.id,
	}
	return obj
})

export const selectAnswersForField = ((state: RootState, fieldId: string) => {
	return Object.values(state.answers.objects).filter(a => a.fieldId == fieldId)
})

export const selectAnswers = ((state: RootState, answerIds: string[]) => {
	return Object.values(state.answers.objects).filter(a => answerIds.includes(a.id))
})

export const selectAnswer = ((state: RootState, answerId: string) => {
	return Object.values(state.answers.objects).find(a => a.id == answerId)!
})

export const selectFormIsDirty = ((state: RootState) => {
	// Any new answers
	return (
		Object.keys(state.answers.status.dirtyQueued).length > 0
		|| Object.keys(state.answers.status.dirtyInFlight).length > 0
	)
})

export const selectFormIsDirtyFromUserInteraction = (
	(state: RootState) => state.answers.touched && selectFormIsDirty(state)
)

export const selectFieldForAnswer = (state:RootState, answer: GenericAnswer) => {
	const mainField = state.mainForm.data!.fields[answer.fieldId]
	if (mainField.type == FieldTypeEnum.TABLE) {
		const tableFields: TableFieldType[] =  mainField.tableFields
		return tableFields.find(tf => tf.id == answer.tableFieldId)!
	}
	return mainField
}

export const selectInvalidAnswers = (state: RootState) => {
	return Object.values(state.answers.objects).filter(
		a => (
			!a.deactivated
			&& !isAnswerValid(selectFieldForAnswer(state, a).type as TableFieldEnumType, a)
		)
	)
}


export const selectAnswerMissingForField = (state: RootState, fieldId: string) => {
	return !selectAnswersForField(state, fieldId).find(a => !!a.content && !a.deactivated)
}

export const selectMissingMandatoryFields = (state: RootState) => {
	return Object.values(state.mainForm.data!.fields).filter(
		field => (
			field.mandatory
			&& !field.hiddenFieldId
			&& selectAnswerMissingForField(state, field.id)
		)
	)
}

export enum AnswerUploadingState {
	NONE = "NONE",
	UPLOADING = "UPLOADING",
	WAITING = "WAITING"
 }

export const selectAnswerUploadingState = (state: RootState, answerId: string) => {
	const content = state.answers.objects[answerId]?.content
	const isFile = !!content?.size
	if (!isFile) {
		// There's a case for initial answers where the answer will be new + already
		// uploaded
		return AnswerUploadingState.NONE
	}
	if (state.answers.status.dirtyQueued[answerId]) {
		return AnswerUploadingState.WAITING
	} else if (state.answers.status.dirtyInFlight[answerId]) {
		return AnswerUploadingState.UPLOADING
	}
	return AnswerUploadingState.NONE
}

export const selectIsFromInitialAnswerId = (state: RootState, answerId: string) => {
	return state.answers.status.initialAnswerMap[answerId] || null
}