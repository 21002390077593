import React from 'react'
import { QueryActionRecordForms_record_List_objects } from '../../gql-types/QueryActionRecordForms'
import { QueryActionRecordForms_template_List_objects_formSet_forms } from '../../gql-types/QueryActionRecordForms'
import style from './index.module.sass'
import { Link } from 'react-router-dom'
import { formUrl } from 'utils/url'
import { useGlobalContext } from 'components/context'


type Props = {
	record: QueryActionRecordForms_record_List_objects
	form: QueryActionRecordForms_template_List_objects_formSet_forms
}
export const RecordTitle = ({record, form}: Props) => {
	const globalContext = useGlobalContext()
	return (
		<Link
			className={style.container}
			to={formUrl({...globalContext, formId: form.id, recordId: record.id})}
		>
			<div className={style.line}><b>{record.name}</b></div>
			<div className={style.line}>{form.title}</div>
		</Link>
	)
}