import { Dispatch } from 'redux'
import { TabularRecordMetric } from 'resources/resource'

export const getAggregatedTable = (id: number, params: {module: number, team: number}) => {
	return async  (dispatch: Dispatch) => {
		dispatch({
			type: "GET_TABULAR_EMPLOYEE_METRIC",
			id
		})
		const response = await TabularRecordMetric.get(id, params)
		const table = await response.json()
		dispatch({
			type: "GOT_TABULAR_EMPLOYEE_METRIC",
			table,
			id,
		})
	}
}

export const getAggregatedTablesList = (moduleId: number) => {
	return async  (dispatch: Dispatch) => {
		const response = await TabularRecordMetric.query({moduleId})
		const tables = await response.json()
		dispatch({
			type: "GOT_TABULAR_EMPLOYEE_METRICS",
			tables,
		})
	}
}