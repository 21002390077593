import { TEMP_ID_PREFIX } from "utils/constants"
import { AssigneeType } from "../select-assignee"
import { useReducer } from "react"
import gql from "graphql-tag"
import { GetEscalations, GetEscalationsVariables, GetEscalations_formActionEscalation_List_objects } from './gql-types/GetEscalations'
import { useQuery } from "@apollo/client"
import { MainFormStateDataType, useFormContext } from "state/main-form/reducer"
import { form_action_escalation_bulkBulkInput } from "types/gql-types/global"

export type Escalation = {
	id: string
	
	assignedUser: {id: string, firstName: string, lastName: string, email: string} | null
	assignedAnonEmail: string
	assignedAnonName: string
	assignedAnonEmailAnswer: {id: string, content: string | null, contactName: string | null} | null
	deleted: boolean
	deltaDays: number
}
export type EscalationWithType = Escalation & { assigneeType: AssigneeType }
export type State = {[id: string]: Escalation}

export type PartialEscalation = { [K in keyof Escalation]?: Escalation[K] }

export type CreateEscalation = {
	type: 'CREATE'
	obj: Escalation

}

type DeleteEscalation = {
	type: 'DELETE'
	id: string
}

type ReorderEscalations = {
	type: 'REORDER'
	objs: Escalation[]
}

export type Actions = DeleteEscalation | CreateEscalation | ReorderEscalations


export const emptyEscalation = () => {
	const a = {
		id: `${TEMP_ID_PREFIX}${Math.floor(Math.random() * 9999999)}`,
		assigneeType: 'user' as AssigneeType,
		assignedUser: null,
		assignedAnonEmail: '',
		assignedAnonName: '',
		assignedAnonEmailAnswer: null,
		deleted: false,
		deltaDays: 14,
	}
	return a
}


const reducer = (state: State, action: Actions) => {
	if (action.type === 'CREATE') {
		return {
			...state,
			[action.obj.id]: {
				...action.obj,
			},
		}
	}
	 else if (action.type == 'DELETE') {
		return {
			...state,
			[action.id]: {
				...state[action.id],
				deleted: true,
			},
		}
	}
	throw Error('Unknown action in form assignment modal');
}

// This is duplicated from form-actions/common, not sure why apollo complains...
export const FORM_ACTION_ESCALATION_FRAGMENT = gql`
	fragment FormActionEscalation on FormActionEscalationType {
		id
		assignedAnonEmail
		assignedAnonName
		assignedAnonEmailAnswer {
			id
			content
			contactName
		}
		assignedUser {
			id
			firstName
			lastName
			email
		}
		deleted
		deltaDays
	}
`


const GET_FORM_ACTIONS = gql`
	query GetEscalations($record: ID!, $form: ID!) {
		formActionEscalation_List(record: $record, form: $form) {
			objects {
				...FormActionEscalation
			}
		}
	}
	${FORM_ACTION_ESCALATION_FRAGMENT}
`

export const useEscalationFetch = () => {
	const formContext = useFormContext()
	const variables = { variables: { record: formContext.record.id, form: formContext.form.id }}
	const { data, loading, error } = useQuery<GetEscalations, GetEscalationsVariables>(GET_FORM_ACTIONS, variables)
	if (loading) {
		return {escalations: null, loading, error: error}
	}
	if (error || !data?.formActionEscalation_List) {
		return {escalations: null, loading: loading, error: error}
	}
	return {escalations: data.formActionEscalation_List.objects, loading: loading, error: error}
}

type Params = {
	initialEscalations: GetEscalations_formActionEscalation_List_objects[]

}

export const useEscalationState = ({initialEscalations}: Params) => {
	const deepCopy = JSON.parse(JSON.stringify(initialEscalations)) as Params['initialEscalations']

	const initialState = deepCopy.reduce((acc, e) => {
		acc[e.id] = e
		return acc
	}, {} as State)

	const [state, dispatch] = useReducer(reducer, initialState);
	return {state, dispatch} 
}

export const transformStateForSave = (formContext: MainFormStateDataType, state: State) => {
	const toSaveEscalations = Object.values(state).map(e => ({
		...(e.id.startsWith(TEMP_ID_PREFIX) ? {} : {id: e.id}),
		form: formContext.form.id,
		record: formContext.record.id,
		deleted: e.deleted,
		assignedUser: e.assignedUser?.id,
		assignedAnonEmail: e.assignedAnonEmail,
		assignedAnonName: e.assignedAnonName,
		assignedAnonEmailAnswer: e.assignedAnonEmailAnswer?.id || null,
		deltaDays: e.deltaDays,
	}) satisfies form_action_escalation_bulkBulkInput)
	return toSaveEscalations
}
