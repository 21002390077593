import gql from 'graphql-tag'
export * from './gql-types/PublicFormSubmit'

export const SUBMIT_PUBLIC_FORM = gql`
	mutation PublicFormSubmit($publicFormCode: String!, $submitterEmail: String) {
		publicForm_Submit(publicFormCode: $publicFormCode, submitterEmail: $submitterEmail) {
			success
		}
	}
`
