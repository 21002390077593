import React, { useRef } from 'react'
import { CSSTransition, TransitionGroup } from 'react-transition-group'
import { LoadingBar } from 'components/common/loading'
import Pdf from './pdf'
import { Pdf as PdfType } from './gql-types/Pdf'
import pdfStyle from './pdf.module.sass'
import style from './pdf-list.module.sass'
import { differenceInSeconds, parseISO } from 'date-fns'


type Props = {
	creating: boolean
	newPdfName: string
	pdfs: PdfType[]
}

export const PdfList = ({ creating, newPdfName, pdfs }: Props) => {
	const nodeRef = useRef(null);
	return (
		<TransitionGroup>
			{creating &&
				<CSSTransition
					nodeRef={nodeRef}
					classNames={"new-pdf-animation"}
					timeout={{ enter: 300, exit: 300 }}
					key="generating-pdf"
					enter={true}
					exit={false}
				>
					<div className={pdfStyle.pdf} ref={nodeRef}>
						<div className={pdfStyle.pdfTextContainer}>
							<div className="muted"><span className={`${pdfStyle.icon} icon-doc`}></span><b>{newPdfName}</b></div>
							<div className={style.loadingBarContainer} >
								<LoadingBar small />
							</div>
							<div className="muted">Generating</div>
						</div>
						<div className={style.dropdownPlaceholder} />
					</div>
				</CSSTransition>
			}

			{pdfs.map((pdf, i) =>
				<CSSTransition
					key={pdf.id}
					classNames={"new-pdf-animation"}
					timeout={{ enter: 300, exit: 300 }}
					// Only animate if an old pdf is filling the space after deletion
					enter={i == 0 && differenceInSeconds(new Date(), parseISO(pdf.created)) > 4}
					exit={true}
				>
					<Pdf pdf={pdf} />
				</CSSTransition>
			)}
		</TransitionGroup>

	)
}