import React from 'react'
import { useQuery } from '@apollo/client'
import gql from "graphql-tag"
import { FieldList, FieldListVariables } from './gql-types/FieldList'
import { MoveDateOnCopyInputInner, PassthroughProps } from './inner'

export const GET_FIELDS = gql`
	query FieldList($templateId: ID!) {
		field_List(
			limit: 100,
			offset: 0,
			moveDateOnCopy: true,
			inTemplate: $templateId,
			orderBy: [
				{field: title, modifiers: [CASE_INSENSITIVE]}
			],
		) {
			objects {
				id
				title
			}
			pageInfo {
				hasNextPage
				total
			}
		}
		tableField_List(
			limit: 100,
			offset: 0,
			moveDateOnCopy: true,
			inTemplate: $templateId,
			orderBy: [
				{field: title, modifiers: [CASE_INSENSITIVE]}
			],
		) {
			objects {
				id
				title
				field {
					id
					title
				}
			}
			pageInfo {
				hasNextPage
				total
			}
		}
	}
`

type Props = {
	templateId: string
} & PassthroughProps

export const MoveDateOnCopyInput = ({templateId, ...passthrough}: Props) => {
	const apolloProps = useQuery<FieldList, FieldListVariables>(
		GET_FIELDS, { variables: { templateId }}
	)

	if (apolloProps.loading) {
		// return <SmallSpinner />
		return null
	} else if (apolloProps.error) {
		return <div>Error fetching "move date on copy" fields</div>
	}
	
	let fields: {id: string, label: string}[] = []
	if (apolloProps.data?.field_List?.objects.length) {
		fields = apolloProps.data?.field_List?.objects.map(({id, title}) => ({id, label: title}))
	}
	if (apolloProps.data?.tableField_List?.objects.length) {
		fields = [
			...fields,
			...apolloProps.data?.tableField_List?.objects.map(
				({id, title, field: {title: parentTitle}}) => ({id, label: `${parentTitle} · ${title}`})
			)
		]
	}

	if (fields.length) {
		return <MoveDateOnCopyInputInner {...passthrough} fields={fields} />
	}
	
	return null
}