import React from 'react'
import gql from 'graphql-tag'
import c from 'classnames'
import { useMutation } from '@apollo/client'
import { CheckOutlined, MinusOutlined, MoreOutlined } from '@ant-design/icons'
import { Dropdown } from 'antd'
import { useDispatch } from 'react-redux'
import { format } from 'date-fns'

import { useGlobalContext } from 'components/context'
import { getFormAuth } from 'state/main-form/thunks'
import { useFormContext } from 'state/main-form/reducer'
import { FORM_ACTION_FRAGMENT, getUserDetails } from '../../common/utils'
import { GetFormActions } from '../../gql-types/GetFormActions'
import { CompleteFormActionButton } from '../../complete-button'
import { UndoFormAction, UndoFormActionVariables } from './gql-types/UndoFormAction'
import style from './index.module.sass'

export type FormAction = NonNullable<GetFormActions["formAction_List"]>["objects"][0]
export type FormActionSchedule = NonNullable<GetFormActions["formActionSchedule_List"]>["objects"][0]

const UNDO_FORM_ACTION = gql`
	mutation UndoFormAction($id: ID!) {
		formAction_Update(input: {id: $id, completedOn: null, signatureImage: null, signatureText: ""}) {
			object {
				...FormAction
			}
		}
	}
	${FORM_ACTION_FRAGMENT}
`

type Props = {
	formAction: FormAction
	formActionSchedule: FormActionSchedule | null
	current: FormAction | null
}

export const SummaryRow = ({ formAction, formActionSchedule, current }: Props) => {
	const globalContext = useGlobalContext()
	const formContext = useFormContext()
	const dispatch = useDispatch()

	const isCurrent = current?.id == formAction.id

	const [undoFormAction] = useMutation<UndoFormAction, UndoFormActionVariables>(
		UNDO_FORM_ACTION,
		{
			variables: {
				id: formAction.id,
			},
			onCompleted: () => {
				// Refetch form as it may be unlocked now
				dispatch(getFormAuth({ formId: formContext.form.id, recordId: formContext.record.id }))
			},
		}
	)

	const isAuthUser = formAction.assignedUser?.id == globalContext.user.id
	const details = getUserDetails(formAction)

	return (
		<div className={style.container}>
			<div className={c(style.iconContainer, { [style.completeIconContainer]: formAction.completedOn })}>
				{formAction.completedOn ? <CheckOutlined className={style.completeIcon} /> : <MinusOutlined className={style.incompleteIcon} />}
			</div>

			<div className={style.rightContent}>
				<div className={style.topRow}>
					{formAction.assignedUser?.firstName || formAction.assignedAnonName}
				</div>
				{formAction.completedOn ?
						<div className="muted">Completed on {format(new Date(formAction.completedOn), 'dd/MM/yyyy')}</div>
					: isAuthUser ?
						<div className={style.buttonContainer}>
							<CompleteFormActionButton formAction={formAction} />
						</div>
					: <div className="muted">Waiting for {details.name}</div>	
				}
			</div>

			{isAuthUser && formAction.completedOn && !formAction.isScheduled &&
				<Dropdown
					menu={{
						items: [{
							key: "1",
							label: (
								<div className="icon-reset" onClick={() => undoFormAction()}>Undo completion</div>
							)
						}]
					}}
					trigger={['click']}
				>
					<MoreOutlined style={{ fontSize: '1.5rem' }} />
				</Dropdown>
			}
		</div>
	)
}