import React from 'react'

import { SummaryRow, FormAction, FormActionSchedule } from './row'
import { format } from 'date-fns'
import { Pill } from 'components/common/pill'


type Props = {
	formActions: FormAction[]
	formActionSchedule: FormActionSchedule | null
}

export const FormActionSummary = ({formActions, formActionSchedule}: Props) => {
	let prevComplete = true
	let current: FormAction | null = formActions[0]

	return ( 
		<div>
			{formActionSchedule && (
				<div style={{marginBottom: 12}}>
					<Pill color={'blue'}>
						<>Due {format(new Date(formActionSchedule.dueAt), 'dd/MM/yyyy')}</>
					</Pill>
				</div>

				)}
			{formActions.map(a => {
				current = (prevComplete && !a.completedOn) ? a : current
				prevComplete = prevComplete && !!a.completedOn
				return <SummaryRow key={a.id} formAction={a} formActionSchedule={formActionSchedule} current={current} />
			})}
			{formActions.length === 0 && <div>No Actions</div>}
		</div>
	)
}