import React, {useContext} from 'react'
import { List } from 'antd'
import { useQuery } from '@apollo/client'
import gql from 'graphql-tag'

import style from './index.module.sass'
import { ModalProps, NotificationQueryContext, QueryType as NotificationQueryType } from '../../common'
import { notifiationsUserList as UserList } from './gql-types/notifiationsUserList'
import AddNotification from './add'
import { NotificationItem } from './item'

export const GET_USERS = gql`
	query notifiationsUserList($teamId: ID!) {
		user_List(
			limit: 200,
			offset: 0,
			hasAccessToTeam: $teamId,
			orderBy: [
				{field: firstName, modifiers: [CASE_INSENSITIVE]},
				{field: lastName, modifiers: [CASE_INSENSITIVE]}
			],
		) {
			objects {
				id
				firstName
				lastName
			}
			pageInfo {
				hasNextPage
				total
			}
		}
	}
`


type Props = ModalProps


const FieldNotificationList = (props: Props) => {
	const { data: notiData, loading: notiLoading, refetch, variables } = useContext(NotificationQueryContext) as NotificationQueryType
	const {data: userData, loading: userLoading} = useQuery<UserList>(
		GET_USERS,
		{variables: {teamId: props.teamId}}
	)

	if (!notiData?.fieldNotification_List) {
		if (notiLoading) {
			return null
		}
		return <>Notification error</>
	}
	if (!userData || !userData.user_List || !userData.user_List.objects) {
		if (userLoading) {
			return null
		}
		return <>User error</>
	}

	const refreshList = async () => await refetch(variables)

	const users = userData.user_List!.objects
	
	return (
		<div>
			<div className={style.listContainer}>
				<List
					size="small"
					bordered
					dataSource={notiData.fieldNotification_List.objects.filter(n => n.isActive)}
					locale={{emptyText: 'No notifications set'}}
					renderItem={n => (
						<List.Item>
							<NotificationItem {...props} notification={n} users={users} refetch={refreshList} />
						</List.Item>
					)}
				/>
			</div>
			<AddNotification {...props} refetch={refreshList} users={users} />
		</div>
	)
}

export default FieldNotificationList


