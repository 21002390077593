import gql from 'graphql-tag'
import { FormAction } from "../edit-modal/once-off/state"

type Params = null | Pick<FormAction, "assignedUser" | "assignedAnonEmailAnswer" | "assignedAnonEmail" | "assignedAnonName">

export const getUserDetails = (formAction: Params) => {

	return !formAction ? {
		type: null,
		name: "Not selected",
		email: null,
	} : formAction.assignedUser ? {
		type: "User",
		name: formAction.assignedUser.firstName + " " + formAction.assignedUser.lastName,
		email: formAction.assignedUser.email,
	} : formAction.assignedAnonEmail ? {
		type: "Email",
		name: formAction.assignedAnonName,
		email: formAction.assignedAnonEmail,
	} : formAction.assignedAnonEmailAnswer ? {
		type: "Contact",
		name: (formAction.assignedAnonEmailAnswer.contactName),
		email: formAction.assignedAnonEmailAnswer.content,
	} : {
		type: "Error",
		name: "Error",
		email: "Error",
	}
}


// This is duplicated from form-actions/common, not sure why apollo complains...
export const FORM_ACTION_FRAGMENT = gql`
	fragment FormAction on FormActionType {
		id
		assignedUser {
			id
			firstName
			lastName
			email
		}
		assignedAnonEmail
		assignedAnonName
		assignedAnonEmailAnswer {
			id
			content
			contactName
		}
		completedOn
		deleted
		form {
			pdfGenerators {
				id
				name
			}
		}
		isScheduled
		link
		priority
		reminderNext
		reminderLast
		requestSignature
		userSuppliedContext
	}
`

// This is duplicated from form-actions/common, not sure why apollo complains...
export const FORM_ACTION_SCHEDULE_FRAGMENT = gql`
	fragment FormActionSchedule on FormActionScheduleType {
		id
		assignedAnonEmail
		assignedAnonName
		assignedAnonEmailAnswer {
			id
			content
			contactName
		}
		assignedUser {
			id
			firstName
			lastName
			email
		}
		deleted
		dueAt
		requestSignature
		unitAmount
		unitName
		userSuppliedContext
	}
`

export const GET_FORM_ACTIONS_QUERY_NAME = 'GetFormActions'
