import React from 'react'
import c from 'classnames'
import style from './index.module.sass'
import { CloseOutlined } from '@ant-design/icons'

export type UserType = {
	id: string
	firstName: string
	lastName: string
	email: string
}

type Props = {
	onClick?: () => void
	onCancel?: () => void
	user: UserType
	prefix?: React.ReactNode
	suffix?: React.ReactNode
}
export const User = ({user, onClick, onCancel, prefix, suffix}: Props) => {
	return (
		<div className={style.container}>
			<div className={style.user} onClick={onClick}>{prefix} {user.firstName} {user.lastName} {suffix}</div>
			{onCancel && <CloseOutlined className={style.deleteButton} onClick={onCancel} />}
		</div>
	)
}