/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

//==============================================================
// START Enums and Input Objects
//==============================================================

/**
 * An enumeration.
 */
export enum ActivityEventTypeType {
  A = "A",
  D = "D",
  F = "F",
  FR = "FR",
  P = "P",
  R = "R",
  S = "S",
  U = "U",
}

/**
 * An enumeration.
 */
export enum AnalyticsUpcomingNotificationsMetricTypeChoices {
  PAST = "PAST",
  UPCOMING = "UPCOMING",
}

/**
 * An enumeration.
 */
export enum AnswerContentTypeEnum {
  organisation = "organisation",
  record = "record",
  team = "team",
}

/**
 * An enumeration.
 */
export enum FieldFieldTypeChoices {
  ADDRESS = "ADDRESS",
  BOOLEAN = "BOOLEAN",
  CURRENCY = "CURRENCY",
  DATE = "DATE",
  DROP_DOWN = "DROP_DOWN",
  DROP_DOWN_OTHER = "DROP_DOWN_OTHER",
  EMAIL = "EMAIL",
  FILE = "FILE",
  IMAGE = "IMAGE",
  INTEGER = "INTEGER",
  LINK = "LINK",
  RADIO = "RADIO",
  RATING = "RATING",
  TABLE = "TABLE",
  TEXT_LONG = "TEXT_LONG",
  TEXT_SHORT = "TEXT_SHORT",
  TEXT_TINY = "TEXT_TINY",
  TIME = "TIME",
}

/**
 * An enumeration.
 */
export enum FieldTableFieldTypeChoices {
  ADDRESS = "ADDRESS",
  BOOLEAN = "BOOLEAN",
  CURRENCY = "CURRENCY",
  DATE = "DATE",
  DROP_DOWN = "DROP_DOWN",
  DROP_DOWN_OTHER = "DROP_DOWN_OTHER",
  EMAIL = "EMAIL",
  FILE = "FILE",
  IMAGE = "IMAGE",
  INTEGER = "INTEGER",
  LINK = "LINK",
  RADIO = "RADIO",
  RATING = "RATING",
  TEXT_LONG = "TEXT_LONG",
  TEXT_SHORT = "TEXT_SHORT",
  TEXT_TINY = "TEXT_TINY",
  TIME = "TIME",
}

/**
 * An enumeration.
 */
export enum FormActionFormActionScheduleUnitNameChoices {
  DAYS = "DAYS",
  MONTHS = "MONTHS",
}

/**
 * An enumeration.
 */
export enum MoveDatesType {
  YEAR_DELTA = "YEAR_DELTA",
  YEAR_SPECIFIC = "YEAR_SPECIFIC",
}

/**
 * An enumeration.
 */
export enum PdfPdfOriginChoices {
  FORM_ACTION = "FORM_ACTION",
  PUBLIC_FORM = "PUBLIC_FORM",
  RESET = "RESET",
  USER = "USER",
}

export interface AnswerBooleanBulkObjectInput {
  id?: string | null;
  fieldId?: string | null;
  tableFieldId?: string | null;
  content: boolean;
  deactivated?: any | null;
  formSave?: string | null;
  contentType?: string | null;
  objectId?: string | null;
  rank?: number | null;
}

export interface AnswerBooleanPubBulkObjectInput {
  id?: string | null;
  fieldId?: string | null;
  tableFieldId?: string | null;
  content: boolean;
  deactivated?: any | null;
  formSave?: string | null;
  contentType?: string | null;
  objectId?: string | null;
  rank?: number | null;
}

export interface AnswerDateBulkObjectInput {
  id?: string | null;
  fieldId?: string | null;
  tableFieldId?: string | null;
  content?: any | null;
  deactivated?: any | null;
  formSave?: string | null;
  contentType?: string | null;
  objectId?: string | null;
  rank?: number | null;
}

export interface AnswerDatePubBulkObjectInput {
  id?: string | null;
  fieldId?: string | null;
  tableFieldId?: string | null;
  content?: any | null;
  deactivated?: any | null;
  formSave?: string | null;
  contentType?: string | null;
  objectId?: string | null;
  rank?: number | null;
}

export interface AnswerDecimalBulkObjectInput {
  id?: string | null;
  fieldId?: string | null;
  tableFieldId?: string | null;
  content?: any | null;
  deactivated?: any | null;
  formSave?: string | null;
  contentType?: string | null;
  objectId?: string | null;
  rank?: number | null;
}

export interface AnswerDecimalPubBulkObjectInput {
  id?: string | null;
  fieldId?: string | null;
  tableFieldId?: string | null;
  content?: any | null;
  deactivated?: any | null;
  formSave?: string | null;
  contentType?: string | null;
  objectId?: string | null;
  rank?: number | null;
}

export interface AnswerFileBulkObjectInput {
  id?: string | null;
  fieldId?: string | null;
  tableFieldId?: string | null;
  content?: any | null;
  deactivated?: any | null;
  formSave?: string | null;
  contentType?: string | null;
  objectId?: string | null;
  rank?: number | null;
  name?: string | null;
}

export interface AnswerFilePubBulkObjectInput {
  id?: string | null;
  fieldId?: string | null;
  tableFieldId?: string | null;
  content?: any | null;
  deactivated?: any | null;
  formSave?: string | null;
  contentType?: string | null;
  objectId?: string | null;
  rank?: number | null;
  name?: string | null;
}

export interface AnswerTextBulkObjectInput {
  id?: string | null;
  fieldId?: string | null;
  tableFieldId?: string | null;
  content?: string | null;
  deactivated?: any | null;
  formSave?: string | null;
  contentType?: string | null;
  objectId?: string | null;
  rank?: number | null;
  lng?: any | null;
  lat?: any | null;
}

export interface AnswerTextPubBulkObjectInput {
  id?: string | null;
  fieldId?: string | null;
  tableFieldId?: string | null;
  content?: string | null;
  deactivated?: any | null;
  formSave?: string | null;
  contentType?: string | null;
  objectId?: string | null;
  rank?: number | null;
  lng?: any | null;
  lat?: any | null;
}

export interface ConcatenatedTableOrdering {
  columnIndex?: number | null;
  direction?: string | null;
}

export interface form_action_bulkBulkInput {
  id?: string | null;
  record?: string | null;
  form?: string | null;
  priority?: number | null;
  deleted?: boolean | null;
  assignedUser?: string | null;
  assignedAnonEmail?: string | null;
  assignedAnonName?: string | null;
  assignedAnonEmailAnswer?: string | null;
  completedOn?: any | null;
  requestSignature?: boolean | null;
  reuseSignatureFormActionId?: string | null;
  signatureImage?: any | null;
  signatureText?: string | null;
  signatureNote?: string | null;
  userSuppliedContext?: string | null;
}

export interface form_action_escalation_bulkBulkInput {
  id?: string | null;
  record?: string | null;
  form?: string | null;
  deleted?: boolean | null;
  assignedUser?: string | null;
  assignedAnonEmail?: string | null;
  assignedAnonName?: string | null;
  assignedAnonEmailAnswer?: string | null;
  deltaDays?: number | null;
}

export interface form_action_scheduleInput {
  id?: string | null;
  record?: string | null;
  form?: string | null;
  deleted?: boolean | null;
  assignedUser?: string | null;
  assignedAnonEmail?: string | null;
  assignedAnonName?: string | null;
  assignedAnonEmailAnswer?: string | null;
  requestSignature?: boolean | null;
  userSuppliedContext?: string | null;
  dueAt?: any | null;
  unitName?: string | null;
  unitAmount?: number | null;
  clientMutationId?: string | null;
}

export interface form_saveInput {
  id?: string | null;
  form: string;
  record: string;
  publicForm?: string | null;
  clientMutationId?: string | null;
}

export interface form_save_pubInput {
  id?: string | null;
  form: string;
  record: string;
  publicForm?: string | null;
  clientMutationId?: string | null;
}

//==============================================================
// END Enums and Input Objects
//==============================================================
