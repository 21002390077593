import React from 'react'
import { Link } from 'react-router-dom'
import style from './icon.module.sass'
import c from "classnames"
import Dropdown, { Props as DropdownProps } from './dropdown'
import { useRouter } from 'components/context';

type Props = {
	icon: string
	iconLink: string
	activePath: string
	small?: boolean
	moveDropdown?: number
	primary?: boolean
} & DropdownProps

export default (props: Props) => {
	const router = useRouter()
	const active = router.location.pathname.startsWith(props.activePath || props.iconLink)
	
	return (
		<div className={style.container}>
			<div className={style.linkContainer} onClick={props.closeDropdown}>
				<Link
					className={c(
						style.link,
						{
							[style.linkPrimary]: props.primary,
							[style.linkActive]: active,
							[style.linkSmall]: props.small
						},
					)}
					to={props.iconLink}
				>
					<div className={c(style.icon, `icon-${props.icon}`, {[style.linkPrimaryIcon]: props.primary},)} />
				</Link>
			</div>
			<div
				className={c(style.teamDropdown, style.teamDropdownHover)}
				style={props.moveDropdown ? {marginTop: props.moveDropdown} : {}}
			>
				<Dropdown
					title={props.title}
					links={props.links}
					iconLink={props.iconLink}
					closeDropdown={props.closeDropdown}
				/>
			</div>
		</div>
	)
}

