import React from 'react'
import { FIELD_CONTENT_TYPE } from 'utils/constants'
import SimpleIconButton from 'components/common/simple-icon-button'
import { useAppSelector } from 'utils/hooks'
import HighlightCircle from './highlight-circle'
FieldNotification
import { useFormContext } from 'state/main-form/reducer'
import { HelpInfoButton } from '../help-info/button'
import FieldNotification from '../field-notification'
import { FieldTypeEnum } from 'state/main-form/types'
import { MandatoryAsterisk } from './mandatory-asterisk'
import style from './index.module.sass'
import { Tooltip } from 'antd'

type Props = {
	fieldId: string
	toggleFullScreenField: () => void
	highlight: boolean
}


export const Title = ({ fieldId, highlight, toggleFullScreenField }: Props) => {
	const {fields, publicForm, record, form} = useFormContext()
	const field = fields[fieldId]

	const fullScreenField = useAppSelector(state => state.userInterface.fullScreenField)
	return (
		<div>
			<div className={style.fieldTitleContainer}>
				{ highlight && 
					<div style={{marginLeft: -30, marginRight: 10}}>
						<HighlightCircle showFor={2000} size={20} />
					</div>
				}
				<div className={style.fieldTitle}>{field.title}</div>
				{ field.type==FieldTypeEnum.DATE && !publicForm && 
					<div>
						<FieldNotification
							recordId={record.id}
							teamId={record.team.id}
							formId={form.id}
							fieldType={FIELD_CONTENT_TYPE}
							fieldId={field.id}
						/>
					</div>
				}
				{ field.helpInfo && 
					<div style={{marginRight: -10}}>
						<HelpInfoButton
							title={field.title}
							htmlContent={field.helpInfo.htmlContent}
						/>
					</div>
				}
				{ field.mandatory &&
					<MandatoryAsterisk fieldId={field.id} />
				}
				{ field.type == FieldTypeEnum.TABLE &&
						<SimpleIconButton
							onClick={toggleFullScreenField}
							iconClass={fullScreenField ? "icon-shrink" : "icon-expand"}
						/>
				}
				{field.disabledForFormActions.length > 0 && 
					
						<Tooltip placement="top" title={
							<div>
								{publicForm?.code
									? <>Locked due to signatures</>
									: (
										<>
										Locked due to signatures:<br/>
											{field.disabledForFormActions.map(fa => (
												<div key={fa.id} style={{marginLeft: 10}}>
													{fa.record.name} · {fa.form.title}
												</div>
											))}
										</>
									) 
								}
							</div>
						}>
							<div className="icon-lock muted" style={{marginLeft: 10}}></div>
						</Tooltip>
				}
			</div>
			{field.subtitle && <div className={style.fieldSubtitle} dangerouslySetInnerHTML={{__html: field.subtitle}} />}
		</div>
	)

}
