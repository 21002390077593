import React from 'react'
import { Link } from 'react-router-dom'
import { Tooltip, TooltipProps } from 'antd'
import c from 'classnames'
import style from './index.module.sass'

interface IProps {
	iconClass?: string;
	small?: boolean;
	noHover?: boolean;
	to?: string;
	text?: string;
	onClick?: () => any;

	title?: string;
	description?: React.ReactNode;
	tooltipDirection?: TooltipProps['placement'];

}

export default (props: IProps) => {
	const button = (
		<div
			className={c(style.simpleIcon, 'button-no-outline', props.iconClass, {
				[style.smallIcon]: props.small,
				[style.noHover]: props.noHover,
			})}
			onClick={props.onClick}
		>
			{props.text}
		</div>
	)

	return ( 
		<div className={style.simpleIconButton}>
			<Tooltip placement={props.tooltipDirection || "bottom"} title={props.title} >
				{props.to 
					? <Link to={props.to}>
						{button}
					</Link>
					: button
				}
			</Tooltip>
		</div>
	)
}
