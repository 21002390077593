import React, { useCallback } from 'react'
import { useQuery } from '@apollo/client'
import gql from "graphql-tag"

import { eventList as QueryType } from './gql-types/eventList'
import { useGlobalContext } from 'components/context'
import { Event } from './event'
import style from './index.module.sass'
import Placeholder from 'components/common/placeholder'


export const GET_EVENTS = gql`
	query eventList($module: ID, $team: ID, $record: ID, $offset: Int, $limit: Int) {
		activityEvent_List(
			module: $module,
			team: $team,
			record: $record,
			limit: $limit,
			offset: $offset,
			orderBy: [{field: created, direction: DESC}],
		) {
			objects {
				id
				type
				record {
					id
					name
				}
				user {
					id
					firstName
				}
				form {
					id
					title
				}
				created
			}
			pageInfo {
				hasNextPage
				total
			}
		}
	}
`

type Props = {
	recordId?: null | string
}

const LOADING_PLACEHOLDER = [...Array(10).keys()].map((i) => (
	<Placeholder key={i} style={{width: 600, height: 40, margin: 10}} />
))

export const Events = ({recordId}: Props) => {
	const { currentModule, currentTeam } = useGlobalContext()
	const variables = {
		limit: 8,
		module: currentModule.id,
		team: currentTeam ? currentTeam.id : null,
		record: recordId,
	}
	const {data, loading, error, fetchMore} = useQuery<QueryType>(
		GET_EVENTS,
		{ variables }
	)

	const more = useCallback(() => {
		const list = data?.activityEvent_List
		if (!list) {
			return
		}
		const offset = list.objects.length
		fetchMore({
			variables: {
				...variables,
				offset,
				limit: 40 // load bigger pages after initial display
			},
			updateQuery: (prev, data) => {
				const { fetchMoreResult } = data
				if (!fetchMoreResult) return prev
				return {
					...prev,
					activityEvent_List: {
						__typename: 'ActivityEventTypeListBase',
						...prev.activityEvent_List,
						objects: [
							...prev!.activityEvent_List!.objects,
							...fetchMoreResult!.activityEvent_List!.objects,
						],
						pageInfo: fetchMoreResult!.activityEvent_List!.pageInfo
					}
				}
			}
		})
	}, [data])

	let inner = null

	if (error) {
		inner = <div className="muted">Error fetching events</div>
	} else if (data?.activityEvent_List?.objects.length === 0) {
		inner = <div className="muted">No recent events</div>
	} else if (!data?.activityEvent_List) {
		inner = null
	} else {
		inner = data.activityEvent_List.objects.map(event => (
			<Event key={event.id} event={event} />
		))
	}

	return (
		<div className={style.container}>
			<h5>Activity</h5>
			{inner}
			{loading && LOADING_PLACEHOLDER}
			<div className={style.loadMoreButtonContainer}>
				{data?.activityEvent_List?.pageInfo?.hasNextPage &&
					<button className={style.loadMoreButton} onClick={more}>
						Load More
					</button>
				}
			</div>
		</div>
	)
}