import React from 'react'
import { Modal } from 'antd'

import { SpinnerButton } from 'components/common/button';
import { Input } from 'components/common/inputs';
import { useEnableMfa } from './use-create-mfa';


type Props = {
	onComplete: () => void
}

export const CreateMfaButton = ({ onComplete }: Props) => {
	const { startMfaRegistration, completeMfaRegistration, cancelMfaRegistration, error, displayName, setDisplayName, loading, showDisplayNameModal } = useEnableMfa({ onComplete })
	return (
		<>
			<SpinnerButton
				className="button-primary-solid"
				loading={loading}
				onClick={startMfaRegistration}
			>
				Add MFA Device
			</SpinnerButton>

			<div className="error">{error}</div>

			<Modal
				title="Name your MFA device"
				open={showDisplayNameModal}
				maskClosable
				onCancel={cancelMfaRegistration}
				footer={null}
				width={600}
			>
				<p>
					Enter a name to identify the MFA device you just enrolled
				</p>

				<Input
					type="text"
					autoFocus
					value={displayName}
					onChange={e => setDisplayName(e.target.value)}
				/>

				<br />
				<br />
				<SpinnerButton
					loading={loading}
					disabled={displayName.replace(' ', '').length === 0}
					onClick={completeMfaRegistration}
				>
					Save
				</SpinnerButton>

			</Modal>
		</>

	)
}