import React from 'react'
import c from 'classnames'
import { Field, FieldArray, FieldProps, FormikState } from 'formik'

import { TextField } from 'components/common/formik'
import { FormValues } from './types'
import styles from './email-select.module.sass'
import EmailField from './email-field'


type Props = {
	formProps: FormikState<FormValues>
	toggleState: () => void
}

const EmailSelect: React.FC<Props> = ({toggleState, formProps: { values, errors, touched }}) => {
	const validEmails = (
		values.newCustomEmails.filter(e => e.length > 0).length +
		values.emailOptions.filter(a => a.selected).length
	)
	return (
		<div>
			<FieldArray
				name="emailOptions"
				render={arrayHelpers => (
					<React.Fragment>
						<div className={styles.emailList}>
							{values.emailOptions.map((v, index) => (
								<Field
									key={`${v.sourceType}-${v.objId}`}
									name={`emailOptions.${index}`}
									component={EmailField}
									aboutText1={`${v.name}`}
									aboutText2={`${v.detail}`}
									email={v.email}
								/>
							))}
						</div>
					</React.Fragment>
				)}
			/>
			
			<FieldArray
				name="newCustomEmails"
				render={
					arrayHelpers => (
						<div className={styles.customEmails}>
							{values.newCustomEmails.map((ce, index) => (
								<div key={index} className={styles.customEmailContainer}>
									<Field
										name={`newCustomEmails.${index}`}
										component={TextField}
										className={styles.customInput}
										placeholder="New email..."
									/>
									{touched.newCustomEmails
									&& errors.newCustomEmails
									&& errors.newCustomEmails[index] &&
										<div className="error">Invalid Email</div>
									}
								</div>

							))}

							<button
								className={c(styles.addAnotherEmailButton, "button-small", "icon-add")}
								onClick={() => arrayHelpers.push('')}
							>
								Custom email
							</button>
						</div>
					)
				}
			/>
			

			<div className={styles.buttonContainer}>
				<button
					className="button-primary-solid"
					onClick={toggleState}
					disabled={!!Object.keys(errors).length || !validEmails}
				>
					Next
				</button>
			</div>
		</div>
	)
}

export default EmailSelect
