import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import c from 'classnames'
import { record_record_template_formSet_forms as FormType } from '../gql-types/record'
import style from './form.module.sass'
import { formUrl } from 'utils/url'
import { useGlobalContext, useRouter } from 'components/context'

type Props = {
	record: {id: string}
	form: FormType
	disabled: boolean
}

export default function({ record, form, disabled }: Props) {
	const [showIconAnimation, setShowIconAnimation] = useState(false)

	const [origHasSaved] = useState(form.hasSaved)
	useEffect(() => {
		if(origHasSaved != form.hasSaved) {
			setShowIconAnimation(true)
			setTimeout(() => {
				setShowIconAnimation(false)
			}, 2000)
		}
	}, [form.hasSaved])

	const globalContext = useGlobalContext()
	const router = useRouter()

	const to = formUrl({recordId: record.id, formId: form.id, ...globalContext})
	
	const selected = c({
		[style.row]: true,
		[style.selected]: (
			router.location.pathname.startsWith(to)
		),
		[style.disabled]: disabled,
	})
	
	const icon = c({
		'icon-dot': form.hasSaved,
		[style.iconAnimation]: showIconAnimation,
	})

	return (
		<Link 
			to={formUrl({recordId: record.id, formId: form.id, ...globalContext})}
			onClick={e => disabled && e.preventDefault()}
		>
			<div className={selected}>
				<div className={style.icon}>
					<div className={icon}></div>
				</div>
				<div className={style.text}>
					{form.title}
				</div>
			</div>
		</Link>
	)
}