import { FieldFieldTypeChoices, FieldTableFieldTypeChoices } from "types/gql-types/global"
import { MainFormAuth } from "./get-main-form-auth"
import { MainFormPublic } from "./get-main-form-public"

type PublicForm = NonNullable<MainFormPublic["publicFormPub"]>

export const TableFieldTypeEnum = FieldTableFieldTypeChoices
export const FieldTypeEnum = FieldFieldTypeChoices


export type TableFieldEnumType = FieldTableFieldTypeChoices
export type FieldEnumType = FieldFieldTypeChoices | FieldTableFieldTypeChoices // This allows us to mix enum types


export type FieldType = 
	| NonNullable<MainFormAuth['form']>['fields'][0]
	| PublicForm['form']['fields'][0]

export type TableFieldType = 
	| NonNullable<MainFormAuth['form']>['fields'][0]['tableFields'][0]
	| PublicForm['form']['fields'][0]['tableFields'][0]

export type InitialAnswerType = 
	| NonNullable<MainFormPublic['initialAnswerPub_List']>['objects'][0]
	| NonNullable<MainFormAuth['initialAnswer_List']>['objects'][0]

export type RecordType =
	| NonNullable<MainFormAuth['record']>
	| PublicForm['record']

export type FormType =
	| NonNullable<MainFormAuth['form']>
	| PublicForm['form']

export type GenericFieldType = FieldType | TableFieldType

export const isTableField = (f: GenericFieldType): f is TableFieldType => {
	return f.__typename == 'TableFieldType' || f.__typename == 'TableFieldPubType'
}

export const isAuthResponse = (resp: PublicForm| MainFormAuth): resp is MainFormAuth => {
	return false
}