import { FormActionFormActionScheduleUnitNameChoices } from 'types/gql-types/global'

const CYCLE_MAP_PRE: { unitName: FormActionFormActionScheduleUnitNameChoices, unitAmount: number, label: string }[] = [
	{
		unitName: FormActionFormActionScheduleUnitNameChoices.DAYS,
		unitAmount: 7,
		label: 'Weekly',
	},
	{
		unitName: FormActionFormActionScheduleUnitNameChoices.MONTHS,
		unitAmount: 1,
		label: 'Monthly',
	},
	{
		unitName: FormActionFormActionScheduleUnitNameChoices.MONTHS,
		unitAmount: 3,
		label: 'Quarterly',
	},
	{
		unitName: FormActionFormActionScheduleUnitNameChoices.MONTHS,
		unitAmount: 6,
		label: '6 Monthly',
	},
	{
		unitName: FormActionFormActionScheduleUnitNameChoices.MONTHS,
		unitAmount: 12,
		label: 'Yearly',
	},
]

export const getStringFromCycle = (cycle: { unitName: FormActionFormActionScheduleUnitNameChoices, unitAmount: number }): string => {
	return `${cycle.unitName}|${cycle.unitAmount}`
}

export const CYCLE_MAP = CYCLE_MAP_PRE.reduce((map, currentItem) => {
	const key = getStringFromCycle(currentItem)
	map[key] = currentItem
	return map
}, {} as {[s: string]: typeof CYCLE_MAP_PRE[0]})
