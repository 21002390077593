import React, { Component } from 'react'
import { useMutation } from "@apollo/client"
import gql from "graphql-tag"

import { useRouter } from 'components/context'
import { Spinner } from 'components/common/loading'
import { userEssentials } from 'fragments'
import { apolloClient } from 'state/apollo-client'
import { teamUrl, teamMetricsDefaultUrl } from 'utils/url'
import { swapOrg as SwapOrg, swapOrgVariables as SwapOrgVariables } from './gql-types/swapOrg'


const SWAP_ORG = gql`
	mutation swapOrg($orgId: ID!, $teamId: ID) {
		user_SwapOrgAdmin(organisation: $orgId, team: $teamId) {
			user {
				...UserEssentials
			}
		}
	}
	${userEssentials}
`

type Props = {
	orgId: string
	teamId?: string
}

export const Switch = ({orgId, teamId}: Props) => {
	const router = useRouter()
	
	const onCompleted = (data: SwapOrg) => {
		const organisation = data?.user_SwapOrgAdmin?.user?.organisation;
		if (!organisation) {
			alert('Error swapping orgs')
			return
		}
		apolloClient.resetStore()
		router.history.push(teamMetricsDefaultUrl({organisation}))
	}

	const [swapOrg, {loading, error}] = useMutation<SwapOrg, SwapOrgVariables>(SWAP_ORG, {
		variables: teamId ? {orgId, teamId}: {orgId},
		onCompleted,
	})

	if (loading) {
		return <Spinner />
	}
	return (
		<button onClick={() => swapOrg()} className="button-small">
			Switch
		</button>
	)
}