import { TEMP_ID_PREFIX } from "utils/constants"

export type FormAction = {
	id: string
	assignedUser: {id: string, firstName: string, lastName: string, email: string} | null
	assignedAnonEmail: string
	assignedAnonName: string
	assignedAnonEmailAnswer: {id: string, content: string | null, contactName: string | null} | null
	completedOn: string | null
	deleted: boolean
	isScheduled: boolean
	link: string | null
	priority: number
	requestSignature: boolean
	reminderLast: Date | null
	reminderNext: Date | null
	userSuppliedContext: string

}
export type State = FormAction[]

export type CreateFormAction = {
	type: 'CREATE'
	obj: {
		assignedUser: FormAction["assignedUser"]
		assignedAnonEmail: string
		assignedAnonName: string
		assignedAnonEmailAnswer: {id: string, content: string | null, contactName: string | null} | null
		userSuppliedContext: string
		requestSignature: boolean
	}
}

type DeleteFormAction = {
	type: 'DELETE'
	id: string
}

type ReorderFormActions = {
	type: 'REORDER'
	objs: FormAction[]
}

export type Actions = DeleteFormAction | CreateFormAction | ReorderFormActions


export const reducer = (state: State, action: Actions) => {
	if (action.type === 'CREATE') {
		let maxPriority = 0
		state.forEach(a => {
			if (a.priority> maxPriority) {
				maxPriority = a.priority
			}
		})

		const a: State = [
			...state,
			{
				...action.obj,
				id: `${TEMP_ID_PREFIX}${Math.floor(Math.random() * 9999999)}`,
				priority: maxPriority + 1,
				completedOn: null,
				deleted: false,
				isScheduled: false,
				link: null,
				reminderLast: null,
				reminderNext: null,
			},
		]
		return a
	}
	 else if (action.type == 'DELETE') {
		let priority = 1
		return state.map((a, i) => {
			const next =  {...a, deleted: a.deleted || (a.id == action.id), priority}
			if (!next.deleted) {
				priority += 1
			}
			return next
	 	})
	} else if (action.type == 'REORDER') {
		const next = state.map(a => ({...a}))
		next.forEach(a1 => {
			const priority = action.objs.findIndex(a2 => a1.id === a2.id) + 1
			a1.priority = priority
		})
		next.sort((a,b) => a.priority-b.priority)
		return next
	}
	throw Error('Unknown action in form assignment modal');
}