import _omit from 'lodash/omit'
import { createSlice, isAnyOf, PayloadAction } from '@reduxjs/toolkit'
import { FieldType } from 'state/main-form/types'
import { MainFormStateDataType } from 'state/main-form/reducer'
import { GenericAnswer } from 'state/answer/types'
import { AnswerContentTypeEnum } from 'types/gql-types/global'
import { createNewAnswerRow, createNewAnswer } from './utils'
import { answersSubmit, getAnswersAuth, getAnswersPublic, resetAnswers, saveAnswers, updateAnswers } from './thunks'


export type AnswerState = {
	saving: boolean
	loading: boolean
	queuedSave: boolean
	touched: boolean
	submitting: boolean
	queuedSubmit: {
		submitterEmail: string
	} | null
	objects: {[id: string]: GenericAnswer}
	status: {
		dirtyQueued: {[id: string]: string}
		dirtyInFlight: {[id: string]: string}
		initialAnswerMap: {[id: string]: string}
	}
}


export const answerSlice = createSlice({
	name: 'answers',
	initialState: {
		saving: false,
		loading: false,
		queuedSave: false,
		touched: false,
		submitting: false,
		queuedSubmit: null,
		objects: {},
		status: {
			saved: {},
			dirtyQueued: {},
			dirtyInFlight: {},
			initialAnswerMap: {},
		},
	} as AnswerState,
	reducers: {
		gotAnswers: (state, action: PayloadAction<GenericAnswer[]>) => {
			action.payload.forEach(answer => {
				state.objects[answer.id] = answer
			})
			return state
		},
		setQueuedSave: (state, action: PayloadAction<boolean>) => {
			state.queuedSave = action.payload
			return state
		},
		setQueuedSubmit: (state, action: PayloadAction<AnswerState['queuedSubmit']>) => {
			state.queuedSubmit = action.payload
			return state
		},
		addMissingAnswers: (state, action: PayloadAction<{
			answers: GenericAnswer[],
		}>) => {
			for (const answer of action.payload.answers) {
				state.objects[answer.id] = answer
				state.status.dirtyQueued[answer.id] = answer.id
			}
			return state
		},
		addAnswer: (state, action: PayloadAction<Parameters<typeof createNewAnswer>[0]>) => {
			const {payload: createParams} = action
			const answer = createNewAnswer(createParams)
			state.objects[answer.id] = answer
			state.status.dirtyQueued[answer.id] = answer.id
			return state
		},
		addAnswerRow: (state, action: PayloadAction<{
			objectIdMap: {[k in AnswerContentTypeEnum]: string},
			mainField: FieldType,
		}>) => {
			const {payload: {mainField, objectIdMap}} = action
			const rank = 1 + Math.max(
				0,
				...(
					Object.values(state.objects)
					.filter(a => a.fieldId == action.payload.mainField.id)
					.map(a => a.rank)
				)
			)

			if(!state.touched) {
				state.touched = true
			}

			const answers = createNewAnswerRow({mainField, rank, objectIdMap})
			for (const answer of answers) {
				state.objects[answer.id] = answer
				state.status.dirtyQueued[answer.id] = answer.id
			}
			return state
		},
		addInitialAnswers: (state, action: PayloadAction<{
			objectIdMap: {[k in AnswerContentTypeEnum]: string},
			fields: {[fieldId: string]: FieldType},
			initialAnswers: MainFormStateDataType["initialAnswers"]
		}>) => {
			const {payload: {initialAnswers, fields, objectIdMap}} = action

			for (const field of Object.values(fields)) {
				const hasAnswer = !!Object.values(state.objects).find(a => a.fieldId == field.id)
				if (hasAnswer) {
					continue
				}

				const ranks = Array.from(new Set(Object.keys(initialAnswers[field.id] || {}).map(Number)))
				if (ranks.length === 0) {
					ranks.push(1)
				}

				for (const rank of ranks) {
					const answers = createNewAnswerRow({
						mainField: field,
						rank,
						objectIdMap,
						initialAnswers: initialAnswers[field.id],
					})
					for (const answer of answers) {
						state.objects[answer.id] = answer
						state.status.dirtyQueued[answer.id] = answer.id

						const initialAnswerForField = (initialAnswers[answer.fieldId] || {})[answer.rank] || []
						const initialAnswer = initialAnswerForField.find(i => i.tableFieldId === answer.tableFieldId)
						if (initialAnswer) {
							state.status.initialAnswerMap[answer.id] = initialAnswer.id
						}
					}
				}
			}
			return state
		}
	},
	
	extraReducers: (builder) => {
		builder
		.addCase(updateAnswers.pending, (state, action) => {
			action.meta.arg.answers.forEach(answer => {
				state.status.dirtyQueued[answer.id] = answer.id
				state.objects[answer.id] = {
					...state.objects[answer.id],
					..._omit(answer, "__typename"),
				}
			})
			state.touched = true
			return state
		})
		.addCase(saveAnswers.pending, (state) => {
			if (!state.status.dirtyQueued) {
				console.error("Save called with empty dirtyQueued")
			}
			state.saving = true
			state.queuedSave = false
			state.status.dirtyInFlight = {
				...state.status.dirtyInFlight,
				...state.status.dirtyQueued
			}
			state.status.dirtyQueued = {}
			return state
		})
		.addCase(saveAnswers.fulfilled, (state, action) => {
			state.saving = !!state.queuedSave
			state.status.dirtyInFlight = {}
			for (const answer of action.payload) {
				state.objects[answer.id] == answer
			}
			return state
		})
		.addCase(saveAnswers.rejected, (state, action) => {
			state.saving = false
			state.status.dirtyQueued = {
				...state.status.dirtyInFlight,
				...state.status.dirtyQueued,
			}
			return state
		})
		.addCase(answersSubmit.pending, (state, action) => {
			state.submitting = true
			return state
		})
		.addMatcher(
			isAnyOf(answersSubmit.fulfilled, answersSubmit.rejected),
			(state) => {
				state.submitting = false
				state.queuedSubmit = null
				return state
			}
		)
		.addMatcher(
			isAnyOf(getAnswersPublic.pending, getAnswersAuth.pending, resetAnswers.pending),
			(state) => {
				state = {...answerSlice.getInitialState()}
				state.loading = true
				return state
			},
		)
		.addMatcher(
			isAnyOf(getAnswersPublic.fulfilled, getAnswersAuth.fulfilled), (state, action) => {
			state.loading = false
			state.touched = false
			state.objects = {...answerSlice.getInitialState()}.objects
			action.payload.answers.forEach(a => {
				state.objects[a.id] = a
			})
			return state
		})
	},
})


export default answerSlice.reducer