import React, {useState} from 'react'
import gql from 'graphql-tag'
import { useQuery } from '@apollo/client'
import { Modal, Button } from 'antd'

import { Spinner } from 'components/common/loading'
import { Fields as FieldsQueryType, FieldsVariables } from './gql-types/Fields'
import style from './index.module.sass'
import { useGlobalContext } from 'components/context'
import Field from './field'

type Props = {
	date: Date,
	filterFields: {[id: string]: string | null}
	setFields: (fields: {[id: string]: string | null}) => void
}

const GET_FILTERS = gql`
	query Fields($moduleId: ID!) {
		field_List(hasDate: true, inModule: $moduleId) {
			objects {
				id
				title
				calendarNames(moduleId: $moduleId)
			}
			
		}
	}
`

const Day = ({date, filterFields, setFields}: Props ) => {
	const globalContext = useGlobalContext()
	const [show, setShow] = useState(false)
	const { data, loading } = useQuery<FieldsQueryType, FieldsVariables>(GET_FILTERS, {
		variables: {moduleId: globalContext.currentModule.id,},
	})

	const activeFieldNames = Object.keys(filterFields).filter(id => !!filterFields[id]).map(id => filterFields[id])

	return (
		<>
			<div className={style.calendarContainer} onClick={() => setShow(!show)}>
				{activeFieldNames.length > 0
					? (
						<div className={style.activeFilters}>
							Filters: &nbsp;
							{activeFieldNames.join(', ')}
						</div>
					)
					: <button className={"icon-filter button-grey"}>Filter dates</button>
				}
			</div>

			<Modal title="Filter dates" open={show} width={650} footer={null} onCancel={() => setShow(false)}>
				{ loading && <Spinner />}
				{ data && data.field_List?.objects.map(f => (
					<Field key={f.id} field={f} filterFields={filterFields} setFields={setFields} />
				))}
				<br />
				<div className={style.buttonRow}>
					<Button onClick={() => {
						setFields({})
						setShow(false)
					}}>
						Show all
					</Button>

					<Button type="primary" onClick={() => setShow(false)}>
						Apply
					</Button>
				</div>
			</Modal>
		</>
	)
}

export default Day