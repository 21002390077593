import React from 'react'
import { Dropdown } from 'antd'
import { MoreOutlined } from '@ant-design/icons'

import { Row } from '../row'
import { Actions, FormAction } from '../state'
import { format } from 'date-fns'
import { useAppDispatch } from 'utils/hooks'
import { showNotification } from 'state/user-interface'
import { AppDispatch } from 'state/redux-store'
import { TEMP_ID_PREFIX } from 'utils/constants'
import { getUserDetails } from '../../../common/utils'

const fmtReminder = (reminder: Date | null) => {
	if (!reminder) {
		return null
	}

	return format(new Date(reminder), 'h:mmbbb M/d/yy')
}

const copyLink = (link: string | null, reduxDispatch: AppDispatch) => {
	if (link) {
		navigator.clipboard.writeText(link)
		reduxDispatch(showNotification({content: `Copied to clipboard`}))
	} else {
		reduxDispatch(showNotification({content: `Copied to clipboard`, type: 'error'}))
	}
}



type Props = {
	formAction: FormAction
	isCurrent: boolean
	dispatch: React.Dispatch<Actions>
	hasReordered: boolean
}

export const FormActionRow = ({ formAction, isCurrent, dispatch, hasReordered }: Props) => {

	const reduxDispatch = useAppDispatch()
	const reminder = (
		formAction.completedOn ? <>Complete</>
			: formAction.id.startsWith(TEMP_ID_PREFIX) ? null
			: isCurrent && hasReordered ? <div className="muted">Save to see reminders</div>
			: isCurrent ? <>
				<div>Last - {fmtReminder(formAction.reminderLast)}</div>
				<div>
					{
						formAction.reminderNext
							? <>Next - {fmtReminder(formAction.reminderNext)}</>
							: <>Reminders stopped</>
					}
				</div>
			</>
			: <div className="muted">Waiting</div>
	)

	const details = getUserDetails(formAction)

	const props = {
		reminder,
		priority: <>{formAction.priority}</>,
		requestSignature: formAction.requestSignature ? 'Yes' : 'No',
		name: `${details.name} - ${details.email}`,
		type: details.type,
		action: (
			<Dropdown
				menu={{
					items: [
						{
							key: 'delete',
							label: (
								<div className="icon-bin"
									onClick={() => dispatch({ type: 'DELETE', id: formAction.id })}>
									Delete
								</div>
							),
						},
						...(
							!formAction.completedOn && !formAction.assignedUser
							? [{
								key: 'link',
								disabled: !formAction.link,
								label: (
									<div
										className="icon-link"
										onClick={() => formAction.link ? copyLink(formAction.link, reduxDispatch) : null}
									>
										{formAction.link ? 'Copy link' : 'Link available after saving'}
									</div>
								),
							},]
							: []
						),
					]
				}}
				trigger={['click']}
			>
				<MoreOutlined style={{ transform: 'scale(1.4)' }} />
			</Dropdown>
		),
	}

	return <Row {...props} />
}