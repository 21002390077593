import React, { Component } from 'react'

import Placeholder from 'components/common/placeholder'
import { SinglePanel } from 'components/common/panel'
import dashboardStyle from './index.module.sass'
import navbarStyles from './navbar/index.module.sass'
import leftMenuStyles from './pages/module/left-side-panel/index.module.sass'


export const DashboardLoading = () => (
	<div className={dashboardStyle.dashboard}>
		<div className={navbarStyles.navBarContainer}>
			<div className={navbarStyles.navBarGroup}>
				<Placeholder style={{margin: "10px 0px 40px", height: 50, width: "80%"}}/>
				<Placeholder style={{margin: "10px 0px 40px", height: 50, width: "80%"}}/>
				<Placeholder style={{margin: "10px 0px 40px", height: 50, width: "80%"}}/>
			</div>
			<div className={navbarStyles.navBarGroup}>
				<Placeholder style={{margin: "40px 0px 10px", height: 50, width: "80%"}}/>
				<Placeholder style={{margin: "40px 0px 10px", height: 50, width: "80%"}}/>
				<Placeholder style={{margin: "40px 0px 10px", height: 50, width: "80%"}}/>
				<Placeholder style={{margin: "40px 0px 10px", height: 50, width: "80%"}}/>
			</div>
		</div>
		<div className={leftMenuStyles.leftMenuContainer}>
			<div className={leftMenuStyles.leftMenuDouble}>
				<div className={leftMenuStyles.leftMenuViewContainer}>
					<div className={leftMenuStyles.leftMenuView}>
						<Placeholder style={{height: 50, marginTop: 0}}/>
						<Placeholder style={{height: 50}}/>
						<Placeholder style={{height: 50}}/>
						<Placeholder style={{height: 300}}/>
					</div>
				</div>
			</div>
		</div>
		<div className={dashboardStyle.dashboardPage}>
			<SinglePanel>
				<Placeholder />
				<Placeholder style={{height: 300}}/>
				<Placeholder style={{height: 300}}/>
			</SinglePanel>
		</div>
	</div>
)