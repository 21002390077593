import React from 'react'
import style from './preview.module.sass'


type Props = {url?: string, child?: React.ReactNode}

export const SignaturePreview = ({ url, child }: Props) => (
	<div className={style.container}>
		<b>Preview</b>
		<div className={style.signatureImageContainer}>
			<div
				className={style.signatureImage}
				style={{
					backgroundImage: `url(${url})`,
				}}
			>
				{child}
			</div>
		</div>
	</div>
)

