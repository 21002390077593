import React, { Component } from 'react'
import { connect } from 'react-redux'
import classNames from 'classnames'
import { SinglePanel } from 'components/common/panel'
import { getAvailableData } from 'state/general/actions'
import { getCsrfToken } from 'resources/resource'
import { wrapWithContext } from 'components/context'
import { Input } from 'components/common/inputs'

export const getCsvDataQueryString = (context) => {
	const teamString = context.currentTeam && `&team_id=${context.currentTeam.id}`
	return `?module_id=${context.currentModule.id}${teamString ? teamString : ''}`
}

class ExportToCsv extends Component {
	state = {
		fields: [],
		positions: [],
		selectedFields: [],
		selectedPositions: [],
		downloadAvailable: false,
		positionSearch: '',
		fieldSearch: '',
	}

	getQueryString = () => {
		const teamString = this.props.currentTeam && `&team_id=${this.props.currentTeam.id}`
		return `?module_id=${this.props.currentModule.id}${teamString ? teamString : ''}`
	}

	componentWillMount() {
		this.props.getAvailableData(getCsvDataQueryString(this.props))
	}

	componentWillReceiveProps(nextProps) {
		this.setState({
			fields: [...nextProps.fields.map(f => ({...f}))],
			positions: [...nextProps.positions.map(f => ({...f}))],
		})  
	}

	selectField(index) {
		var fields = this.getFields()
		fields[index].selected = !fields[index].selected

		const selectedFields = fields.filter(f => f.selected).map(f => f.id)

		this.setState({
			...this.state,
			selectedFields,
			downloadAvailable: selectedFields.length > 0 && this.state.selectedPositions.length > 0,
		})
	}

	selectPosition(index) {
		var positions = this.getPositions()
		positions[index].selected = !positions[index].selected

		const selectedPositions = positions.filter(p => p.selected).map(p => p.position)

		this.setState({
			...this.state,
			positions,
			selectedPositions,
			downloadAvailable: this.state.selectedFields.length > 0 && selectedPositions.length > 0,
		})
	}

	selectAllPositions() {
		this.state.positions.map((p, i) => {
			this.selectPosition(i)
		})
	}

	selectAllFields() {
		this.state.fields.map((p, i) => {
			this.selectField(i)
		})
	}

	getJson() {
		return JSON.stringify({
			fields: this.state.selectedFields,
			positions: this.state.selectedPositions,
		})
	}

	getPositions() {
		if(this.state.positionSearch) {
			return this.state.positions.filter(p => p.position.toLowerCase().includes(this.state.positionSearch))
		}
		return this.state.positions
	}

	getFields() {
		if(this.state.fieldSearch) {
			return this.state.fields.filter(f => f.title.toLowerCase().includes(this.state.fieldSearch))
		}
		return this.state.fields
	}


	render() {
		const downloadButtonClasses = classNames()
		const positions = this.getPositions()
		const fields = this.getFields()
		return (
			<SinglePanel>
				<div className="csv-export">
					<h2 className="heading">
						CSV Export
					</h2>
					<h4 className="heading">
						&nbsp;{this.props.currentModule.name}: 
						<span className="muted">&nbsp;{this.props.currentTeam ? this.props.currentTeam.name : 'All'}</span>
					</h4>

					<form className="download-container" method="post" action={`/api/v1/csv_export${getCsvDataQueryString(this.props)}`}>
						<input type="hidden" name="json" value={this.getJson()} />
						<input type="hidden" name="csrfmiddlewaretoken" value={getCsrfToken()} />
						<button
							type="submit"
							className="icon-download button-primary-solid"
							disabled={!this.state.downloadAvailable}>Download
						</button>
					</form>

					<div className="csv-available-data">
						<div className="data-column">
							<div className="data-row" onClick={this.selectAllPositions.bind(this)}>
								<h5 className="heading">{this.props.currentModule.positionWord}s</h5>
							</div>
							<div className="search-row">
								<div className="icon-search"/>
								<Input
									value={this.state.positionSearch}
									onChange={(e) => this.setState({...this.state, positionSearch: e.target.value.toLowerCase()})}
									placeholder="Search..."
								/>
							</div>

							{ positions.map((position, i) => {
								const classes = classNames('data-row', {'selected': position.selected})
								return (
									<div key={position.position} className={classes} onClick={() => this.selectPosition(i)}>
										<div className="label">{position.position}</div>
									</div>
								)
							})}
							{ positions.length == 0 && 
								<p className="muted">There are no positions to show</p>
							}
						</div>

						<div className="data-column">
							<div className="data-row" onClick={this.selectAllFields.bind(this)}>
								<h5 className="heading">Fields</h5>
							</div>
							<div className="search-row">
								<div className="icon-search"/>
								<Input
									value={this.state.fieldSearch}
									onChange={(e) => this.setState({...this.state, fieldSearch: e.target.value.toLowerCase()})}
									placeholder="Search..."
								/>
							</div>
							{ fields.map((field, i) => {
								const classes = classNames('data-row', {'selected': field.selected})
								return (
									<div key={field.id} className={classes} onClick={() => this.selectField(i)}>
										<div className="label">{field.title}</div>
									</div>
								)
							})}
							{ fields.length == 0 && 
								<p className="muted">There are no fields to show</p>
							}
						</div>
					</div>
				</div>
			</SinglePanel>
		)
	}
}

const mapStateToProps = (state, ownProps) => {
	return {
		fields: state.general.availableCsvData.fields,
		positions: state.general.availableCsvData.positions,
	}
}

const mapDispatchToProps = (dispatch) => {
	return {
		getAvailableData: (queryString) => dispatch(getAvailableData(queryString))
	}
}

export default wrapWithContext(connect(
	mapStateToProps, 
	mapDispatchToProps
)(ExportToCsv))