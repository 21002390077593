import React, { useState } from 'react'
import { Checkbox } from 'antd'

import { Fields_field_List_objects as FieldType } from '../gql-types/Fields'
import style from './index.module.sass'

type Props = {
	field: FieldType
	filterFields: {[id: string]: string | null}
	setFields: (fields: {[id: string]: string | null}) => void
}

const Field = ({field, filterFields, setFields}: Props) => {
	const [show, setShow] = useState(false)

	return (
		<div className={style.container}>
			<div className={style.fieldTitleRow}>
				<Checkbox
					checked={!!filterFields[field.id]}
					onChange={(e) => setFields({...filterFields, [field.id]: filterFields[field.id] ? null : field.title})}
				>
					{field.title}
				</Checkbox>
				<div className={style.showDetails} onClick={() => setShow(!show)}> &nbsp; {show ? 'Hide': 'Show'} details</div>
			</div>
			{show && 
				<ul>
					{field.calendarNames.map(name => (
						<li key={name}>{name}</li>
					))}
				</ul>
			}
		</div>
	)
}

export default Field
