import gql from 'graphql-tag'
import {
	FormAnswersAuth_answerText_List_objects,
	FormAnswersAuth_answerBoolean_List_objects,
	FormAnswersAuth_answerDate_List_objects,
	FormAnswersAuth_answerFile_List_objects,
	FormAnswersAuth_answerDecimal_List_objects,
} from './gql-types/FormAnswersAuth'

import {
	FormAnswersPublic_answerTextPub_List_objects,
	FormAnswersPublic_answerBooleanPub_List_objects,
	FormAnswersPublic_answerDatePub_List_objects,
	FormAnswersPublic_answerFilePub_List_objects,
	FormAnswersPublic_answerDecimalPub_List_objects,
} from './gql-types/FormAnswersPublic'

export * from './gql-types/FormAnswersAuth'
export * from './gql-types/FormAnswersPublic'

export type AuthAnswerText = FormAnswersAuth_answerText_List_objects
export type AuthAnswerBoolean = FormAnswersAuth_answerBoolean_List_objects
export type AuthAnswerDate = FormAnswersAuth_answerDate_List_objects
export type AuthAnswerFile = FormAnswersAuth_answerFile_List_objects
export type AuthAnswerDecimal = FormAnswersAuth_answerDecimal_List_objects

export type PublicAnswerText = FormAnswersPublic_answerTextPub_List_objects
export type PublicAnswerBoolean = FormAnswersPublic_answerBooleanPub_List_objects
export type PublicAnswerDate = FormAnswersPublic_answerDatePub_List_objects
export type PublicAnswerFile = FormAnswersPublic_answerFilePub_List_objects
export type PublicAnswerDecimal = FormAnswersPublic_answerDecimalPub_List_objects

export const FORM_ANSWERS_AUTH = gql`
	query FormAnswersAuth($record: ID!, $form: ID!) {
		answerText_List(relatedToRecord: $record, form: $form, deactivated_Isnull: true) {
			objects {
				id
				content
				fieldId
				tableFieldId
				contentType
				objectId
				rank
				deactivated
			}
		}
		answerBoolean_List(relatedToRecord: $record, form: $form, deactivated_Isnull: true) {
			objects {
				id
				content
				fieldId
				tableFieldId
				contentType
				objectId
				rank
				deactivated
			}
		}
		answerDate_List(relatedToRecord: $record, form: $form, deactivated_Isnull: true) {
			objects {
				id
				content
				fieldId
				tableFieldId
				contentType
				objectId
				rank
				deactivated
			}
		}
		answerDecimal_List(relatedToRecord: $record, form: $form, deactivated_Isnull: true) {
			objects {
				id
				content
				fieldId
				tableFieldId
				contentType
				objectId
				rank
				deactivated
			}
		}
		answerFile_List(relatedToRecord: $record, form: $form, deactivated_Isnull: true) {
			objects {
				id
				content
				fieldId
				tableFieldId
				contentType
				objectId
				rank
				deactivated

				name
			}
		}
	}
`

export const FORM_ANSWERS_PUBLIC = gql`
	query FormAnswersPublic {
		answerTextPub_List(deactivated_Isnull: true) {
			objects {
				id
				content
				fieldId
				tableFieldId
				contentType
				objectId
				rank
				deactivated
			}
		}
		answerBooleanPub_List(deactivated_Isnull: true) {
			objects {
				id
				content
				fieldId
				tableFieldId
				contentType
				objectId
				rank
				deactivated
			}
		}
		answerDatePub_List(deactivated_Isnull: true) {
			objects {
				id
				content
				fieldId
				tableFieldId
				contentType
				objectId
				rank
				deactivated
			}
		}
		answerDecimalPub_List(deactivated_Isnull: true) {
			objects {
				id
				content
				fieldId
				tableFieldId
				contentType
				objectId
				rank
				deactivated
			}
		}
		answerFilePub_List(deactivated_Isnull: true) {
			objects {
				id
				content
				fieldId
				tableFieldId
				contentType
				objectId
				rank
				deactivated

				name
			}
		}
	}
`







