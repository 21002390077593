import React, { useState } from 'react'
import { useMutation } from '@apollo/client'
import gql from 'graphql-tag'

import { pdfGenerators_pdfGenerator_List_objects as PdfGeneratorType } from './gql-types/pdfGenerators'
import { createPdf, createPdfVariables } from './gql-types/createPdf'
import { useGlobalContext } from 'components/context'
import { changeFormPdfStatus } from 'components/dashboard/pages/module/left-side-panel/record'
import { previewPdfFileApiUrl } from 'utils/url'
import { useAppDispatch, useAppSelector } from 'utils/hooks'
import { validateFlushAndWaitForSave, validateAnswers } from 'state/answer/thunks'
import commonStyle from '../common.module.sass'
import { PDF_FRAGMENT } from './pdf'
import style from './pdf-generator.module.sass'
import { GET_PDF_GENERATORS } from '.'
import { PdfList } from './pdf-list'



type Props = {
	pdfGenerator: PdfGeneratorType
	recordId: string
	formId: string
	readOnly: boolean
}


const CREATE_PDF = gql`
	mutation createPdf(
		$pdfGenerator: ID!
		$form: ID!
		$record: ID!
		$name: String!
		$createdBy: ID!
	) {
		pdf_Update(
			input: {
				pdfGenerator: $pdfGenerator
				record: $record
				form: $form
				name: $name
				createdBy: $createdBy
			}
		) {
			object {
				...Pdf
			}
		}
	}
	${PDF_FRAGMENT}
`

const PdfGenerator = (props: Props) => {
	const dispatch = useAppDispatch()
	const [showPdfs, setShowPdfs] = useState(1)
	const globalContext = useGlobalContext()
	const [seeMore, setSeeMore] = useState(false)
	const [creating, setCreating] = useState(false)
	const [createPdfMutation] = useMutation<createPdf, createPdfVariables>(CREATE_PDF)

	const createPdf = async () => {
		if (creating) {
			return
		}
		
		setCreating(true)
		const result = await dispatch(validateFlushAndWaitForSave({}))
		if(result.meta.requestStatus == 'rejected') {
			setCreating(false)
			return
		}

		const response = await createPdfMutation({
			variables: {
				pdfGenerator: props.pdfGenerator.id,
				record: props.recordId,
				form: props.formId,
				name: props.pdfGenerator.name,
				createdBy: globalContext.user.id,
			},
			// Wait for dependant queries to finish loading with new pdf
			// before we take away loading card
			awaitRefetchQueries: true,
			refetchQueries: [{
				query: GET_PDF_GENERATORS,
				variables: { formId: props.formId, recordId: props.recordId }
			 }]
		})

		setShowPdfs(showPdfs + 1)
		setCreating(false)
		changeFormPdfStatus(props.formId, props.recordId, true)
		const pdf = response.data?.pdf_Update?.object
		if (!pdf) {
			alert('An unknown error occurred creating your PDF.')
		}
	}
	const formIsDirty = useAppSelector(state => {
		return (
			Object.keys(state.answers.status.dirtyInFlight).length
			+ Object.keys(state.answers.status.dirtyQueued).length
		) > 0
	})

	const checkIfFormIsDirtyForPreview = async (e: React.SyntheticEvent) => {
		if (formIsDirty) {
			// When we have prefilled answers, we only save them when a user touches the form.
			// This is to avoid mutations / saves if a user is clicking though forms.
			// Due to browser security restrictions, we pop open a new tab after a promise, so
			// block and make the user try again. 
			e.preventDefault() 
			const result = await dispatch(validateFlushAndWaitForSave({skipAnswerCheck: true}))
			if(result.meta.requestStatus == 'rejected') {
				alert('This form had no user edits, but was invalid. Please fix form errors and try again. If this issue persists, please contact support.')
			} else {
				alert('This form had no user edits so a save was initiated to create the preview pdf. Please try again.')
			}
			
		}
	}
	const shownPdfs = seeMore
		? props.pdfGenerator.pdfs
		: props.pdfGenerator.pdfs.slice(0, showPdfs)
	const hiddenPdfs = props.pdfGenerator.pdfs.length - showPdfs
	return (
		<div>
			<div className={commonStyle.sectionTitle}>
				{props.pdfGenerator.name}
			</div>

			{props.readOnly && props.pdfGenerator.pdfs.length == 0 &&
				<div className="muted">No pdfs</div>
			}

			<div className={style.createPdfButtonContainer}>
				<a
					onClick={checkIfFormIsDirtyForPreview}
					target="_blank"
					href={previewPdfFileApiUrl({ ...props, pdfGeneratorId: props.pdfGenerator.id })}
				>

					<button
						className="icon-eye button-grey-solid"
					>
						Preview
					</button>
				</a>

				<button
					className="icon-doc button-grey-solid"
					onClick={createPdf}
				>
					Create
				</button>
			</div>

			<PdfList
				creating={creating}
				newPdfName={props.pdfGenerator.name}
				pdfs={shownPdfs.filter(pdf => pdf.isActive)}
			/>

			{hiddenPdfs > 0 &&
				<div className={style.loadMoreButtonContainer}>
					<button
						className={style.loadMoreButton}
						onClick={() => setSeeMore(!seeMore)}
					>
						{seeMore ? 'Hide history' : 'View history'}
					</button>
				</div>
			}
		</div>
	)
}

export default PdfGenerator