import React from 'react'
import { addMonths, addDays } from 'date-fns'
import { QueryFormActionMetrics } from '../gql-types/QueryFormActionMetrics'
import { QueryActionRecordForms_record_List_objects, QueryActionRecordForms_template_List_objects_formSet_forms } from '../../gql-types/QueryActionRecordForms'
import { Row } from '../row'
import { RecordTitle } from '../record-title'
import { emptyMonthMap, Line, LineLoading } from './line'
import { getMonths } from '../headers'
import { monthIdFromString, monthKey } from '../common/const'
import { MonthData } from './segment'
import { FormActionFormActionScheduleUnitNameChoices } from 'types/gql-types/global'

const UNIT_NAME_TO_FUNC = {
	DAYS: addDays,
	MONTHS: addMonths,
} satisfies { [k in FormActionFormActionScheduleUnitNameChoices]: any }


type Props = {
	hideEmpty: boolean
	record: QueryActionRecordForms_record_List_objects
	form: QueryActionRecordForms_template_List_objects_formSet_forms
	data: QueryFormActionMetrics | undefined
	loading: boolean
	startYear: number
	startMonth: number
}
export const ActionLine = ({ hideEmpty, record, form, data, loading, startYear, startMonth }: Props) => {
	const actions = data?.formAction_List?.objects.filter(a => a.record.id == record.id && a.form.id == form.id) || []
	const schedules = data?.formActionSchedule_List?.objects.filter(a => a.record.id == record.id && a.form.id == form.id) || []
	const escalations = data?.formActionEscalationEvent_List?.objects.filter(a => a.record.id == record.id && a.form.id == form.id) || []

	const thisMonth = monthKey({year: new Date().getFullYear(), month: new Date().getMonth() + 1})

	const monthMap: { [id: string]: MonthData } = {}

	const months = getMonths({startMonth, startYear})

	months.forEach(month => {
		monthMap[monthKey(month)] = emptyMonthMap()
	})

	actions.forEach(action => {
		const completedOnMonth = action.completedOn ? monthIdFromString(action.completedOn) : null
		if (completedOnMonth && monthMap[completedOnMonth]) {
			monthMap[completedOnMonth].completedOn[action.id] = action
		} else if (monthMap[thisMonth]) {
			// add overdue for today if visible
			monthMap[thisMonth].overdue[action.id] = action
		}
		
		const createdMonth = monthIdFromString(action.created)
		if(monthMap[createdMonth]) {
			monthMap[createdMonth].created[action.id] = action
		}

		for (let key in monthMap) {
			if (createdMonth < key && (!completedOnMonth || key < completedOnMonth) && key < thisMonth) {
				monthMap[key].span[action.id] = action
			}
		}
	})

	schedules.forEach(schedule => {
		let c = 0
		let dueAt = new Date(schedule.dueAt)
		while (c <= 365) {
			c++ // Add extra limit in case
			const dueAtMonth = monthIdFromString(dueAt.toUTCString())
			let month = monthMap[dueAtMonth]
			if (!month){
				break
			}
			month.dueInFuture[schedule.id] = schedule

			const addFunc = UNIT_NAME_TO_FUNC[schedule.unitName]
			dueAt = addFunc(dueAt, schedule.unitAmount)
		}
	})

	escalations.forEach(e => {
		const dueAtMonth = monthIdFromString(e.created)
		monthMap[dueAtMonth].escalation[e.id] = e
	})
	if (hideEmpty) {
		const hasData = Object.values(monthMap).filter(
			m => (Object.values(m).filter(d => {return Object.keys(d).length > 0})).length > 0
		).length > 0
		if (!hasData) {
			return null
		}
	}

	return <Row
		key={`${record.id}-${form.id}`}
		left={
			<RecordTitle
				record={record}
				form={form}
			/>
		}
		right={
			loading ? <LineLoading /> : (
				!data?.formAction_List
				|| !data?.formActionSchedule_List
				|| !data?.formActionEscalationEvent_List
			) ? 'error' :
				<Line
					months={months}
					monthMap={monthMap}
				/>
		}
	/>
}