import React from 'react'
import gql from 'graphql-tag'
import { analyticsLink } from 'fragments'
import { useGlobalContext } from 'components/context'
import { useQuery } from '@apollo/client'
import PieMetric from '../../types/pie'
import { FormStatusMetric, FormStatusMetricVariables } from './gql-types/FormStatusMetric'
import MetricPlaceholder from '../metric-placeholder'


const QUERY = gql`
	query FormStatusMetric($id: ID!, $teamId: ID, $isSuiteAdminDashboard: Boolean) {
		formStatusMetric(id: $id) {
			id
			name
			values(teamId: $teamId, isSuiteAdminDashboard: $isSuiteAdminDashboard) {
				name
				color
				formLinks {
					...MetricFormLinkEssentials
				}
			}
		}
	}
	${analyticsLink}
`


type Props = {
	id: string
	isSuiteAdminDashboard?: boolean
}

const FormStatusMetricWrapper = ({ id, isSuiteAdminDashboard }: Props) => {
	const globalContext = useGlobalContext()
	const { loading, data } = useQuery<FormStatusMetric, FormStatusMetricVariables>(
		QUERY,
		{
			variables: {
				id,
				teamId: globalContext.currentTeam?.id,
				isSuiteAdminDashboard,
			}
		}
	)
	if (loading) {
		return <MetricPlaceholder />
	}
	if (!data || !data.formStatusMetric) {
		return <div>error</div>
	}
	return (
		<PieMetric
			useFormTitleInList={true}
			isSuiteAdminDashboard={isSuiteAdminDashboard}
			{...data?.formStatusMetric}
		/>
	)

}

export default FormStatusMetricWrapper