import React, { useState } from 'react'
import { Switch, Typography } from 'antd'
import { TextArea } from 'components/common/inputs'
import style from './index.module.sass'

const { Title } = Typography;

export type MiscDetails = {
	userSuppliedContext: string
	requestSignature: boolean
}

export type PartialDetails = { [K in keyof MiscDetails]?: MiscDetails[K] }

type Props = {
	update: (toUpdate: PartialDetails) => void
	details: MiscDetails
}


export const emptyDetails = () => {
	const a: MiscDetails = {
		userSuppliedContext: '',
		requestSignature: false,
	}
	return a
}

export const SetMiscDetails = ({ update, details }: Props) => {
	const {
		userSuppliedContext,
		requestSignature,
	} = details;

	return (
		<>
			<Title level={5}>Request Signature</Title>
			<Switch checked={requestSignature} onChange={() => update({ requestSignature: !requestSignature })} />
			<br />
			<br />
			<Title level={5}>Add Message <span style={{ opacity: 0.4 }}>(optional)</span></Title>
			<TextArea
				className={style.textarea}
				onChange={e => { update({ userSuppliedContext: e.target.value }) }}
				value={userSuppliedContext}
			/>
		</>
	)
}
