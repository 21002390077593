import c from 'classnames'
import React, { Component } from 'react'
import { useSelector, useDispatch } from 'react-redux'

import { toggleFullScreenField } from 'state/user-interface'
import style from './index.module.sass'
import { useAppSelector } from 'utils/hooks'


const ShrinkButtonInner = () => {
	const fullScreenField = useAppSelector(state => state.userInterface.fullScreenField)
	const dispatch = useDispatch()

	if(!fullScreenField) {
		return null
	}
	
	else {
		return (
			<button
				className={c(style.shrinkButton, "icon-shrink", "button-primary-solid")}
				onClick={() => dispatch(toggleFullScreenField(null))}
			>
				Show menu
			</button>
		)
	}
}

export default ShrinkButtonInner