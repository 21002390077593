import { GraphQLError } from 'graphql'
import _omit from 'lodash/omit'
import * as Yup from 'yup'
import { ApolloQueryResult } from '@apollo/client'
import { v4 as uuidv4 } from 'uuid'
import { AnswerContentTypeEnum } from "types/gql-types/global"
import { FieldTypeEnum, TableFieldTypeEnum, TableFieldEnumType } from "state/main-form/types"
import { GenericAnswer, isAnswerBoolean, isAnswerDate, isAnswerDecimal, isAnswerFile, isAnswerText } from "./types"
import { isValidUrl } from 'utils/functions'
import { FieldType, InitialAnswerType, isTableField, TableFieldType } from 'state/main-form/types'
import { RootState } from 'state/redux-store'
import { selectAnswersForField, selectFormIsDirtyFromUserInteraction, selectInvalidAnswers, selectObjectIdMap } from './selectors'
import { SaveAnswersAuth, SaveAnswersAuthVariables } from './save-answers-auth'
import { SaveAnswersPublic, SaveAnswersPublicVariables } from './save-answers-public'

const ANSWER_TYPENAME_LOOKUP: {[k in TableFieldEnumType]: GenericAnswer['__typename']} = {
	[TableFieldTypeEnum.ADDRESS]: 'AnswerTextType',
	[TableFieldTypeEnum.BOOLEAN]: 'AnswerBooleanType',
	[TableFieldTypeEnum.DATE]: 'AnswerDateType',
	[TableFieldTypeEnum.CURRENCY]: 'AnswerDecimalType',
	[TableFieldTypeEnum.DROP_DOWN]: 'AnswerTextType',
	[TableFieldTypeEnum.DROP_DOWN_OTHER]: 'AnswerTextType',
	[TableFieldTypeEnum.EMAIL]: 'AnswerTextType',
	[TableFieldTypeEnum.FILE]: 'AnswerFileType',
	[TableFieldTypeEnum.IMAGE]: 'AnswerFileType',
	[TableFieldTypeEnum.INTEGER]: 'AnswerDecimalType',
	[TableFieldTypeEnum.LINK]: 'AnswerTextType',
	[TableFieldTypeEnum.RADIO]: 'AnswerTextType',
	[TableFieldTypeEnum.RATING]: 'AnswerDecimalType',
	[TableFieldTypeEnum.TEXT_LONG]: 'AnswerTextType',
	[TableFieldTypeEnum.TEXT_SHORT]: 'AnswerTextType',
	[TableFieldTypeEnum.TEXT_TINY]: 'AnswerTextType',
	[TableFieldTypeEnum.TIME]: 'AnswerDateType',
}

const ANSWER_VALIDATORS: {[k in TableFieldEnumType]: null | ((a: GenericAnswer) => boolean)} = {
	[TableFieldTypeEnum.ADDRESS]: null,
	[TableFieldTypeEnum.BOOLEAN]: null,
	[TableFieldTypeEnum.DATE]: null,
	[TableFieldTypeEnum.CURRENCY]: null,
	[TableFieldTypeEnum.DROP_DOWN]: null,
	[TableFieldTypeEnum.DROP_DOWN_OTHER]: null,
	[TableFieldTypeEnum.EMAIL]: (a: GenericAnswer) => !a || !a.content || Yup.string().email().nullable(true).isValidSync(a.content),
	[TableFieldTypeEnum.FILE]: null,
	[TableFieldTypeEnum.IMAGE]: null,
	[TableFieldTypeEnum.INTEGER]: null,
	[TableFieldTypeEnum.LINK]: (a: GenericAnswer) => !a || !a.content || isValidUrl(a.content),
	[TableFieldTypeEnum.RADIO]: null,
	[TableFieldTypeEnum.RATING]: null,
	[TableFieldTypeEnum.TEXT_LONG]: null,
	[TableFieldTypeEnum.TEXT_SHORT]: null,
	[TableFieldTypeEnum.TEXT_TINY]: null,
	[TableFieldTypeEnum.TIME]: null,
}

export const isAnswerValid = (fieldType: TableFieldEnumType, a: GenericAnswer) => {
	const b = fieldType as TableFieldEnumType
	if (!a.content || !(fieldType in ANSWER_VALIDATORS)) {
		return true
	}

	const validator = ANSWER_VALIDATORS[fieldType]
	return validator ? validator(a) : true
	
}

export const getRanksFromAnswers = (answers: GenericAnswer[]) => (
	Array.from(new Set(answers.map(a => a.rank))).sort((a,b)=>a-b)
)


export const createNewAnswer = ({field, tableField, objectIdMap, initialAnswers, rank}:{
	objectIdMap: {[k in AnswerContentTypeEnum]: string},
	field: FieldType,
	tableField: TableFieldType | null,
	rank: number,
	initialAnswers?: {[rank: number]: InitialAnswerType[]}
}) => {
	if (field.type == FieldTypeEnum.TABLE && !tableField) {
		const message = "createAnswer must receive tableField for a table"
		alert(message)
		throw Error(message)
	}

	const commonAttributes = {
		id: uuidv4(),
		fieldId: field.id,
		tableFieldId: tableField?.id || null,
		rank: rank,
		contentType: field.contentType,
		objectId: objectIdMap[field.contentType],
		deactivated: null,
	}

	const __typename = ANSWER_TYPENAME_LOOKUP[(field.type == FieldTypeEnum.TABLE ? tableField!.type : field.type)];
	let answer: GenericAnswer

	const initialAnswer: InitialAnswerType | undefined = initialAnswers && initialAnswers[rank] && initialAnswers[rank].find(
		(i: InitialAnswerType) => tableField ? i.tableFieldId == tableField.id : i.fieldId == field.id
	)
	
	if (__typename == 'AnswerFileType') {
		answer = {
			__typename,
			content: initialAnswer ? initialAnswer.contentFile : null,
			name: initialAnswer ? initialAnswer.contentFileName : null,
			...commonAttributes,
		}
	} else if (__typename == 'AnswerBooleanType') {
		answer = {
			__typename,
			content: initialAnswer ? initialAnswer.contentBoolean : false,
			...commonAttributes,
		}
	} else if (__typename == 'AnswerDateType') {
		answer = {
			__typename,
			content: initialAnswer ? initialAnswer.contentDate : null,
			...commonAttributes,
		}
	} else if (__typename == 'AnswerDecimalType') {
		answer = {
			__typename,
			content:  initialAnswer ? initialAnswer.contentDecimal : null,
			...commonAttributes,
		}
	} else if (__typename == 'AnswerTextType') {
		answer = {
			__typename,
			content:  initialAnswer ? initialAnswer.contentText : null,
			...commonAttributes,
		}
	} else {
		throw Error('Unable to infer type')
	}
	return answer
}

export const createNewAnswerRow = ({mainField, objectIdMap, initialAnswers, rank}:{
	objectIdMap: {[k in AnswerContentTypeEnum]: string},
	mainField: FieldType,
	rank: number,
	initialAnswers?: {[rank: number]: InitialAnswerType[]}
}) => {
	const answers = []
	const fields = mainField.type == FieldTypeEnum.TABLE ? mainField.tableFields : [mainField]

	for(const fOrTf of fields) {
		answers.push(createNewAnswer({
			field: mainField,
			tableField: isTableField(fOrTf) ? fOrTf : null,
			objectIdMap,
			rank,
			initialAnswers,
		}))
	}
	return answers
}


export const createMissingAnswers = ({state, objectIdMap}: {state: RootState, objectIdMap: ReturnType<typeof selectObjectIdMap>}) => {
	if (!state.mainForm.data) {throw Error('missing mainForm.data')}
	const toCreate = []
	const tables = Object.values(state.mainForm.data.fields).filter(field => field.type == FieldTypeEnum.TABLE)
	for (const field of tables) {
		const answers = selectAnswersForField(state, field.id)
		const ranks = getRanksFromAnswers(answers)
		for (const rank of ranks) {
			for (const tableField of field.tableFields) {
				const answer = Object.values(state.answers.objects).find(a => a.rank == rank && a.tableFieldId == tableField.id)
				if (!answer) {
					toCreate.push(createNewAnswer({
						objectIdMap,
						field,
						tableField,
						rank,
					}))
				}
			}
		}
	}
	return toCreate
}

export const getSaveAnswerVariables = (state: RootState) => {
	if (!state.mainForm.data) {
		alert('Unable to save answers. Form not loaded.')
		throw Error('Unable to save answers. Form not loaded.')
	}

	const invalidAnswers = selectInvalidAnswers(state)

	const toSave = Object.keys(state.answers.status.dirtyInFlight).map(id => {
		const a: GenericAnswer =  state.answers.objects[id]
		return a
	}).filter(
		// make sure to NOT save invalid answers
		a => !invalidAnswers.find(i => i.id === a.id)
	)

	const formSaveId = uuidv4()

	const variables: SaveAnswersAuthVariables | SaveAnswersPublicVariables = {
		formSave: {
			id: formSaveId,
			record: state.mainForm.data.record.id,
			form: state.mainForm.data.form.id,
			publicForm: state.mainForm.data.publicForm?.id || null,
		},
		answerText: [],
		answerDecimal: [],
		answerDate: [],
		answerBoolean: [],
		answerFile: [],
	}

	const transformForSave = (answer: GenericAnswer) => {
		return {
			..._omit(answer, '__typename'),
			formSave: formSaveId,
		}
	}

	for (const answer of toSave) {
		if (isAnswerText(answer)) {
			variables.answerText.push(transformForSave(answer))
		} else if (isAnswerDecimal(answer)) {
			variables.answerDecimal.push(transformForSave(answer))
		} else if (isAnswerDate(answer)) {
			variables.answerDate.push(transformForSave(answer))
		} else if (isAnswerBoolean(answer)) {
			variables.answerBoolean.push(transformForSave(answer))
		} else if (isAnswerFile(answer)) {
			variables.answerFile.push(transformForSave(answer))
		} else {
			alert('Error saving answers')
			throw Error(`Unknown answer type`)
		}
	}
	return variables
}

const handleSignatureErrors = (errors: readonly GraphQLError[] | undefined) => {
	const scopedError = errors && errors[0]
	const key = 'cant_edit_values_due_to_signatures:'
	if (scopedError && scopedError.message.startsWith(key)) {
		const name = scopedError.message.replace(key, "")
		const message = [
			`This form cannot be saved due to signatures on:`,
			`\u00B7 ${name}`,
			`If this isn't the current form, they may share the same field. Remove the actions on the above form to make edits.`
		].join('\n\n')
		alert(message)
		throw Error(scopedError.toString())
	}
}

export const extractSaveAnswerPubResponse = ({data, errors, error}: ApolloQueryResult<SaveAnswersPublic>) => {
	handleSignatureErrors(errors)
	
	if (
		error
		|| (errors && errors.length)
		|| !data.answerTextPub_BulkUpdate?.objects
		|| !data.answerDecimalPub_BulkUpdate?.objects
		|| !data.answerDatePub_BulkUpdate?.objects
		|| !data.answerBooleanPub_BulkUpdate?.objects
		|| !data.answerFilePub_BulkUpdate?.objects
	) {
		console.error('Error saving answers', [...Object.values(data).map(d => d.errors), errors, error].filter(d => d))
		alert('There was an error saving your answers. If this issue persists, please contact support.')
		throw Error(`Error extracting answers pub ${error} ${errors}`)
	}
	return [
		...data.answerTextPub_BulkUpdate.objects,
		...data.answerDecimalPub_BulkUpdate.objects,
		...data.answerDatePub_BulkUpdate.objects,
		...data.answerBooleanPub_BulkUpdate.objects,
		...data.answerFilePub_BulkUpdate.objects,
	]
}

export const extractSaveAnswerResponse = ({data, errors, error}: ApolloQueryResult<SaveAnswersAuth>) => {
	handleSignatureErrors(errors)
	
	if (
		error
		|| (errors && errors.length)
		|| !data.answerText_BulkUpdate?.objects
		|| !data.answerDecimal_BulkUpdate?.objects
		|| !data.answerDate_BulkUpdate?.objects
		|| !data.answerBoolean_BulkUpdate?.objects
		|| !data.answerFile_BulkUpdate?.objects
	) {
		console.error('Error saving answers', [...Object.values(data).map(d => d.errors), errors, error].filter(d => d))
		alert('There was an error saving your answers. If this issue persists, please contact support.')
		throw Error(`Error extracting answers auth ${error} ${errors}`)
	}
	return [
		...data.answerText_BulkUpdate.objects,
		...data.answerDecimal_BulkUpdate.objects,
		...data.answerDate_BulkUpdate.objects,
		...data.answerBoolean_BulkUpdate.objects,
		...data.answerFile_BulkUpdate.objects,
	]
}

export const waitForSave = async (getState: () => RootState) => {
	const state = getState()
	
	if (selectFormIsDirtyFromUserInteraction(state) || state.answers.saving || state.answers.queuedSave) {
		console.log("Dirty answers in state, waiting for save...")
		await new Promise<void>((resolve, reject) => {
			setTimeout(async () => {
				resolve()
			}, 300)
		})
		await waitForSave(getState)
	}
}

export const addBlockNavigationListener = (getState: () => RootState) => {
	window.addEventListener("beforeunload", function(event) {
		const state = getState()
		if (selectFormIsDirtyFromUserInteraction(state)) {
			event.preventDefault();
			return event.returnValue = "Your changes are still saving. Are you sure you want to leave?";
		}
		return false
	})
}