import React from 'react'
import { Tooltip } from "antd"
import c from 'classnames'
import { selectAnswerMissingForField } from "state/answer/selectors"
import { useAppSelector } from "utils/hooks"
import style from './index.module.sass'


export const MandatoryAsterisk = ({fieldId}: {fieldId: string}) => {
	const hasValue = useAppSelector(state => !selectAnswerMissingForField(state, fieldId))
	return (
		<Tooltip title="This field is required">
			<div className={c(style.requiredAsterisk, {[style.hasValue]: hasValue})}>
				<span className={style.asterisk}>*</span>
				{!hasValue && <span className={style.text}>&nbsp;Required</span>}
			</div>
		</Tooltip>
	)
}