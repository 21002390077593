
import React from 'react'
import { MoreOutlined } from '@ant-design/icons'
import { Dropdown, Typography } from 'antd'
import { InfoCircleOutlined } from '@ant-design/icons'
import { Tooltip } from 'antd'
import { Actions, Escalation, State } from '../hooks'
import style from './index.module.sass'
import { getUserDetails } from 'components/form/form-control-panel/form-actions/common/utils';

const { Title } = Typography;

type Props = {
	dispatch: React.Dispatch<Actions>
	state: State
}

type RowProps = {
	e: Escalation
	dispatch: React.Dispatch<Actions>
}

const Row = ({e, dispatch}: RowProps) => {
	const details = getUserDetails(e)
	return (
		<div key={e.id} className={style.row}>
			<div><b>{details.name}</b> ({details.email}) will be CC'ed when overdue by {e.deltaDays} days</div>
			<div>
			<Dropdown
				menu={{
					items: [
						{
							key: 'delete',
							label: (
								<div className="icon-bin"
									onClick={() => dispatch({ type: 'DELETE', id: e.id })}>
									Delete
								</div>
							),
						},
					]
				}}
				trigger={['click']}
			>
				<MoreOutlined style={{ transform: 'scale(1.4)' }} />
			</Dropdown>
			</div>
		</div>
	)
}

export const EscalationList = ({dispatch, state}: Props) => {
	const escalations = Object.values(state).filter(e => !e.deleted).sort((a, b) => a.deltaDays - b.deltaDays)
	return (
		<>
			<Title level={5}>
				Escalations&nbsp;
				<Tooltip title="If the actions defined above remain incomplete, additional users can be added to the email chain.">
					<InfoCircleOutlined />
				</Tooltip>
			</Title>
			
			<div>
				{escalations && escalations.map(e => (
					<Row key={e.id} e={e} dispatch={dispatch} />
				))}
				{escalations.length === 0 && <div>No escalations set</div>}
			</div>
		</>
	)

}
