import { useQuery } from '@apollo/client'
import { Empty } from 'antd'
import gql from 'graphql-tag'
import React from 'react'
import { QueryFormActionMetrics, QueryFormActionMetricsVariables } from './gql-types/QueryFormActionMetrics'
import { QueryActionRecordForms_record_List_objects, QueryActionRecordForms_template_List_objects, QueryActionRecordForms_template_List_objects_formSet_forms } from '../gql-types/QueryActionRecordForms'
import { Headers } from './headers'
import { ActionLine } from './action-line'

const GET_ACTIONS_QUERY = gql`
	query QueryFormActionMetrics(
		$recordIds: [ID!]!,
		$yearMonth: String!
	) {
		formAction_List(
			record_In: $recordIds,
			yearMonth: $yearMonth,
		) {
			objects {
				id
				completedOn
				created
				record {
					id
				}
				form {
					id
				}
				assignedUser {
					id
					firstName
					lastName
					email
				}
				assignedAnonEmail
				assignedAnonName
				assignedAnonEmailAnswer {
					id
					content
					contactName
				}
			}
		}
		formActionSchedule_List(
			record_In: $recordIds,
			yearMonth: $yearMonth,
		) {
			objects {
				id
				assignedAnonEmail
				assignedAnonName
				dueAt
				unitAmount
				unitName
				assignedAnonEmailAnswer {
					id
					content
					contactName
				}
				assignedUser {
					id
					firstName
					lastName
					email
				}
				record {
					id
				}
				form {
					id
				}
			}
		}
		formActionEscalationEvent_List(
			record_In: $recordIds,
			yearMonth: $yearMonth,
		) {
			objects {
				id
				created
				record {
					id
				}
				form {
					id
				}
				assignedUser {
					id
					firstName
					lastName
					email
				}
				assignedAnonEmail
				assignedAnonName
				assignedAnonEmailAnswer {
					id
					content
					contactName
				}
			}
		}
	}
`


type Props = {
	hideEmpty: boolean
	startYear: number
	startMonth: number
	records: QueryActionRecordForms_record_List_objects[]
	templates: { [id: string]: QueryActionRecordForms_template_List_objects }
}
export const Table = ({ startYear, startMonth, records, templates, hideEmpty }: Props) => {
	const [search, setSearch] = React.useState('')
	const lowerCaseSearch = search.toLowerCase()

	const { data, loading } = useQuery<QueryFormActionMetrics, QueryFormActionMetricsVariables>(GET_ACTIONS_QUERY, {
		variables: {
			recordIds: records.map(record => record.id),
			yearMonth: `${startYear}-${startMonth}`,
		},
	})

	const rows: {record: QueryActionRecordForms_record_List_objects, form: QueryActionRecordForms_template_List_objects_formSet_forms}[] = []
	let errors: React.ReactNode[] = []

	records.forEach(record => {
		const template = templates[record.template.id]
		if (!template) {
			errors.push(<div key={`${record.id}-${record.template.id}`}><b>{record.name}</b> has template <b>{record.template.id}</b> which doesn't exist in this module.</div>)
			return
		}
		template.formSet.forms.filter(f => (
			!search || (
				record.name.toLowerCase().includes(lowerCaseSearch)
				|| f.title.toLowerCase().includes(lowerCaseSearch)
			)
		)).forEach(form => {
			rows.push({record, form})
		})
	})

	return (
		<div>
			<Headers startYear={startYear} startMonth={startMonth} setSearch={setSearch} />
			{rows.map(({record, form}) => (
				<ActionLine
					key={`${record.id}-${form.id}`}
					record={record}
					form={form}
					data={data}
					hideEmpty={hideEmpty}
					loading={loading}
					startYear={startYear}
					startMonth={startMonth}
				/>
			))}
			{rows.length == 0 && <><br/><br/><Empty /></>}
			{errors.length > 0 && <div className="muted"><br/>Hidden:<br/>{errors}</div>}
		</div>
	)
}
