import React from 'react'
import { updateAnswers } from 'state/answer/thunks'
import { useAppDispatch } from 'utils/hooks'
import { AnswerTextBaseProps } from '../types'
import style from './index.module.sass'


const AnswerRadio = (props: AnswerTextBaseProps) => {
	const dispatch = useAppDispatch()

	return (
		<div className={style.radioRow}>

			{props.disabled && <span className="muted"> (disabled)</span>}
			{props.field.selectOptions.map(selectOption => {
				return (
					<div className={style.radioItem} key={selectOption.label}>
						<input
							type='radio'
							value={selectOption.label}
							checked={selectOption.label === props.answer.content}
							onChange={(e) => dispatch(updateAnswers({
								answers: [{
									...props.answer,
									content: e.target.value,
								}]
							}))}
							disabled={props.disabled}
						/>
						<span className={style.radioText}>{selectOption.label}</span>
					</div>
				)
			})}
		</div>
	)
}

export default AnswerRadio