import gql from 'graphql-tag'
import React from 'react'
import c from 'classnames'
import { Link, Route } from 'react-router-dom'
import { useQuery } from '@apollo/client'

import Placeholder from 'components/common/placeholder'
import { useFormContext } from 'state/main-form/reducer'
import { editFormActionsUrl, resetFormActionsUrl } from 'utils/url'
import { useGlobalContext } from 'components/context'
import commonStyle from '../common.module.sass'
import { GetFormActions, GetFormActionsVariables } from './gql-types/GetFormActions'
import { FormActionSummary } from './summary'
import { EditFormActionModal } from './edit-modal'
import { ResetFormActionModal } from './reset-modal'
import { FORM_ACTION_FRAGMENT, FORM_ACTION_SCHEDULE_FRAGMENT } from './common/utils'
import style from './index.module.sass'

// Warning: refetchQueries references the name of this query
const GET_FORM_ACTIONS = gql`
	query GetFormActions($record: ID!, $form: ID!) {
		formAction_List(record: $record, form: $form, orderBy: {field: priority}) {
			objects {
				...FormAction
			}
		}
		formActionSchedule_List(record: $record, form: $form) {
			objects {
				...FormActionSchedule
			}
		}
	}
	${FORM_ACTION_FRAGMENT}
	${FORM_ACTION_SCHEDULE_FRAGMENT}
`


export const FormActions = () => {
	const globalContext = useGlobalContext()
	const formContext = useFormContext()

	const variables = { variables: { record: formContext.record.id, form: formContext.form.id }}
	const { data, loading } = useQuery<GetFormActions, GetFormActionsVariables>(GET_FORM_ACTIONS, variables)
	const formActions = data?.formAction_List?.objects
	const formActionSchedule = data?.formActionSchedule_List?.objects[0] || null

	const enabled = formContext.form.allowActions
	if (!enabled) {
		return null
	}

	const editUrl = editFormActionsUrl({ ...globalContext, recordId: formContext.record.id, formId: formContext.form.id })
	const resetUrl = resetFormActionsUrl({ ...globalContext, recordId: formContext.record.id, formId: formContext.form.id })

	return ( 
		<div>
			<div className={c(commonStyle.sectionTitle, style.titleRow)}>
				<div>Actions</div>
				<div className={style.buttonGroup}>
					{formActions && formActions.length > 0 && !formActionSchedule &&
						<Link to={resetUrl}><button className="button-small button-grey-solid">Reset</button></Link>
					}
					<Link to={editUrl}><button className="button-small button-grey-solid">View</button></Link>
				</div>
			</div>
			{
				loading ? <Placeholder style={{height: 100}} />
				: (formActions === undefined) ? <div className="error">error</div>
				: (
					<>
						<FormActionSummary formActions={formActions} formActionSchedule={formActionSchedule} />
						<Route
							path={editUrl}
							component={() => <EditFormActionModal formActions={formActions} formActionSchedule={formActionSchedule}/>}
						/>
						<Route
							path={resetUrl}
							component={() => <ResetFormActionModal formActions={formActions}/>}
						/>
					</>
				)
			}
		</div>
	)
}