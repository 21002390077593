import React from 'react'
import { MONTHS, monthKey } from '../common/const'
import commonStyle from '../common/common.module.sass'
import segmentStyle from './segment.module.sass'
import { MonthData, Segment } from './segment'


export const emptyMonthMap: () => MonthData = () => {
	return {
		created: {},
		completedOn: {},
		dueInFuture: {},
		overdue: {},
		span: {},
		escalation: {},
	}
}

type Props = {
	months: {year: number, month: number}[]
	monthMap: { [id: string]: MonthData }
}
export const Line = ({ months, monthMap }: Props) => {
	const thisMonth = monthKey({year: new Date().getFullYear(), month: new Date().getMonth() + 1})

	return (
		<div className={commonStyle.monthsContainer}>
			{months.map(month => (
				<div key={month.month} className={commonStyle.monthCell}>
					<Segment
						year={month.year}
						month={month.month}
						todayKey={thisMonth}
						monthData={monthMap[monthKey(month)] || emptyMonthMap()}
					/>
				</div>
			))}
		</div>
	)
}

export const LineLoading = () => (
	<div className={commonStyle.monthsContainer}>
		{MONTHS.map(month => (
			<div key={month.index} className={commonStyle.monthCell}>
				<div className={segmentStyle.spanLoading} />
			</div>
		))}
	</div>
)