import React, { useState } from 'react'
import _delay from 'lodash/delay'
import gql from "graphql-tag"
import c from 'classnames'
import { useMutation } from '@apollo/client'

import { useGlobalContext } from 'components/context'
import {
	adviceUrl,
	pharosAdminUrl,
	settingsUrl,
	addModuleUrl,
	userManagementAddUrl,
	loginUrl,
	suiteAdminDashboardUrl,
	settingsUserDetailsUrl
} from 'utils/url'
import Icon from './icon'
import IconModule from './icon-module'
import style from './index.module.sass'
import { SITE_NAME } from 'utils/constants'
import { useAppSelector } from 'utils/hooks'
import BASTION_LOGO from './logos/bastion.svg'
import JOURNAL_LOGO from './logos/journal.svg'
import PHAROS_LOGO from './logos/pharos.svg'
import QUESTOS_LOGO from './logos/questos.svg'
import SCHOOL_LOGO from './logos/school.svg'

const LOGOUT_USER = gql`
	mutation logoutUser {
		user_Logout {
			success
		}
	}
`

export const NavBar = () => {
	const { organisation, user } = useGlobalContext()
	const defaultLogo = {
		BASTION: BASTION_LOGO,
		JOURNAL: JOURNAL_LOGO,
		PHAROS: PHAROS_LOGO,
		QUESTOS: QUESTOS_LOGO,
		SCHOOL: SCHOOL_LOGO,
	}[SITE_NAME]
	
	const [hideDropdown, toggleHideDropdown] = useState(false)
	const closeDropdown = () => {
		toggleHideDropdown(true)
		_delay(toggleHideDropdown, 500, false)
	}

	const [logout, {}] = useMutation(LOGOUT_USER)

	const showModules = organisation.suite.moduleSetItems.filter(
		m => m.module.teams.length > 0
	)

	const printableDashboard = useAppSelector(state => state.userInterface.printableDashboard)
	const fullScreenField = useAppSelector(state => state.userInterface.fullScreenField)
	if (printableDashboard || fullScreenField) {
		return null
	}

	const showAddModule = organisation.suite.moduleSetItems.filter(
		msi => (
			msi.module.teams.length == 0
			&& !msi.hiddenWhenEmpty 
		)
	).length > 0

	return (
		<div
			className={style.navBarContainer}
			style={{pointerEvents: hideDropdown ? "none" : "inherit"}}
		>
			<div className={style.navBarGroup}>
				<div className={c(style.logo, {[style.faded]: !organisation.suite.logo})}>
					<img src={organisation.suite.logo ? organisation.suite.logo : defaultLogo} />
				</div>
				{ showModules.map(m => (
					<IconModule
						key={m.id}
						module={m.module}
						closeDropdown={closeDropdown}
					/>
				))}
				{ user.topLevelManager && showAddModule &&
					<Icon
						iconLink={addModuleUrl()}
						activePath={addModuleUrl()}
						icon={"add"}
						title={`Add ${organisation.suite.moduleWord}`} 
						closeDropdown={closeDropdown}
						primary
					/>
				}
			</div>
			<div className={style.navBarGroup}>
				{ user.adminOfSuite &&
					<Icon
						iconLink={suiteAdminDashboardUrl()}
						activePath={suiteAdminDashboardUrl()}
						icon={"gauge"}
						title="Overview dashboard"
						closeDropdown={closeDropdown}
						small
					/>
				}
				{ user.topLevelManager &&
					<Icon
						iconLink={userManagementAddUrl()}
						activePath={userManagementAddUrl()}
						icon={"users"}
						title="User Management"
						closeDropdown={closeDropdown}
						small
					/>
				}
				{ organisation.suite.adviceSection &&
					<Icon
						iconLink={adviceUrl()}
						activePath={adviceUrl()}
						icon={"not-found"}
						title={organisation.suite.adviceSection.title} 
						closeDropdown={closeDropdown}
						small
					/>
				}
				{ user.isAdmin &&
					<Icon
						iconLink={pharosAdminUrl()}
						activePath={pharosAdminUrl()}
						icon={"medium-users-swap"}
						title="Superuser"
						closeDropdown={closeDropdown}
						links={[
							{
								name: "Change team",
								to: pharosAdminUrl(),
							},
							{
								name: <a href="/admin">Django admin</a>,
							},
						]}
						small
					/>
				}
				<Icon
					iconLink={settingsUserDetailsUrl()}
					activePath={settingsUrl()}
					icon={"user"}
					title="Account"
					links={[
						{
							name: "Settings",
							to: settingsUserDetailsUrl(),
						},
						{
							name: "Logout",
							onClick: async () => {
								await logout()
								window.location.replace(loginUrl())
							},
						},
					]}
					moveDropdown={-60}
				/>
			</div>
		</div>
	)
}