import React, { useEffect } from 'react'
import { Route } from 'react-router-dom'
import querystring from 'query-string'

import { useGlobalContext, useRouter } from '../context'
import { teamMetricsDefaultUrl, loginUrl, signupUrl } from 'utils/url'
import { Login } from './login'
import { Signup } from './signup'
import { DashboardLoading } from 'components/dashboard/loading'

export const Auth = () => {
	const router = useRouter()
	const globalContext = useGlobalContext()

	useEffect(() => {
		if (globalContext.user) {
			const qs = querystring.parse(router.location.search)
			router.history.push(qs.next || teamMetricsDefaultUrl(globalContext))
		}
	})
	if (globalContext.user) {
		return <DashboardLoading />
	}
	return (
		<>
			<Route path={loginUrl()} component={Login} />
			<Route path={signupUrl()} component={Signup} />
		</>
	)
}
