import gql from 'graphql-tag'

export * from './gql-types/ToggleHiddenField'
export const TOGGLE_HIDDEN_FIELD = gql`
	mutation ToggleHiddenField($id: ID, $fieldId: ID!, $teamId: ID!, $recordId: ID!, $deleted: Boolean!) {
		hiddenField_Update(input: {
			id: $id,
			field: $fieldId,
			deleted: $deleted,
			team: $teamId
		}) {
			object {
				id
				field {
					id
					hiddenFieldId(recordId: $recordId)
				}
				deleted
			}
			errors {
				field
				messages
			}
		}
	}
`
