import React from 'react'
import { Tabs, Modal } from 'antd'

import { SinglePanel } from 'components/common/panel'
import history from 'utils/history'
import { RouteComponentProps, Route, Link } from 'react-router-dom'
import { recordMergePdfsCreateUrl, recordMergePdfsHistoryUrl, formUrl } from 'utils/url'
import { useGlobalContext } from 'components/context'
import History from './history'
import { CreateMergedPdf } from './create'
import { useTitle } from 'utils/hooks'

const TABS = {
	SEND: 'send',
	HISTORY: 'history',
}

type Props = {
	match: {
		params: {
			recordId: string
		}
		url: string
	}
}

export const MergePdf = ({match: {url, params: { recordId }}}: Props) => {
	const globalContext = useGlobalContext()
	const createRoute = recordMergePdfsCreateUrl({
		...globalContext,
		recordId: recordId,
	})
	const historyRoute = recordMergePdfsHistoryUrl({
		...globalContext,
		recordId: recordId,
	})
	useTitle('Combine Pdfs')
	return (
		<SinglePanel type="thin">
			<h4>Merge pdfs</h4>
			<div>
				<Tabs
					defaultActiveKey={window.location.pathname.includes('create') ? TABS.SEND : TABS.HISTORY}
					onChange={(key) => history.push(key === TABS.SEND ? createRoute : historyRoute) }
				>
					<Tabs.TabPane tab="Create" key={TABS.SEND}>
						<Route
							path={createRoute}
							component={() => <CreateMergedPdf recordId={recordId} />}
						/>
					</Tabs.TabPane>
					<Tabs.TabPane tab="History" key={TABS.HISTORY}>
						<Route
							path={historyRoute}
							component={() => <History recordId={recordId} />}
						/>
					</Tabs.TabPane>
				</Tabs>
			</div>
		</SinglePanel>
	)
}