
import React from 'react'
import gql from 'graphql-tag'

import PdfGenerator from './pdf-generator'
import { useQuery } from '@apollo/client'
import { pdfGenerators, pdfGeneratorsVariables } from './gql-types/pdfGenerators'
import Placeholder from 'components/common/placeholder'
import { PDF_FRAGMENT } from './pdf'


type Props = {
	readOnly: boolean
	recordId: string
	formId: string
}

// NOTE: This query is references as a refetch in formActions
export const GET_PDF_GENERATORS = gql`
	query pdfGenerators($formId: ID!, $recordId: ID!) {
		pdfGenerator_List(
			formId: $formId
			limit: 20
			offset: 0
		) {
			objects {
				id
				name
				pdfs(recordId: $recordId) {
					...Pdf
				}
			}
			pageInfo {
				hasNextPage
				total
			}
		}
	}
	${PDF_FRAGMENT}
`

const PdfSection = (props: Props) => {
	const { data, loading } = useQuery<pdfGenerators, pdfGeneratorsVariables>(GET_PDF_GENERATORS, {
		variables: {
			formId: props.formId,
			recordId: props.recordId, 
		}
	})

	const generators = data?.pdfGenerator_List?.objects

	return (
		<>
			{generators &&
				generators.map(generator => (
					<PdfGenerator
						key={generator.id}
						{...props}
						pdfGenerator={generator}
					/>
				))
			}
			{loading && !generators &&
				<Placeholder style={{height: 100}}/>
			}
			{!generators && !loading &&
				<div className="error"> Pdf section error</div>
			}
		</>
	)
}

export default PdfSection