import React, { useState } from 'react'
import { Teams } from './teams'
import { Switch } from './switch'

import style from './org.module.sass'

import { adminOrganisations_organisationAdmin_List_objects as OrgType } from './gql-types/adminOrganisations'

type Props = {
	org: OrgType
}

export const Org = ({org}: Props) => {
	const [show, setShow] = useState(false);
	return (
		<div 
			key={org.id}
			className={style.orgContainer}
		>
			<div className={style.orgRow}>
				<div className={style.leftCol}>
					<div className={style.id}>{org.id}</div>
					<div className={style.orgName} onClick={() => setShow(!show)}>{org.name}</div>
					<div className={`${style.count} icon-user`}>{org.userCount}</div>
					<div className={`${style.count} icon-doc`}>{org.recordCount}</div>
				</div>
				<Switch orgId={org.id} />
			</div>
			{show && <Teams orgId={org.id} />}
		</div>
	)
}