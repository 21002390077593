import React from 'react'
import { FieldContentType } from 'types/common'
import { Notifications, NotificationsVariables } from './gql-types/Notifications'
import { QueryResult } from '@apollo/client'

export type ModalProps = {
	fieldType: FieldContentType
	fieldId: string
	recordId: string
	teamId: string
	formId: string
}

export type QueryType = QueryResult<Notifications, NotificationsVariables>

// Without memoization AND useContext, modal will flicker when data is updated
export const NotificationQueryContext = React.createContext<null | QueryResult<Notifications, NotificationsVariables>>(null)
